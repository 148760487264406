import React, { useCallback, useEffect, useMemo, useState } from "react";

import {Icon} from "@iconify/react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card, Divider,
  IconButton,
  Popover,
  TextField, Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  LocalizationProvider,
  MonthCalendar,
  YearCalendar,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";

import Descriptions from "./Descriptions";
import MaterialTablePaginationTimeSheet from "../../components/MaterialTablePaginationTimeSheet";
import TooltipTimesheet from "../../components/TooltipTimesheet/TooltipTimesheet";
import { TIMESHEET_STATUSES } from "../../constants";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { socket } from "../../utils/socket";
import {shoptDepartment} from "../../utils/textAvatar";

const TimesheetMonth = () => {
  const tableId = "timesheetMonth";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "sm");

  const [editingCell, setEditingCell] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [updateStatus, setUpdateStatus] = useState("started");
  const [tableData, setTableData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const { timesheetsMonth, tooltipCell, isOpenTooltipCell } = useSelector(
    (state) => state.timesheetsReducer,
  );

  const { setTimesheetsMonth, setIsOpenTooltipCell, setTooltipCell } =
    useActions();

  const loadData = useCallback(async () => {
    setLoading(true);

    const selectedMonth = dayjs(month).month() + 1;
    const selectedYear = dayjs(year).year();
    try {
      const response = await $authHost.get(`/report_cards/all/`, {
        params: {
          month: selectedMonth,
          year: selectedYear,
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
        },
      });

      const data = response.data.items;
      setTimesheetsMonth(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    month,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setTimesheetsMonth,
    year,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onUnload = () => {
    socket.removeAllListeners();
    socket.disconnect();
    console.log("onUnload");
  };

  const onConnect = () => {
    console.log("Connect");
  };

  const onDisconnect = () => {
    console.log("Disconnect");
  };

  const onUpdateStatus = useCallback(
    (data) => {
      console.log("onUpdateStatus", data);
      setUpdateStatus(data?.status);

      // if (data?.status === "started") {
      // }
      if (data?.status === "finished") {
        loadData();
      }
    },
    [loadData],
  );

  useEffect(() => {
    socket.connect();

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("update_report_cards", onUpdateStatus);

    window.addEventListener("beforeunload", onUnload);
    return () => {
      onUnload();
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [onUpdateStatus]);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const response = await $authHost.get(`/report_cards/service/status`);
        setUpdateStatus(response.data?.status);
      } catch (e) {
        console.log(e);
      }
    };

    getStatus();
  }, []);

  const days = useMemo(() => {
    const daysInMonth = dayjs(month).daysInMonth();
    const daysArr = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArr.push(i);
    }

    return daysArr;
  }, [month]);

  useEffect(() => {
    const newTableData = [];
    timesheetsMonth.forEach((item) => {
      const edited = editedRows.find((row) => row?.id === item?.id);
      if (edited) {
        newTableData.push(edited);
      } else {
        newTableData.push(item);
      }
    });

    setTableData(newTableData);

    if (editedRows.length > 0) {
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(true);
    }
  }, [editedRows, timesheetsMonth]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  const dataCellView = (cell) => {
    if (cell.id === tooltipCell && isOpenTooltipCell) {
      setIsOpenTooltipCell(false);
      setTooltipCell(null);
    } else {
      setIsOpenTooltipCell(true);
      setTooltipCell(cell.id);
    }
  };

  const handleClick = (e, cell) => {
    switch (e.detail) {
      case 1:
        dataCellView(cell);
        break;
      case 2:
        setEditingCell(cell);
        break;
    }
  };

  const tableColumns = [
    {
      id: "user",
      header: "Сотрудник",
      accessorFn: (row) => row.user?.display_name || "",
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <Typography color="text.primary" variant="body3" >
          {renderedCellValue}
        </Typography>
      ),
    },
    {
      id: "user_department_name",
      header: "Отдел",
      accessorFn: (row) => row.user?.department?.name || "",
      enableEditing: false,
      size: 15,
      Cell: ({ renderedCellValue, row }) => (
        <Typography variant="body3">
          {shoptDepartment(renderedCellValue)}
        </Typography>
      ),
    },
    ...days.map((d) => ({
      id: `calendar.${d.toString()}`,
      accessorFn: (row) => row.calendar?.[d.toString()] || "",
      header: d.toString(),
      size: 1,
      enableEditing: updateStatus !== "started",
      Edit: ({ cell, column, table }) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find(
          (status) => status.value === value,
        );

        return status ? (
          <Autocomplete
            color="secondary"
            disableClearable
            getOptionLabel={(option) => option}
            onBlur={(e) => {
              handleBlur(cell, e.target.value);
              setEditingCell(null);
            }}
            options={status?.options}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                color="secondary"
                size="small"
                sx={(theme) => ({
                  '& .MuiOutlinedInput-root':{
                    '& fieldset': {
                      borderColor: `${theme.palette.divider}`,
                    },
                  },
                  '& input': {
                    fontSize:'12px'
                  },
                  '& .MuiInputBase-inputMultiline':{
                    fontSize:'12px'
                  }
                })}
                variant="outlined"
              />
            )}
            size="small"
            value={status?.value}
          />
        ) : (
          <Autocomplete
            disableClearable
            getOptionLabel={(option) => option.value}
            onBlur={(e) => {
              handleBlur(cell, e.target.value);
              setEditingCell(null);
            }}
            options={TIMESHEET_STATUSES}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                color="secondary"
                size="small"
                sx={(theme) => ({
                  '& .MuiOutlinedInput-root':{
                    '& fieldset': {
                      borderColor: `${theme.palette.divider}`,
                    },
                  },
                  '& input': {
                    fontSize:'12px'
                  },
                  '& .MuiInputBase-inputMultiline':{
                    fontSize:'12px'
                  }
                })}
                variant="outlined"
              />
            )}
            size="small"
            value={value}
          />
        );
      },
      muiTableBodyCellProps: ({ cell, column, row, table }) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find(
          (status) => status.value === value,
        );
        if (
          (status && !status?.editing) ||
          !status ||
          row.original?.is_closed
        ) {
          return {
            sx: {
              '& :hover':{
                borderColor:'red'
              },
              padding:'3px',
              cursor: "default",
            },
            onClick: () => {
              dataCellView(cell);
            },
          };
        } else {
          return {
            sx: {
              padding:'3px',
            },
            onClick: (e) => {
              handleClick(e, cell);
            },
          };
        }
      },
      Cell: ({ renderedCellValue, row, cell }) => (
        <TooltipTimesheet
          cell={cell}
          data={row}
          element={renderedCellValue}
        />
      ),
    })),
  ];

  const handleBlur = (cell, value) => {
    const editedRow = cloneDeep(tableData[cell.row.index]);
    editedRow.calendar[cell.column.columnDef.header] = value;
    const originalRow = timesheetsMonth[cell.row.index];
    const isSame = isEqual(editedRow, originalRow);
    const newEditedRows = [...editedRows];

    if (isSame) {
      const index = editedRows.findIndex((row) => row?.id === editedRow?.id);
      if (index > -1) {
        newEditedRows.splice(index, 1);
      }
    }

    if (!isSame) {
      const index = editedRows.findIndex((row) => row?.id === editedRow?.id);
      if (index > -1) {
        newEditedRows.splice(index, 1, editedRow);
      }
      if (index === -1) {
        newEditedRows.push(editedRow);
      }
    }

    setEditedRows(newEditedRows);
  };

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, "month");

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, "year");
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, "month");

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, "year");
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const requests = editedRows.map((row) => {
        return $authHost.put(`/report_cards/${row.id}/update/`, row);
      });

      const response = await Promise.allSettled([...requests]);
      console.log(response);
      const unsavedRows = [];
      response.forEach((r, index) => {
        if (r.status === "rejected") {
          unsavedRows.push(editedRows[index]);
        }
      });
      setEditedRows(unsavedRows);
      loadData();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditedRows([]);
  };

  const handleUpdate = () => {
    try {
      $authHost.put(`/report_cards/update/all`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Card sx={{ my: 2 }}>
        <Descriptions />
      </Card>
      {/*{isMobile && (*/}
      {/*  <Stack alignItems="center" direction="column" sx={{ width: "100%" }}>*/}
      {/*    <LoadingButton*/}
      {/*      loading={updateStatus === "started"}*/}
      {/*      loadingPosition="start"*/}
      {/*      onClick={handleUpdate}*/}
      {/*      startIcon={<RefreshIcon />}*/}
      {/*      sx={{ mr: 2 }}*/}
      {/*      variant="contained"*/}
      {/*    >*/}
      {/*      {updateStatus === "started"*/}
      {/*        ? "Идет процесс обновления"*/}
      {/*        : "Обновить"}*/}
      {/*    </LoadingButton>*/}
      {/*    {updateStatus === "started" && (*/}
      {/*      <Typography sx={{ pt: 1 }} variant="caption">*/}
      {/*        Внесенные изменения могут быть утеряны*/}
      {/*      </Typography>*/}
      {/*    )}*/}
      {/*  </Stack>*/}
      {/*)}*/}
      {isMobile && updateStatus === "started" && (
        <Alert severity="info" sx={{mb:2}}>
          Идет процесс обновления
        </Alert>
      )}

      <Card>
        <MaterialTablePaginationTimeSheet
          columns={tableColumns}
          customFilter={
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              {
                isMobile ?
                  <IconButton
                    color="primary"
                    loading={updateStatus === "started"}
                    onClick={handleUpdate}
                    size="small"
                    sx={(theme)=>({
                      borderRadius:'8px',
                      // border:`1px solid ${theme.palette.primary.light}`,
                      backgroundColor:`${theme.palette.primary.light}`,
                      color:'white',
                    })}
                  >
                    <Icon height="20" icon="mdi-light:refresh" width="20"  />
                  </IconButton>
                  :
                  <Button
                    color="primary"
                    loading={updateStatus === "started"}
                    onClick={handleUpdate}
                    size="small"
                    startIcon={
                      <Icon height="20" icon="mdi-light:refresh" width="20"  />
                    }
                    sx={(theme)=>({
                      backgroundColor:`${theme.palette.primary.light}`,
                      color:'white',
                    })}
                  >
                    Обновить
                  </Button>
              }

              <Stack alignItems="center" direction="row" sx={(theme) => ({
                // backgroundColor:'common.white',
                borderRadius:'8px',
                border:`1px solid ${theme.palette.divider}`,
                px: 1,
                py: 0.5,
                // boxShadow: theme.customShadows.card,
              })}>
                <IconButton onClick={handlePrev} sx={{ padding: 0 }}>
                  <NavigateBeforeIcon color="secondary" />
                </IconButton>
                <ButtonGroup color="secondary" size="small" variant="text">
                  <Button
                    color="secondary"
                    onClick={handleClickMonth}
                    sx={{
                      paddingRight: 1,
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRightWidth: "2px !important",
                    }}
                  >
                    {dayjs(month).format("MMMM")}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleClickYear}
                    sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0 }}
                  >
                    {dayjs(year).format("YYYY")}
                  </Button>
                </ButtonGroup>
                <IconButton onClick={handleNext} sx={{ padding: 0 }}>
                  <NavigateNextIcon color="secondary"  />
                </IconButton>
                <Popover
                  anchorEl={anchorMonth}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={handleCloseMonth}
                  open={!!anchorMonth}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MonthCalendar onChange={(v) => setMonth(v)} value={month} />
                  </LocalizationProvider>
                </Popover>
                <Popover
                  anchorEl={anchorYear}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={handleCloseYear}
                  open={!!anchorYear}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <YearCalendar onChange={(v) => setYear(v)} value={year} />
                  </LocalizationProvider>
                </Popover>
              </Stack>
            </Stack>
          }
          data={tableData}
          editDisplayMode="cell"
          enableColumnFilters={false}
          enableEditing
          enableSorting={false}
          id={tableId}
          initialState={{
            density: "compact",
            columnPinning: {
              left: ['user']
            }
          }}
          muiTableBodyCellProps={{
            sx: {
              '& :hover':{
                // borderColor:'red'
              }
            },
          }}
          muiTableContainerProps={{
            sx: (theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: 'none',
            }),
          }}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: '#ffffff',
            },
          }}
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          state={{
            editingCell: editingCell,
            isLoading: loading,
          }}
        />
        {/*<Descriptions/>*/}
        <Stack direction="row" spacing={2} sx={{ p:2, justifyContent: 'flex-end' }}>

          <Button
            disabled={buttonsDisabled}
            onClick={handleCancel}
            size="small"
            variant="text"
          >
            Отменить
          </Button>
          <Button
            disabled={buttonsDisabled || updateStatus === "started"}
            onClick={handleSave}
            size="small"
            variant="contained"
          >
            Сохранить
          </Button>
        </Stack>
      </Card>
    </>
  );
};

export default TimesheetMonth;
