import React from 'react';

import {Icon} from "@iconify/react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from "@mui/lab";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import {getColorByStatus, LabelStatuses} from "../constants";
import Label from "./label/Label";
import useResponsive from "../hook/useResponsive";


const History = ({history}) => {
  const isMobile = useResponsive("down", "sm");

  return (
    <Box sx={{width:'100%'}}>
      <Timeline
        sx={{
          '& .MuiTimeline-root':{
            padding: "0px"
          },
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {history.map((i) => (
          <TimelineItem key={i.updated_at}>
            <TimelineSeparator>
              <TimelineDot color={getColorByStatus(i.status)} variant="outlined"/>
              <TimelineConnector sx={(theme) => ({ backgroundColor:`${theme.palette.divider}` })} />
            </TimelineSeparator>
            <TimelineContent>
              <Stack
                direction="column"
                spacing={0.5}
                sx={(theme) => ({
                  alignItems: "flex-start",
                  justifyContent:'flex-start',
                  width: 'max-content',
                  // maxWidth: '90%',
                  overflowX: 'hidden',
                  border:`1px solid ${theme.palette.divider}`,
                  padding:'8px 16px 8px 16px',
                  borderRadius:'8px'
                })}
              >
                <Stack
                  direction={ isMobile ? 'column' : 'row'}
                  spacing={1}
                  sx={{
                    alignItems: isMobile ? "flex-start" : "center",
                    justifyContent:'flex-start',
                  }}
                >
                  <Label color={getColorByStatus(i.status)}>
                    {LabelStatuses(i.status)}
                  </Label>
                  <Box>
                    <Typography color="text.primary" sx={{marginRight:'8px'}} variant="body3">
                      {dayjs(i.updated_at)
                        .utcOffset(5)
                        .format("DD.MM.YYYY HH:mm")}
                    </Typography>
                    <Typography variant="body3">
                      <Icon height="12" icon="material-symbols-light:edit-outline-rounded" width="12"  />
                      {i.user_approved.display_name}
                    </Typography>
                  </Box>
                </Stack>
                {i.comment && (
                  <Stack direction="row" spacing={1} sx={{width:'100%', }}>
                    <Icon height="18" icon="material-symbols-light:comment-outline-rounded" width="18"/>
                    <Typography variant="body2">
                      {i.comment}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default History;