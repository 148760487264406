import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {Box, Card, Divider, IconButton, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import { getSortingString } from "../../utils/getSortingString";
import InfoAboutComponent from "../InfoAboutComponent";
import MaterialTablePagination from "../MaterialTablePagination";
import { TableCellsWrap } from "../TableCellsWrap";

const MyContracts = () => {
  const tableId = "myContracts";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setMyContracts, setSelectedMyContact } = useActions();
  const { roles, myContracts, selectedMyContract } = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const [openForm, setOpenForm] = useState("");

  const tableColumns = [
    {
      accessorKey: "date_and_number",
      header: "Номер договора",
      accessorFn: (row) => row.date_and_number || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата заключения",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => {
        if (row.is_unlimited) {
          return "Бессрочный";
        } else {
          return dayjs(row.end_date).format("DD.MM.YYYY") || "";
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус договора",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "type",
      header: "Тип договора",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "legal_entity",
      header: "Юр. лицо",
      accessorFn: (row) => row.legal_entity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "production_calendar",
      header: "Производственный календарь",
      accessorFn: (row) => row.production_calendar || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "loyalty_program",
      header: "Программа лояльности",
      accessorFn: (row) => row.loyalty_program || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "interaction_capacity",
      header: "Вид занятости",
      accessorFn: (row) => row.interaction_capacity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "hour_capacity",
      header: "Объем часов Fix",
      accessorFn: (row) => {
        if (row.interaction_capacity === "Fix" && row.hour_capacity) {
          return row.hour_capacity;
        } else {
          return "";
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "hour_rate",
      header: "Ставка в час",
      accessorFn: (row) => {
        if (row.interaction_capacity === "Fix" && row.hour_rate) {
          return row.hour_rate;
        } else {
          return "";
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "month_rate",
      header: "Ставка в месяц",
      accessorFn: (row) => {
        if (row.interaction_capacity === "Fulltime" && row.month_rate) {
          return row.month_rate;
        } else {
          return "";
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "currency",
      header: "Валюта договора",
      accessorFn: (row) => row.currency || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "vacation_days",
      header: "Отпуск в год, дней",
      accessorFn: (row) => row.vacation_days || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "sick_days",
      header: "Больничный в год, дней",
      accessorFn: (row) => row.sick_days || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      setSelectedMyContact(id);
      openForm !== "View" && setOpenForm("View");
    }

  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/agreements/me/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
      setMyContracts(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setMyContracts,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <Box>
      {openForm === "View" && (
        <Card>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle2">Информация о договоре</Typography>
            <IconButton color="primary" onClick={() => setOpenForm("")} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box p={2}>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Номер договора"
                  text={selectedMyContract.date_and_number}
                />
                <InfoAboutComponent
                  label="Дата заключения"
                  text={dayjs(selectedMyContract.start_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Дата окончания"
                  text={
                    selectedMyContract.is_unlimited
                      ? "Бессрочный"
                      : dayjs(selectedMyContract.end_date).format("DD.MM.YYYY")
                  }
                />
                <InfoAboutComponent
                  label="Статус договора"
                  text={selectedMyContract.status}
                />
                <InfoAboutComponent label="Тип договора" text={selectedMyContract.type} />
                <InfoAboutComponent
                  label="Юр. лицо"
                  text={selectedMyContract.legal_entity}
                />
                <InfoAboutComponent
                  label="Производственный календарь"
                  text={selectedMyContract.production_calendar}
                />
                <InfoAboutComponent
                  label="Программа лояльности"
                  text={selectedMyContract.loyalty_program}
                />
              </ColumnStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Условия
                </Typography>
                <InfoAboutComponent
                  label="Вид занятости"
                  text={selectedMyContract.interaction_capacity}
                />
                {selectedMyContract.interaction_capacity === "Fix" && (
                  <>
                    <InfoAboutComponent
                      label="Объем часов Fix"
                      text={selectedMyContract.hour_capacity}
                    />
                    {roles?.some((role) => role === "ERP_Admins") && (
                      <InfoAboutComponent
                        label="Ставка в час"
                        text={selectedMyContract.hour_rate}
                      />
                    )}
                  </>
                )}
                {roles?.some((role) => role === "ERP_Admins") &&
                  selectedMyContract.interaction_capacity === "Fulltime" && (
                    <InfoAboutComponent
                      label="Ставка в месяц"
                      text={selectedMyContract.month_rate}
                    />
                  )}
                {roles?.some((role) => role === "ERP_Admins") && (
                  <InfoAboutComponent
                    label="Валюта договора"
                    text={selectedMyContract.currency}
                  />
                )}
                <InfoAboutComponent
                  label="Отпуск в год, дней"
                  text={selectedMyContract.vacation_days}
                />
                <InfoAboutComponent
                  label="Больничные в год, дней"
                  text={selectedMyContract.sick_days}
                />

              </ColumnStack>
            </RowStack>
          </Box>
        </Card>
      )}
      <Card sx={{ my: 2 }}>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={myContracts}
          enableHiding={true}
          id={tableId}
          initialState={{
            columnVisibility: {
              date_and_number: false,
              type: false,
              legal_entity: false,
              hour_rate: false,
              month_rate: false,
              currency: false,
              vacation_days: false,
              sick_days: false,
            },
          }}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>

  );
};

export default MyContracts;
