import React, {useEffect, useState} from "react";

import {Icon} from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Link,
  ListItemText,
  Typography
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import NavSection from "../../components/nav-section/NavSection";
import {
  AspexIcon,
  NAV_WIDTH,
  NAV_WIDTH_CLOSE
} from "../../constants";
import { useActions } from "../../hook/useActions";
import {useAuth} from "../../hook/useAuth";
import useResponsive from "../../hook/useResponsive";
import {RenderedContent, StyledNavItem} from "../../theme/standarts_styles";
import SearchFieldInAppBar from "../header/SearchFieldInAppBar";


export default function Nav() {
  const { t } = useTranslation();
  const theme = useTheme();

  const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 790px)'
  })



  const { openNav } = useSelector((state) => state.serviceReducer);
  const { toggleOpenNav, setOpenNav } = useActions();
  const isDesktop = useResponsive("up", "lg");
  const { signOut } = useAuth();
  const isMobile = useResponsive("down", "sm");



  const { display_name, email, photo, occupation} = useSelector(
    (state) => state.authReducer,
  );

  useEffect(() => {
    // if (!isDesktop && openNav) {
    //   toggleOpenNav()
    // }
    if (!isDesktopOrLaptop) {
      setOpenNav(false);
    }
  }, [isDesktopOrLaptop, setOpenNav]);

  const handleLogout = () => {
    signOut();
  };



  const [isOpen, setIsOpen] = useState(true)

  const renderContent = (
    <RenderedContent openNav={openNav}>
      <Box>
        <Stack
          direction="row"
          sx={{
            px: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {openNav ? (
            <Box
              sx={{
                width:'100%',
                display:'flex',
                justifyContent: 'flex-start',
                alignItems:"center",
              }}
            >
              <Button
                onClick={() => toggleOpenNav()}
                sx={(theme) => ({
                  py: 1,
                  px: 1.5,
                  color: "text.primary",
                })}
                variant="text"
              >
                <Icon
                  height="24"
                  icon="material-symbols-light:menu-open-rounded"
                  sx={{ color: "text.primary" }}
                  width="24"
                />
              </Button>
              <Link href="/">
                <Box
                  sx={{
                    ml: 0,
                    cursor: "pointer",
                  }}
                >
                  <AspexIcon
                    color={theme.palette.text.primary}
                    height={isMobile ? 10 : 11}
                  />
                </Box>
              </Link>
            </Box>
          ) : (
            <Button
              onClick={() => toggleOpenNav()}
              sx={(theme) => ({
                p: 1,
                // m:1,
                color: "text.primary",
              })}
              variant="text"
            >
              <Icon
                height="24"
                icon="material-symbols-light:menu-rounded"
                sx={{ color: "text.primary" }}
                width="24"
              />
            </Button>
          )}
        </Stack>
        <Box sx={{ my:1.5}}>
          {openNav ? (
            <Stack
              direction="row"
              sx={{
                px: 1.5,
                my: 1,
              }}
            >
              <SearchFieldInAppBar width="100%" />
            </Stack>
          ) : (
            <Box
              sx={{
                color: "text.primary",
                m: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                height="22"
                icon="material-symbols-light:search"
                onClick={() => toggleOpenNav()}
                sx={{ color: "text.secondary" }}
                width="22"
              />
            </Box>
          )}
        </Box>
        <NavSection />
      </Box>

      {isDesktopOrLaptop &&
        <Box
          sx={{
            px: 2,
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <StyledNavItem
                onClick={() => setIsOpen(!isOpen)}
                sx={{
                  px: openNav && 1,
                  mb:1,
                  height: "40px",
                }}
              >
                {openNav ? (
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt="photoURL"
                      src={photo}
                      sx={{
                        width: "28px",
                        height: "28px",
                        mr: isDesktop ? 1 : 0,
                      }}
                    />
                    <Stack
                      direction="column"
                      sx={{
                        width: "60%",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          color: "text.primary",
                          textAlign: "start",
                          fontWeight: "bold",
                        }}
                        variant="body3"
                      >
                        {display_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "start",
                          fontSize: "10px",
                        }}
                        variant="body3"
                      >
                        {occupation}
                      </Typography>
                    </Stack>
                    <ExpandMoreIcon
                      fontSize="small"
                      sx={{
                        color: "text.primary",
                        transform: `${!isOpen && 'rotate(180deg)'}`,
                        transition: 'transform .2s ease-out',
                      }}
                    />
                  </Stack>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt="photoURL"
                      src={photo}
                      sx={{
                        width: "32px",
                        height: "32px",
                        border: !openNav && "2px solid #fafafa",
                      }}
                    />
                  </Box>
                )}
              </StyledNavItem>
            </Box>
            <Collapse in={!isOpen} sx={{ width:'100%' }}>
                <>
                  <StyledNavItem
                    href='/account/general'
                    sx={{color: "text.primary",}}>
                  {openNav ? (
                    <Stack
                      direction="row" px={1} spacing={1}
                    >
                      <Icon height="20" icon="material-symbols-light:account-circle-outline" width="20"  />
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body2">
                            {t("src.layouts.dashboard.header.AccountPopover.munuOption1")}
                          </Typography>
                        }
                      />
                    </Stack>
                  ) : (
                    <Grid
                      alignItems="center"
                      container
                      direction="column"
                      justifyContent="center"
                      sx={{
                        width:'100%',
                      }}
                    >
                      <Icon height="20" icon="material-symbols-light:account-circle-outline" width="20"  />
                    </Grid>

                  )}
                  </StyledNavItem>
                  <Divider sx={{ borderStyle: "dashed", color: "divider" }} />
                  <StyledNavItem onClick={handleLogout} sx={{color:'error.darker'}}>
                  {openNav ? (
                    <Stack
                      direction="row" px={1}
                      spacing={1}
                    >
                      <Icon height="20" icon="material-symbols-light:logout-rounded" width="20"  />
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body2">
                            {t("src.layouts.dashboard.header.AccountPopover.exit")}
                          </Typography>
                        }
                      />
                    </Stack>
                  ) : (
                    <Grid
                      alignItems="center"
                      container
                      direction="column"
                      justifyContent="center"
                      onClick={handleLogout}
                      sx={{
                        width:'100%',
                      }}
                    >
                      <Icon height="20" icon="material-symbols-light:logout-rounded" width="20"  />
                    </Grid>
                  )}
                  </StyledNavItem>
                  </>
            </Collapse>
          </Box>

        </Box>
      }
    </RenderedContent>
  );

  return (
    <Box
      component="nav"
      sx={(theme) => ({
        flexShrink:  0 ,
        height:'100vh',
        backgroundColor: "transparent",
        boxShadow: 'none',
        [theme.breakpoints.up("790")]: {
          width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
        },
        [theme.breakpoints.down("790")]: {
          width: openNav && NAV_WIDTH,
        },
      })}
    >
      <Drawer
        PaperProps={(theme) => ({
          sx: {
            width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
            overflow: "hidden",
            boxShadow: 'none',
            [theme.breakpoints.up("790")]: {
              visibility: "visible",
            },
            [theme.breakpoints.down("790")]: {
              visibility: "hidden",
            },
          },
        })}
        onClose={toggleOpenNav}
        open={openNav}
        sx={(theme)=>({
          '& .MuiDrawer-paper':{
            width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
            height:'100vh',
            backgroundColor: 'transparent',
            transition: "all 0.2s ease",
            p:0
          },
        })}
        variant={ isDesktopOrLaptop ? "permanent" : "temporary"}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
