import React, {useCallback, useEffect, useRef, useState} from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";


const types = {
  vacation: "Отпуск",
  day_off: "Отгул",
  sick_leave: "Больничный",
  overtime_work_days: "Сверхурочные часы",
  fired: "Увольнение",
  working_holidays: "Работа в праздники/выходные",
  promotion: "Поощрение",
};

const payers = {
  company_expense: "За счет компании",
  self_expense: "За свой счет",
  overwork_expense: "За счет переработок",
};


const MyStatement = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recallConfirm, setRecallConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const { statementId } = useParams();
  const navigate = useNavigate();
const ref1 = useRef(null);
  const { setStatement } = useActions();

  const { statement } = useSelector((state) => state.calendarReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onRecall = async (data) => {
    setLoading(true);

    try {
      await $authHost.post(`/calendar_rules/claim/${statementId}/cancel/`, {
        comment: data.comment,
      });
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/schedule/my-statements");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/schedule/my-statements`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${statementId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [statementId]);

  const getStatement = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${statementId}/`);
      const data = response.data;
      setStatement(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setStatement, statementId]);

  useEffect(() => {
    getStatement();
    getHistory();
  }, [getHistory, getStatement]);

  return (
    <Card sx={{ mb: 2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 2,
        }}
      >
        <Typography variant="subtitle2">Заявление</Typography>
        <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box p={2}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onRecall)}>
            <RowStack>
              <ColumnStack customSpace={0.5} ref={ref1} >
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Тип заявки"
                  text={types[statement?.type]}
                />
                {statement?.type !== "fired" && (
                  <InfoAboutComponent
                    label="За чей счет"
                    text={payers[statement?.payer]}
                  />
                )}
                <InfoAboutComponent
                  label="Дата создания"
                  text={dayjs(statement?.created_at).format(
                    "DD.MM.YYYY",
                  )}
                />
                <InfoAboutComponent
                  label="Сотрудник"
                  text={statement?.user?.display_name}
                />
                <InfoAboutComponent
                  label="Отдел"
                  text={statement?.department?.name}
                />
                <InfoAboutComponent
                  label="Текст запроса"
                  text={statement?.request_text}
                />
                <InfoAboutComponent
                  label="Дата начала"
                  text={dayjs(statement?.start_date).format(
                    "DD.MM.YYYY",
                  )}
                />
                <InfoAboutComponent
                  label="Дата окончания"
                  text={dayjs(statement?.end_date).format(
                    "DD.MM.YYYY",
                  )}
                />
              </ColumnStack>
              <ColumnStack
                customSpace={0.1}
                sx={{
                  height:`${ref1?.current?.clientHeight}px`,
                  overflowY:'auto'
                }}
              >
                <Typography align="left" component="h2" pt={1} variant="miniHeader">
                  История
                </Typography>
                <History history={history} />
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack>
                <Stack
                  direction="column"
                  spacing={0}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    align="left"
                    component="h2"
                    pb={2}
                    pt={2}
                    variant="miniHeader"
                  >
                    Действия
                  </Typography>
                  <CustomTextField
                    disabled={statement?.status !== "new"}
                    label="Комментарий"
                    multiline
                    name="comment"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    rows={2}
                    size="small"
                    sx={{ width: "100%" }}
                  />
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    {recallConfirm ? (
                      <>
                        <Typography color="text.secondary" sx={{
                          width:'100%',
                          overflow:'visible',
                          whiteSpace: "normal",
                        }} variant="body2"
                        >
                          Вы уверены, что хотите отозвать заявление?
                        </Typography>
                        <Button
                          color="primary"
                          size="small"
                          type="submit"
                          variant="text"
                        >
                          Да
                        </Button>
                        <Button
                          onClick={() => setRecallConfirm(false)}
                          size="small"
                          variant="contained"
                        >
                          Нет
                        </Button>
                      </>
                    ) : (
                      <Tooltip title="Заявление может быть отозвано сотрудником, если оно в статусе “Новое” и не входит в закрытый период табеля.">
                        <Button
                          color="primary"
                          disabled={statement?.status !== "new"}
                          onClick={() => setRecallConfirm(true)}
                          size="small"
                          sx={{
                            "&.Mui-disabled": {
                              pointerEvents: "auto",
                            },
                          }}
                          variant="outlined"
                        >
                          Отозвать
                        </Button>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </ColumnStack>
              <ColumnStack />
            </RowStack>
          </form>
        </FormProvider>
      </Box>
    </Card>
  );
};

export default MyStatement;
