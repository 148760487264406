import React from "react";

import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import { matchIsValidTel } from "mui-tel-input";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useResponsive from "../../hook/useResponsive";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import CustomMuiTelInput from "../TextFields/CustomMuiTelInput";
import CustomTextField from "../TextFields/CustomTextField";

const ContactsForm = () => {
  const { t } = useTranslation();
  const isMobile = useResponsive("down", "sm");

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const phone = watch("phone");
  const email = watch("email");

  return (
    // <>
      <Box>
        <RowStack>
          <ColumnStack>
            <Typography align="left" component="h2" pb={1} variant="miniHeader">
              Общее
            </Typography>
            <CustomTextField
              label="ФИО"
              name="full_name"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />
            <CustomTextField
              label="Должность"
              name="position"
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />
            <CustomTextField
              label="Комментарий"
              name="notes"
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />
          </ColumnStack>
          <ColumnStack>
            <Typography align="left" component="h2" pb={1} variant="miniHeader">
              Связаться
            </Typography>
            <CustomMuiTelInput
              label={t(
                "src.components.exportComponents.PaymentPlanForm.table_tel",
                "Телефон",
              )}
              name="phone"
              rules={{
                required: phone || email ? false : t("main.another.requiredField"),
                validate: {
                  validPhone: (phone) => {
                    if (!phone) {
                      return true;
                    } else if (!matchIsValidTel(phone)) {
                      return "Некорректный номер телефона";
                    }
                    return true; // Возвращает true, если проверка прошла успешно
                  },
                },
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 2 }}
            />
            <CustomTextField
              label="Email"
              name="email"
              registerOptions={{
                required: phone || email ? false : t("main.another.requiredField"),
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: t("main.another.wrongInput"),
                },
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />

            <CustomTextField
              label="Дополнительноя коммуникация"
              name="additional_communication"
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />
          </ColumnStack>
        </RowStack>
      </Box>
    // </>
  );
};

export default ContactsForm;
