import React, { useCallback, useEffect, useRef, useState } from "react";

import {Icon} from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {Avatar, Badge, Box, Button, Card, Divider, Stack, Tooltip, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import TitleAndButtonComponent from "../../layouts/StandardElementsForPages/TitleAndButtonComponent";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import AlertMessage from "../Alert/AlertMessage";
import BlockFormModal from "../BlockFormModal";
import LoadingSpinner from "../modals/loadingSpinner";
import RemoteDynamicTabs from "../Tabs/RemoteDynamicTabs";
import CustomTextField from "../TextFields/CustomTextField";

const acceptFileType = ["image/jpeg", "image/png"];

const UserData = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [uploadedAvatarLabel, setUploadedAvatarLabel] = useState(null);
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "sm");

  const tabs = [
    { label: "Обзор", path: "general" },
    { label: "Договорные отношения", path: "contracts", disabled: true },
  ];

  const handleBack = () => {
    navigate('/account');
  }

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setValue,
    control,
  } = methods;

  const {
    display_name,
    first_name,
    last_name,
    email,
    phone,
    birth_date,
    about_me,
    country,
    photo,
    department,
    street_address,
    work_schedule,
    communication_channels,
    startTime,
    groups,
    occupation,
    manager
  } = useSelector((state) => state.authReducer);

  const handleReset = useCallback(() => {
    setValue("first_name", first_name);
    setValue("last_name", last_name);
    setValue("email", email);
    setValue("phone", phone);
    setValue("birth_date", birth_date);
    setValue("country", country);
    setValue("street_address", street_address);
    setValue("work_schedule", work_schedule);
    setValue("communication_channels", communication_channels);
    setValue("about_me", about_me);
  }, [
    birth_date,
    communication_channels,
    country,
    email,
    first_name,
    last_name,
    phone,
    setValue,
    street_address,
    work_schedule,
    about_me
  ]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const { setErrorAlertMessage, updateUser } = useActions();

  const inputRef = useRef(null);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (
      fileObj &&
      acceptFileType.indexOf(fileObj.type) !== -1 &&
      fileObj.size / 1024 ** 2 < 3.6
    ) {
      const FR = new FileReader();
      FR.addEventListener("load", function (evt) {
        setUploadedAvatar(evt.target.result);
      });
      FR.readAsDataURL(event.target.files[0]);
      setUploadedAvatarLabel(fileObj.name);
    } else {
      setErrorAlertMessage(t("src.pages.AccountPage.UserData.extension"));
    }
  };

  const cleanInput = () => {
    setUploadedAvatar(null);
    setUploadedAvatarLabel(null);
  };

  const loadData = useCallback(() => {
    try {
      const getUser = async () => {
        const response = await $authHost.get(`/users/me/`);
        return response.data;
      };
      getUser().then(
        ({
          display_name,
          first_name,
          last_name,
          email,
          phone,
          birth_date,
          work_schedule,
          country,
          photo,
          street_address,
          communication_channels,
          groups, about_me, source_id,
          department, manager, occupation
        }) => {
          updateUser({
            display_name,
            first_name,
            last_name,
            email,
            phone,
            birth_date,
            work_schedule,
            country,
            photo,
            street_address,
            communication_channels,
            groups,
            about_me,
            source_id,
            department, manager, occupation
          });
        },
      );
    } catch (e) {
      console.log(e);
    }
  }, [updateUser]);

  const onSubmit = async ({ email, photo, ...data }) => {
    setLoading(true);
    const newData = data;

    newData.display_name = `${data.first_name} ${data.last_name}`;
    if (uploadedAvatar !== null) {
      newData.photo = uploadedAvatar.split(",")[1];
    }

    try {
      await $authHost.put(`/users/me/update/`, newData);
    } catch (e) {
      console.log(e);
    } finally {
      loadData();
      navigate("/account");
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        pb:2
      }}
    >
      <TitleAndButtonComponent
        back={()=>{navigate("/");}}
        backTitle="Главная"
        title="Профиль"
      />
      <Card
        sx={{backgroundColor:'none', border:'none'}}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          py={1}
          spacing={isMobile? 1 : 2}
          sx={{
            justifyContent: "flex-start",
            alignItems: { xs: "center", sm: "stretch" },
          }}
        >
          <Avatar
            alt="photoURL"
            src={photo}
            sx={(theme) => ({
              width: 130, height: 130,
              border:`2px solid ${theme.palette.secondary.light}`
            })}
          />
          <Stack
            direction={{ xs: "column" }}
            spacing={isMobile? 2:3}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Box>
              <Typography
                align={isMobile ? "center" : "flex-start"}
                color="text.primary"
                component="p"
                variant="h5Wide"
              >
                {first_name} {last_name}
              </Typography>
              <Typography align={isMobile ? "center" : "flex-start"} color="text.secondary" component="p" variant="body3">
                {occupation} / {department ? department?.name : `Отдел не указан`}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              {email && (
                <>
                  { isMobile ?
                    <IconButton
                      color="secondary"
                      onClick={() => copy(email)}
                      size="small"
                      sx={(theme)=>({
                        borderRadius:'90px',
                        border:`1px solid ${theme.palette.secondary.main}`,
                      })}
                    >
                      <Icon icon="material-symbols-light:stacked-email-outline-rounded" />
                    </IconButton>
                    :
                    <Tooltip title="Копировать почту">
                      <Chip
                        color="secondary"
                        icon={<Icon icon="material-symbols-light:stacked-email-outline-rounded" />}
                        label={email}
                        onClick={() => copy(email)}
                        sx={{fontSize:12}}
                        variant="outlined"
                      />
                    </Tooltip>
                  }
                </>
              )}
              {phone && (
                <>
                  {
                    isMobile ?
                      <IconButton
                        color="secondary"
                        onClick={() => copy(phone)}
                        size="small"
                        sx={(theme)=>({
                          borderRadius:'90px',
                          border:`1px solid ${theme.palette.secondary.main}`,
                        })}
                      >
                        <Icon icon="material-symbols-light:phone-enabled-outline" />
                      </IconButton>
                      :
                      <Tooltip title="Копировать телефон">
                        <Chip
                          color="secondary"
                          icon={<Icon icon="material-symbols-light:phone-enabled-outline" />}
                          label={phone}
                          onClick={() => copy(phone)}
                          sx={{fontSize: 12}}
                          variant="outlined"
                        />
                      </Tooltip>
                  }
                </>
              )}
            </Stack>

          </Stack>
        </Stack>
      </Card>
      <RemoteDynamicTabs tabs={tabs} />
      <Box pb={2}>
        <Card sx={{width:{ xs: "100%", md:"100%" }}}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle2">Редактировать</Typography>
            {isMobile ? (
              <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
                <CloseIcon />
              </IconButton>

            ) : (
              <Button
                color="primary"
                onClick={handleBack}
                size="small"
                startIcon={
                  <CloseIcon />
                }
                variant="text"
              >
                Отмена
              </Button>
            )}
          </Stack>
          <Divider />
          <Box p={2}>
            {loading && <LoadingSpinner />}
            <AlertMessage />
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <RowStack>
                  <ColumnStack customSpace={0.5}>
                    <Typography
                      align="left"
                      component="h2"
                      pb={1}
                      variant="miniHeader"
                    >
                      Общие данные
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={3}
                      sx={{
                        justifyContent:'flex-start',
                        alignItems:'flex-end',
                        width:'100%',
                      }}
                    >
                      <Stack alignItems="flex-start">
                        <div className="containerImg">
                          <Badge
                            badgeContent={
                              <IconButton
                                onClick={cleanInput}
                                size="small"
                                sx={{padding:'0px'}}
                              >
                                <Icon color="#fff" icon="material-symbols:close-rounded"/>
                              </IconButton>
                            }
                            color="primary"
                            invisible={uploadedAvatar === null}
                            overlap="circular"
                            sx={{
                              '& .MuiBadge-badge':{
                                padding:'0px'
                              }
                            }}
                          >
                            <Avatar
                              alt="photoURL"
                              id="img"
                              src={uploadedAvatar || photo}
                              sx={{ width: 150, height: 150, mb: 2 }}
                            />
                          </Badge>
                          <input
                            id="avatar"
                            onChange={handleFileChange}
                            ref={inputRef}
                            style={{ display: "none" }}
                            type="file"
                          />
                          <div
                            className="overlay"
                            onClick={() => {
                              inputRef.current.click();
                            }}
                          >
                            <IconButton aria-label="delete" className="icon">
                              <Icon icon="material-symbols-light:upload-rounded"/>
                            </IconButton>
                          </div>
                        </div>
                      </Stack>
                      <div style={{width: `calc(100% - 150px)`}}>
                        <CustomTextField
                          label="Имя"
                          name="first_name"
                          registerOptions={{
                            required: t("main.another.requiredField"),
                          }}
                          size="small"
                          sx={{ width: "100%", mb: 3 }}
                        />
                        <CustomTextField
                          label="Фамилия"
                          name="last_name"
                          registerOptions={{
                            required: t("main.another.requiredField"),
                          }}
                          size="small"
                          sx={{ width: "100%", mb: 3 }}
                        />
                      </div>
                    </Stack>

                    <Controller
                      control={control}
                      name="birth_date"
                      render={({
                                 field: { onChange, value },
                                 fieldState: { error },
                               }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            control={control}
                            format="DD/MM/YYYY"
                            label="Дата рождения"
                            onChange={(e) => {
                              onChange(e);
                            }}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: "small",
                                InputLabelProps: {
                                  shrink: true,
                                },
                              },
                            }}
                            sx={(theme) => ({
                              width: "100%",
                              mb: 2,
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              }
                            })}
                            value={dayjs(value)}
                          />
                        </LocalizationProvider>
                      )}
                      rules={{
                        required: t("main.another.requiredField"),
                      }}
                    />
                    <CustomTextField
                      label="Обо мне"
                      name="about_me"
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                    <Typography
                      align="left"
                      component="h2"
                      pb={1}
                      pt={2}
                      variant="miniHeader"
                    >
                      Контакты
                    </Typography>
                    <CustomTextField
                      label="Телефон"
                      name="phone"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                      }}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                    <CustomTextField
                      disabled
                      label="Email"
                      name="email"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                      }}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                    <CustomTextField
                      label="Доп. контакт"
                      multiline
                      name="communication_channels"
                      rows={3}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                  </ColumnStack>
                  <ColumnStack customSpace={0.5}>

                    <Typography
                      align="left"
                      component="h2"
                      pb={1}
                      pt={0}
                      variant="miniHeader"
                    >
                      Локация
                    </Typography>
                    <CustomTextField
                      label="Страна"
                      name="country"
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                    <CustomTextField
                      label="Рабочий график"
                      multiline
                      name="work_schedule"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                      }}
                      rows={3}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                    <CustomTextField
                      label="Почтовый адрес"
                      multiline
                      name="street_address"
                      rows={3}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                  </ColumnStack>
                </RowStack>
                {isMobile ? (
                  <Stack
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                    sx={{ width: "100%", mt: 3 }}
                  >
                    <Button sx={{ width: "100%" }} type="submit" variant="contained">
                      Сохранить
                    </Button>
                    <Button
                      onClick={handleReset}
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Сбросить
                    </Button>
                  </Stack>
                ) : (
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Button
                      color="primary"
                      onClick={handleReset}
                      size="small"

                      variant="outlined"
                    >
                      Сбросить
                    </Button>
                    <Button size="small" type="submit" variant="contained">
                      Сохранить
                    </Button>
                  </Stack>
                )}
              </form>
            </FormProvider>
            <BlockFormModal when={isDirty && !isSubmitSuccessful} />
          </Box>
        </Card>
    </Box>
    </Box>
  );
};

export default UserData;
