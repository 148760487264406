import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CustomTextFieldNew = ({
  name,
  label,
  required,
  rules,
  inputLabelProps,
  helperText,
  sx,
  ...props
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  if (required) {
    rules = { required: t("main.another.requiredField"), ...rules };
  }

  // TODO: Добавить переводы
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <TextField
            {...field}
            FormHelperTextProps={{
              sx: {
                position: "absolute",
                bottom: "-20px",
                fontSize: "12px",
              },
            }}
            InputLabelProps={{
              shrink: true,
              ...inputLabelProps,
            }}
            error={!!error}
            fullWidth
            helperText={(error && error?.message) || helperText}
            label={`${label}${required || !!rules?.required ? " *" : ""}`}
            onChange={(e) => {
              onChange(e);
            }}
            size="small"
            sx={(theme) => ({
              '& .MuiOutlinedInput-root':{
                '& fieldset': {
                  borderColor: `${theme.palette.divider}`,
                },
              },
              width:'100%', mb: 2,
              '& input': {
                fontSize:'15px'
              },
              '& .MuiInputBase-inputMultiline':{
                fontSize:'15px'
              },
              ...sx
            })}
            value={value}
            variant="outlined"
            {...props}
          />
        );
      }}
      rules={rules}
    />
  );
};

CustomTextFieldNew.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rules: PropTypes.object,
  inputLabelProps: PropTypes.object,
  sx: PropTypes.object,
  helperText: PropTypes.string,
};

export default CustomTextFieldNew;
