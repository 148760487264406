import React, { forwardRef } from "react";

import {Icon} from "@iconify/react";
import {
  Box, Button,
  Link,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import { useSelector } from "react-redux";
import {useMediaQuery} from "react-responsive";

import { blackLogo, NAV_WIDTH, AspexIcon} from "../constants";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";


// eslint-disable-next-line react/display-name
const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { isInitAuth } = useSelector((state) => state.authReducer);
  const theme = useTheme();
  const { openNav } = useSelector((state) => state.serviceReducer);
  const { toggleOpenNav } = useActions();
  const isMobile = useResponsive("down", "sm");

  return (
    <>
      {isInitAuth === true ? (
        <Box
          sx={{
            p: 0,
            ml:1,
            width: "max-content",
          }}
        >
          <Stack
            direction="row"
            sx={{
              py: 0,
              // width: "max-content",
              alignItems:'center',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              color="secondary"
              onClick={() => toggleOpenNav()}
              sx={(theme) => ({
                px: 1,
              })}
              variant="text"
            >
              {openNav ?
                <Icon
                  height="22"
                  icon="material-symbols-light:menu-open-rounded"
                  width="24"
                />
              :
                <Icon
                  height="22"
                  icon="material-symbols-light:menu-rounded"
                  width="24"
                />
              }

            </Button>

            <Link href="/">
              <Box
                sx={{
                  // ml: isMobile ? 0 : 3,
                  cursor: "pointer",
                  ...sx,
                }}
              >
                <AspexIcon
                  color={theme.palette.text.primary}
                  height={isMobile ? 10 : 11}
                />
              </Box>
            </Link>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            p: 0,
            width: NAV_WIDTH,
          }}
        >
          <Box
            component="img"
            src={blackLogo}
            sx={{
              height: 20,
              ml: 1,
              cursor: "pointer",
              ...sx,
            }}
          />
        </Box>
      )}
    </>
  );
});

export default Logo;
