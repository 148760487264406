import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete, Box,
  Button,
  Card, Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextField from "../../components/TextFields/CustomTextField";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isSameOrBefore);

const EmploymentAdd = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMobile = useResponsive("down", "sm");


  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      start_date: null,
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    control,
    resetField,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  const onSubmit = async ({ start_date, ...data }) => {
    setLoading(true);

    const newData = {
      ...data,
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      status: "Новое",
    };

    try {
      await $authHost.post(`/employments/add/`, convertToNull(newData));
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/employee-relations/employments");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/employee-relations/employments`);
  };

  const country = useWatch({ control, name: "country" });

  const companies = useCallback(() => {
    if (country === "Россия" || country === "Беларусь") {
      return ['ООО "АСПЭКС"', 'ООО "WHITE DATA"'];
    } else if (country === "Казахстан") {
      return ['ТОО "АСПЭКС ГРУПП"'];
    } else {
      return [];
    }
  }, [country]);

  useEffect(() => {
    resetField("company_name");
  }, [country, resetField, setError]);

  return (
    <Card>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p:2
        }}
      >
        <Typography variant="subtitle2">
          Новое трудоустройство
        </Typography>
        {isMobile ?
          <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
          :
          <Button
            color="primary"
            onClick={handleBack} startIcon={
            <CloseIcon />
          }
            sx={{ px: 1, py:0 }}
            variant="text"
          >
            Отмена
          </Button>
        }
      </Stack>
      <Divider/>
      <Box sx={{ p: 3 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack full={true}>
                <CustomTextField
                  helperText="Введите Имя и Фамилию сотрудника на латинице"
                  label="Сотрудник"
                  name="employee"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^[^\u0400-\u04FF]*$/,
                      message: "Введите Имя и Фамилию сотрудника на латинице",
                    },
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <CustomTextField
                  label="Должность"
                  name="occupation"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <CustomTextField
                  label="Корпоративный email"
                  name="email"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@aspex\.kz$/,
                      message:
                        "Ошибка: Неверный домен. Убедитесь, что адрес электронной почты заканчивается на @aspex.kz",
                    },
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <Controller
                  control={control}
                  name="start_date"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        control={control}
                        format="DD.MM.YYYY"
                        label="Дата выхода на работу *"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            size: "small",
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                        sx={(theme) => ({
                          '& .MuiOutlinedInput-root':{
                            '& fieldset': {
                              borderColor: `${theme.palette.divider}`,
                            },
                          },
                          mb: 2, width:'100%',
                          '& input': {
                            fontSize:'15px'
                          },
                          '& .MuiInputBase-inputMultiline':{
                            fontSize:'15px'
                          }
                        })}
                        value={dayjs(value)}
                      />
                    </LocalizationProvider>
                  )}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Россия", "Беларусь", "Казахстан"]}
                        renderInput={(params) => (
                          <TextField
                            label="Страна трудоустройства *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              }
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={companies()}
                        renderInput={(params) => (
                          <TextField
                            label="Юр. Лицо *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              }
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <Controller
                  control={control}
                  name="employment_type"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Трудоустройство", "Самозанятость", "ИП"]}
                        renderInput={(params) => (
                          <TextField
                            label="Форма трудоустройства *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              }
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
              </ColumnStack>
            </RowStack>
                {isMobile ? (
                  <Stack
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                    sx={{ width: "100%", mt: 2 }}
                  >
                    <Button
                      disabled={loading}
                      sx={{ width: "100%" }}
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button
                      onClick={handleReset}
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Сбросить
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1} sx={{justifyContent: "flex-end"}}>
                    <Button
                      color="primary"
                      onClick={handleReset}
                      size="small"
                      variant="outlined"
                    >
                      Сбросить
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                  </Stack>
                )}

          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Box>
    </Card>
  );
};

export default EmploymentAdd;
