import React from "react";

import { Box, Button, Dialog } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ReactRouterPrompt from "react-router-prompt";

// TODO: Fix component
const BlockFormModal = ({
  when,
  title = "Есть несохраненные данные в форме",
}) => (
  // <ReactRouterPrompt when={when}>
  //   {({ isActive, onConfirm, onCancel }) => (
  //     <Dialog open={isActive} sx={{ zIndex: 9999 }}>
  //       <Box sx={{ p: 4 }}>
  //         <Typography sx={{ mb: 2 }} variant="h5">
  //           {title}
  //         </Typography>
  //         <Typography sx={{ mb: 2 }} variant="body1">
  //           Вы действительно хотите выйти? Все несохраненные данные будут
  //           утеряны.
  //         </Typography>
  //         <Stack direction="row" spacing={1}>
  //           <Button onClick={onCancel} variant="contained">
  //             Отмена
  //           </Button>
  //           <Button onClick={onConfirm} variant="text">
  //             Выйти
  //           </Button>
  //         </Stack>
  //       </Box>
  //     </Dialog>
  //   )}
  // </ReactRouterPrompt>
  <></>
);

export default BlockFormModal;
