import React, { useCallback, useEffect, useMemo, useState } from "react";

import {Icon} from "@iconify/react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button, Card,
  Checkbox, Divider,
  Grid, IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";

import { currencies2 } from "../../constants";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import BlockFormModal from "../BlockFormModal";
import SkeletonInput from "../SkeletonInput";
import CustomTextField from "../TextFields/CustomTextField";
import VirtualizedAutocomplete from "../VirtualizedAutocomplete";

const CommonSettings = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { setProject, setClientsAll, setEmployees } = useActions();
  const isMobile = useResponsive("down", "sm");

  const { clientsAll } = useSelector((state) => state.clientsReducer);
  const { employees } = useSelector((state) => state.employeesReducer);
  const { project } = useSelector((state) => state.projectsReducer);

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const loadProject = useCallback(() => {
    setLoading(true);
    try {
      const getProject = async () => {
        const response = await $authHost.get(`/projects/${projectId}/`);
        setProject(response.data);
      };
      getProject();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [projectId, setProject]);

  const loadClients = useCallback(() => {
    try {
      const getClients = async () => {
        const response = await $authHost.get("/clients/all/dropdown/");
        setClientsAll(response.data);
      };
      getClients();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setClientsAll]);

  const loadUsers = useCallback(() => {
    try {
      const getUsers = async () => {
        const response = await $authHost.get("/users/all/");
        setEmployees(response.data.items);
      };
      getUsers();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setEmployees]);

  useEffect(() => {
    loadProject();
    loadClients();
    loadUsers();
  }, [loadClients, loadProject, loadUsers]);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    getValues,
    setValue,
    setError,
    handleSubmit,
    register,
    control,
    reset,
  } = methods;

  useEffect(() => {
    reset({
      ...project,
      team: Array.isArray(project.team) ? project.team : [],
    });
  }, [project, reset]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSubmit = async (data) => {
    setLoading(true);
    const {
      client,
      code,
      currency,
      start_date,
      end_date,
      actual_start_date,
      actual_end_date,
      manager,
      name,
      notes,
      status,
      team,
      currency_budget,
      commercial_hours_budget,
      internal_hours_budget,
    } = data;

    const newData = {
      code,
      name,
      notes,
      status,
      currency_budget,
      commercial_hours_budget,
      internal_hours_budget,
      currency: currency?.value,
      client_id: client?.source_id || client?.id,
      start_date: start_date
        ? dayjs(start_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      end_date: end_date ? dayjs(end_date).format("YYYY-MM-DDTHH:mm:ss") : null,
      actual_start_date: actual_start_date
        ? dayjs(actual_start_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      actual_end_date: actual_end_date
        ? dayjs(actual_end_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      manager_id: manager?.source_id,
      team:
        Array.isArray(team) && team.length > 0
          ? team.map((user) => user?.source_id)
          : [],
    };
    // console.log(data);
    // console.log(newData);
    try {
      await $authHost.put(`/projects/${projectId}/update/`, newData);
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/projects");
    }
  }, [isSubmitSuccessful, navigate]);

  // const clickupList = [
  //   { id: 1, name: 'name 1' },
  //   { id: 2, name: 'name 2' },
  //   { id: 3, name: 'name 3' },
  //   { id: 4, name: 'name 4' },
  //   { id: 5, name: 'name 5' },
  // ];

  const clickupList = ["name 1", "name 2", "name 3", "name 4", "name 5"];

  // TODO: Придумать лучший способ обновления формы после редактирования поля


  const manager = useWatch({ control, name: "manager" });

  useEffect(() => {
    const team = getValues("team");
    if (manager && Array.isArray(team)) {
      const managerInTeam = team.find((v) => v?.id === manager?.id);
      if (!managerInTeam) {
        setValue("team", [manager, ...team]);
      }
    }
  }, [getValues, manager, setValue]);

  const handleBack = () => {
    navigate(`/projects/${projectId}/`);
  };

  const [currencyAdornmentLabel, setCurrencyAdornmentLabel] = useState("$");
  const currency = useWatch({ control, name: "currency" });

  useEffect(() => {
    currency?.label && setCurrencyAdornmentLabel(currency.label);
  }, [currency]);

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{
                p:2
              }}
            >
              <Typography variant="subtitle2">
                Редактировать
              </Typography>
              {isMobile ?
                <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
                :
                <Button
                  color="primary"
                  onClick={handleBack}
                  size="small"
                  startIcon={
                    <CloseIcon />
                  }
                  variant="text"
                >
                  Отмена
                </Button>
              }
            </Stack>
            <Divider/>
            <Box
              sx={{
                flexDirection: "column",
                px: 2, pb: 4, pt:2
              }}
            >
              <RowStack>
                <ColumnStack>
                  <Typography align="left" component="h2" pb={1} variant="miniHeader">
                    Общее
                  </Typography>
                  <SkeletonInput loading={loading}>
                    <CustomTextField
                      label="Код проекта *"
                      {...register("code", {
                        required: "Обязательное поле",
                      })}
                      // error={!!errors?.code}
                      // helperText={errors?.code?.message}
                      fullWidth
                      size="small"
                      sx={{ width: "100%", fontSize: "12px", mb:2 }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <CustomTextField
                      label="Наименование проекта *"
                      {...register("name", {
                        required: "Обязательное поле",
                      })}
                      // error={!!errors?.name}
                      // helperText={errors?.name?.message}
                      fullWidth
                      size="small"
                      sx={{ width: "100%", fontSize: "12px", mb: 2 }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <VirtualizedAutocomplete
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      label="Клиент"
                      loading={loading}
                      name="client"
                      options={clientsAll}
                      required
                      sx={{
                        mb: 2,
                        width:'100%'
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <CustomTextField
                      label="Статус проекта *"
                      {...register("status", {
                        required: "Обязательное поле",
                      })}
                      // error={!!errors?.status}
                      // helperText={errors?.status?.message}
                      fullWidth
                      size="small"
                      sx={{ width: "100%", fontSize: "12px", mb: 2 }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <CustomTextField
                      label="Описание"
                      {...register("notes")}
                      // error={!!errors?.notes}
                      // helperText={errors?.notes?.message}
                      fullWidth
                      multiline={true}
                      rows={4}
                      size="small"
                      sx={{ width: "100%", fontSize: "12px", mb: 2 }}
                    />
                  </SkeletonInput>
                </ColumnStack>
                <ColumnStack>
                  <Typography align="left" component="h2" pb={1} variant="miniHeader">
                    Интеграция
                  </Typography>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="clickup"
                      render={({
                                 field: { ref, ...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => field.onChange(value)}
                            onInputChange={(_, data) => {
                              if (data) field.onChange(data);
                            }}
                            options={clickupList}
                            renderInput={(params) => (
                              <TextField
                                label="ClickUp"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                                inputRef={ref}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2, width: "100%",
                            }}
                          />
                        );
                      }}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="teams"
                      render={({
                                 field: { ref, ...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => field.onChange(value)}
                            onInputChange={(_, data) => {
                              if (data) field.onChange(data);
                            }}
                            options={clickupList}
                            renderInput={(params) => (
                              <TextField
                                label="Teams"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                                inputRef={ref}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2, width: "100%",
                            }}
                          />
                        );
                      }}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="azure_dev_ops"
                      render={({
                                 field: { ref, ...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => field.onChange(value)}
                            onInputChange={(_, data) => {
                              if (data) field.onChange(data);
                            }}
                            options={clickupList}
                            renderInput={(params) => (
                              <TextField
                                label="Azure"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                                inputRef={ref}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2, width: "100%",
                            }}
                          />
                        );
                      }}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                </ColumnStack>
              </RowStack>
              <RowStack>
                <ColumnStack>
                  <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                    Сроки
                  </Typography>
                  <Stack direction="row" mb={2} spacing={1} sx={{width:'100%'}}>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="actual_start_date"
                        render={({
                                   field: { onChange, value },
                                   fieldState: { error },
                                 }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Старт проекта"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "50%",
                                mb: 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="start_date"
                        render={({
                                   field: { onChange, value },
                                   fieldState: { error },
                                 }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Старт План"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "50%",
                                mb: 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                  </Stack>
                  <Stack direction="row" mb={0} spacing={1} sx={{width:'100%'}}>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="actual_end_date"
                        render={({
                                   field: { onChange, value },
                                   fieldState: { error },
                                 }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Дата завершения"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "50%",
                                mb: 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="end_date"
                        render={({
                                   field: { onChange, value },
                                   fieldState: { error },
                                 }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Завершение План"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "50%",
                                mb: isMobile ? 1 : 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                  </Stack>
                </ColumnStack>
                <ColumnStack>
                  <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                    Бюджеты
                  </Typography>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="currency"
                      render={({
                                 field: { onChange, value,...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => onChange(value)}
                            options={currencies2}
                            renderInput={(params) => (
                              <TextField
                                label="Валюта проекта"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2, width:'100%'
                            }}
                            value={value || null}
                          />
                        );
                      }}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                  <Stack direction="row" mb={2} spacing={1} sx={{width:'100%'}}>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Бюджет в валюте"
                        {...register("currency_budget", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencyAdornmentLabel}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.currency_budget}
                        fullWidth
                        helperText={errors?.currency_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Нач. бюджет в валюте"
                        {...register("currency_budget_start", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencyAdornmentLabel}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.currency_budget}
                        fullWidth
                        helperText={errors?.currency_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                  </Stack>
                  <Stack direction="row" mb={2} spacing={1} sx={{width:'100%'}}>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Бюджет в ком. часах"
                        {...register("commercial_hours_budget", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.commercial_hours_budget}
                        fullWidth
                        helperText={errors?.commercial_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Нач. бюджет в ком. часах"
                        {...register("commercial_hours_budget_start", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.commercial_hours_budget}
                        fullWidth
                        helperText={errors?.commercial_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                  </Stack>
                  <Stack direction="row" mb={0} spacing={1} sx={{width:'100%'}}>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Бюджет во вн. часах"
                        {...register("internal_hours_budget", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.internal_hours_budget}
                        fullWidth
                        helperText={errors?.internal_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Нач. бюджет во вн. часах"
                        {...register("internal_hours_budget_start", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.internal_hours_budget}
                        fullWidth
                        helperText={errors?.internal_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                  </Stack>



                </ColumnStack>
              </RowStack>
              <RowStack>
                <ColumnStack full={true}>
                  <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                    Команда проекта
                  </Typography>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="manager"
                      render={({
                                 field: { onChange, value, ...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option.display_name}
                            isOptionEqualToValue={(option, value) =>
                              option?.source_id === value?.source_id
                            }
                            onChange={(e, value) => onChange(value)}
                            options={employees}
                            renderInput={(params) => (
                              <TextField
                                label="Менеджер проекта *"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2, width:"100%"
                            }}
                            value={value || null}
                          />
                        );
                      }}
                      rules={{
                        required: "Обязательное поле",
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="team"
                      render={({
                                 field: { onChange, value, ...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option.display_name}
                            isOptionEqualToValue={(option, value) =>
                              option?.source_id === value?.source_id
                            }
                            multiple
                            onChange={(e, value) => onChange(value)}
                            options={employees}
                            renderInput={(params) => (
                              <TextField
                                label="Команда *"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2, width:'100%'
                            }}
                            value={value || []}
                          />
                        );
                      }}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                </ColumnStack>
              </RowStack>
              {isMobile ? (
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{ width: "100%", mt: 3 }}
                >
                  <Button size="small" sx={{ width: "100%" }} type="submit" variant="contained">
                    Сохранить
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isDirty}
                    onClick={()=>reset()}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                </Stack>
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                >

                  <Button
                    color="primary"
                    onClick={()=>reset()}
                    size="small"
                    sx={{ ml: 1 }}
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                  <Button size="small" type="submit" variant="contained">
                    Сохранить
                  </Button>
                </Stack>
              )}
            </Box>
          </Card>

        </form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Box>
  );
};

export default CommonSettings;
