import React, { useCallback, useEffect } from "react";

import {Icon} from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar, Box,
  Button,
  Card, Divider,
  Grid,
  IconButton, Tooltip,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";

import CustomViewField from "../components/CustomViewField";
import InfoAboutComponent from "../components/InfoAboutComponent";
import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";
import {ColumnStack, RowStack} from "../theme/standarts_styles";

const EmployeePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeId } = useParams();
  const isMobile = useResponsive("down", "sm");

  const { selectedEmployee } = useSelector((state) => state.employeesReducer);

  const { roles } = useSelector((state) => state.authReducer);

  const {
    setEmployees,
    setSelectedEmployee,
    setDataSelectedEmployee,
    setSelectedDepartment,
  } = useActions();

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get(`/users/${employeeId}/`);
        return response.data;
      };
      newChannelList().then((data) => {
        setDataSelectedEmployee(data);
      });
    } catch (e) {
      console.log(e);
    }
  }, [employeeId, setDataSelectedEmployee]);

  const handleBack = () => {
    navigate("/org-structure/employees");
  };

  const handleEdit = () => {
    navigate(`/org-structure/employees/edit/${selectedEmployee.source_id}`);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };

  return (
    <Box pb={2}>
      <TitleAndButtonComponent
        back={handleBack}
        backTitle="Вернуться к списку"
        title={`Управление сотрудником`}
        // title={`Сотрудник ${selectedEmployee?.display_name}`}
      />
      <Card
        sx={{backgroundColor:'none', border:'none'}}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          py={1}
          spacing={isMobile? 2 : 3}
          sx={{
            justifyContent: "flex-start",
            alignItems: { xs: "center", sm: "stretch" },
          }}
        >
          <Avatar
            alt="photoURL"
            src={selectedEmployee?.photo}
            sx={(theme) => ({
              width: 130, height: 130,
              border:`2px solid ${theme.palette.secondary.light}`
            })}
          />
          <Stack
            direction={{ xs: "column" }}
            spacing={isMobile?1:2}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Box>
              <Typography
                align={isMobile ? "center" : "flex-start"}
                color="text.primary"
                component="p"
                variant="h5Wide"
              >
                {selectedEmployee?.first_name} {selectedEmployee?.last_name}
              </Typography>
              <Typography align={isMobile ? "center" : "flex-start"} color="text.secondary" component="p" variant="body3">
                {selectedEmployee?.occupation} / {selectedEmployee?.department ? selectedEmployee?.department?.name : `Отдел не указан`}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              {selectedEmployee?.email && (
                <>
                  { isMobile ?
                    <IconButton
                      color="secondary"
                      onClick={() => copy(selectedEmployee?.email)}
                      size="small"
                      sx={(theme)=>({
                        borderRadius:'90px',
                        border:`1px solid ${theme.palette.secondary.main}`,
                      })}
                    >
                      <Icon icon="material-symbols-light:stacked-email-outline-rounded" />
                    </IconButton>
                    :
                    <Tooltip title="Копировать почту">
                      <Chip
                        color="secondary"
                        icon={<Icon icon="material-symbols-light:stacked-email-outline-rounded" />}
                        label={selectedEmployee?.email}
                        onClick={() => copy(selectedEmployee?.email)}
                        sx={{fontSize:12}}
                        variant="outlined"
                      />
                    </Tooltip>
                  }
                </>
              )}
              {selectedEmployee?.phone && (
                <>
                  {
                    isMobile ?
                      <IconButton
                        color="secondary"
                        onClick={() => copy(selectedEmployee?.phone)}
                        size="small"
                        sx={(theme)=>({
                          borderRadius:'90px',
                          border:`1px solid ${theme.palette.secondary.main}`,
                        })}
                      >
                        <Icon icon="material-symbols-light:phone-enabled-outline" />
                      </IconButton>
                      :
                      <Tooltip title="Копировать телефон">
                        <Chip
                          color="secondary"
                          icon={<Icon icon="material-symbols-light:phone-enabled-outline" />}
                          label={selectedEmployee?.phone}
                          onClick={() => copy(selectedEmployee?.phone)}
                          sx={{fontSize: 12}}
                          variant="outlined"
                        />
                      </Tooltip>
                  }
                </>
              )}
            </Stack>

          </Stack>
        </Stack>
      </Card>
      <Card
        sx={(theme) => ({
          width:{ xs: "100%", md:"100%" },
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        })}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            p: 2,
          }}
        >
          <Typography variant="subtitle2">Орг. структура</Typography>
          { roles?.some((role) => role === "ERP_Admins") && (
              <>
                {isMobile ? (
                  <IconButton color="primary" sx={{ p: 0 }}>
                    <Icon
                      height="20"
                      icon="material-symbols-light:edit-outline-rounded"
                      onClick={handleEdit}
                      width="20"
                    />
                  </IconButton>
                ) : (
                  <Button
                    color="primary"
                    onClick={handleEdit}
                    size="small"
                    startIcon={
                      <Icon
                        height="20"
                        icon="material-symbols-light:edit-outline-rounded"
                        width="20"
                      />
                    }
                    variant="text"
                  >
                    Редактировать
                  </Button>
                )}
              </>
          )}
        </Stack>
        <Divider />
        <Box p={2}>
          <RowStack>
            <ColumnStack customSpace={0.5}>
              <InfoAboutComponent
                label="Отдел"
                text={selectedEmployee?.department?.name}
              />

              <InfoAboutComponent
                label="Должность"
                text={selectedEmployee?.occupation}
              />
            </ColumnStack>
            <ColumnStack>
              <InfoAboutComponent
                label="Менеджер"
                text={selectedEmployee?.manager?.display_name}
              />
            </ColumnStack>
          </RowStack>
        </Box>
      </Card>
      <Card
        sx={(theme) => ({
          width:{ xs: "100%", md:"100%" },
        })}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            p: 2,
          }}
        >
          <Typography variant="subtitle2">Подробная информация</Typography>
        </Stack>
        <Divider />
        <Box p={2}>
          <RowStack>
            <ColumnStack customSpace={0.5}>
              <Typography
                align="left"
                component="h2"
                pb={1}
                variant="miniHeader"
              >
                Контакты
              </Typography>
              <InfoAboutComponent
                copyButton={true}
                label="Телефон"
                text={selectedEmployee?.phone}
              />
              <InfoAboutComponent
                copyButton={true}
                label="Email"
                text={selectedEmployee?.email}
              />
              <InfoAboutComponent
                label="Доп. контакт"
                text={selectedEmployee?.communication_channels}
              />
              <Typography
                align="left"
                component="h2"
                pb={1}
                pt={4}
                variant="miniHeader"
              >
                Локация
              </Typography>
              <InfoAboutComponent
                label="Страна"
                text={selectedEmployee?.country}
              />
              <InfoAboutComponent
                label="Рабочий график"
                text={selectedEmployee?.work_schedule}
              />
              <InfoAboutComponent
                label="Почтовый адрес"
                text={selectedEmployee?.street_address}
              />
            </ColumnStack>
            <ColumnStack customSpace={0.5}>
              <Typography
                align="left"
                component="h2"
                pb={1}
                variant="miniHeader"
              >
                Общие данные
              </Typography>
              <InfoAboutComponent
                label="Дата рождения"
                text={dayjs(selectedEmployee?.birth_date).format("DD.MM.YYYY")}
              />
              <InfoAboutComponent
                label="Обо мне"
                text={selectedEmployee?.about_me}
              />
            </ColumnStack>
          </RowStack>
        </Box>
      </Card>
    </Box>
  );
};

export default EmployeePage;
