import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomDatepickerNew from "../../components/TextFields/CustomDatepickerNew";
import CustomFileInput from "../../components/TextFields/CustomFileInput";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isBetween);

const MyCompensationAdd = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [receiptFiles, setReceiptFiles] = useState([]);
  const [receiptFilesError, setReceiptFilesError] = useState(false);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [photoFilesError, setPhotoFilesError] = useState(false);
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      type: null,
      currency: null,
      amount: "",
      expenses_period: null,
      payment_period: null,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    if (receiptFiles.length > 0) {
      setReceiptFilesError(false);
    }
  }, [receiptFiles]);

  useEffect(() => {
    if (photoFiles.length > 0) {
      setPhotoFilesError(false);
    }
  }, [photoFiles]);

  const onSubmit = async (
    { expenses_period, payment_period, request_text, amount, ...data },
    draft,
  ) => {
    try {
      const newData = {
        ...data,
        expenses_period: expenses_period
          ? dayjs(expenses_period).format("YYYY-MM")
          : null,
        payment_period: payment_period
          ? dayjs(payment_period).format("YYYY-MM")
          : null,
        ...(receiptFiles.length > 0 && {
          checks: receiptFiles.map((file) => file.id),
        }),
        ...(photoFiles.length > 0 && {
          photos: photoFiles.map((file) => file.id),
        }),
        ...(amount && { amount }),
        ...(request_text && { request_text }),
      };

      if (receiptFiles.length > 0) {
        setReceiptFilesError(false);
        clearErrors("receiptFiles");
      }

      if (photoFiles.length > 0) {
        setPhotoFilesError(false);
        clearErrors("photoFiles");
      }

      if (!draft) {
        if (receiptFiles.length === 0) {
          setReceiptFilesError(true);
          setError("root.random", {
            type: "receiptFiles",
          });
        }

        if (photoFiles.length === 0 && data.type === "Спорт") {
          setPhotoFilesError(true);
          setError("root.random", {
            type: "photoFiles",
          });
        }

        !amount &&
          setError("amount", {
            type: "custom",
            message: "Обязательное поле",
          });

        !payment_period &&
          setError("payment_period", {
            type: "custom",
            message: "Обязательное поле",
          });

        !request_text &&
          setError("request_text", {
            type: "custom",
            message: "Обязательное поле",
          });

        if (
          data.type === "Спорт" &&
          receiptFiles.length > 0 &&
          photoFiles.length > 0 &&
          request_text &&
          payment_period &&
          request_text
        ) {
          setLoading(true);

          await $authHost.post(
            `/compensation/me/add/`,
            convertToNull({ ...newData, status: "Новое" }),
          );
        }

        if (
          data.type !== "Спорт" &&
          receiptFiles.length > 0 &&
          request_text &&
          payment_period &&
          request_text
        ) {
          setLoading(true);

          await $authHost.post(
            `/compensation/me/add/`,
            convertToNull({ ...newData, status: "Новое" }),
          );
        }
      }

      if (draft) {
        setLoading(true);

        await $authHost.post(
          `/compensation/me/add/`,
          convertToNull({ ...newData, status: "Черновик" }),
        );
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/my-compensations");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/payments/my-compensations`);
  };

  const paymentPeriod = useWatch({ control, name: "payment_period" });
  const expensesPeriod = useWatch({ control, name: "expenses_period" });

  useEffect(() => {
    if (paymentPeriod) {
      const paymentPeriodIsBetween = dayjs(paymentPeriod).isBetween(
        expensesPeriod,
        dayjs(expensesPeriod).add(2, "month"),
        "month",
        "[]",
      );

      if (!paymentPeriodIsBetween) {
        setError("payment_period", {
          type: "custom",
          message:
            "Период выплаты не может быть раньше периода расходов или превышать его более чем на 2 месяца. Пожалуйста, выберите корректный период",
        });
      } else {
        clearErrors("payment_period");
      }
    } else {
      clearErrors("payment_period");
    }
  }, [clearErrors, expensesPeriod, paymentPeriod, setError]);

  return (
    <Card sx={{ mb: 2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 2,
        }}
      >
        <Typography variant="subtitle2">Новая заявка</Typography>
        {isMobile ? (
          <IconButton color="primary" onClick={handleBack} sx={{ p: 1 }}>
            <CloseIcon />
          </IconButton>
        ) : (
          <Button
            color="primary"
            onClick={handleBack}
            size="small"
            startIcon={<CloseIcon />}
            variant="text"
          >
            Отмена
          </Button>
        )}
      </Stack>
      <Divider />
      <Box sx={{ p: 2 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Общее
                </Typography>
                <CustomAutocompleteNew
                  disableClearable
                  helperText='Поле "Вид компенсации" будет недоступно для изменений после сохранения заявки'
                  label="Вид компенсации"
                  name="type"
                  options={[
                    "Спорт",
                    "Подарок на ДР",
                    "Командировочные расходы",
                    "Другое",
                  ]}
                  required
                />
                <CustomAutocompleteNew
                  disableClearable
                  helperText={
                    'Поле "Валюта" будет недоступно для изменений после сохранения заявки'
                  }
                  label="Валюта"
                  name="currency"
                  options={["RUB", "KZT"]}
                  required
                />
                <CustomTextFieldNew label="Сумма в валюте" name="amount" />
                <CustomDatepickerNew
                  format="MMMM YYYY"
                  helperText='Поле "Период расходов" будет недоступно для изменений после сохранения заявки'
                  label="Период расходов"
                  name="expenses_period"
                  required
                  sx={{ mb: 6 }}
                  views={["year", "month"]}
                />
                <CustomDatepickerNew
                  format="MMMM YYYY"
                  label="Период выплаты"
                  name="payment_period"
                  sx={{ mb: 2 }}
                  views={["year", "month"]}
                />
                <CustomTextFieldNew
                  label="Текст запроса"
                  multiline
                  name="request_text"
                  rows={3}
                />
              </ColumnStack>
              <ColumnStack>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Документы
                </Typography>
                <CustomFileInput
                  error={receiptFilesError}
                  files={receiptFiles}
                  helperText='Переименуйте файл перед загрузкой "Фамилия_ВидКомпенсации_ММ-ГГГГ_чек_номер" например "Иванов_Спорт_10-2024_чек_1"'
                  label="Чек"
                  setFiles={setReceiptFiles}
                  setLoading={setLoading}
                />
                <CustomFileInput
                  error={photoFilesError}
                  files={photoFiles}
                  helperText='Переименуйте файл перед загрузкой "Фамилия_ВидКомпенсации_ММ-ГГГГ_фото_номер" например "Иванов_Спорт_10-2024_фото_1"'
                  label="Фото-отчет"
                  setFiles={setPhotoFiles}
                  setLoading={setLoading}
                />
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack full={true}>
                {isMobile ? (
                  <Stack
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    spacing={3}
                    sx={{ width: "100%", mt: 3 }}
                  >
                    <Box>
                      <Button
                        onClick={handleSubmit((data) => onSubmit(data, true))}
                        size="small"
                        sx={{ width: "100%" }}
                        variant="contained"
                      >
                        Сохранить черновик
                      </Button>
                      <Button
                        size="small"
                        sx={{ width: "100%", marginTop: "16px" }}
                        variant="outlined"
                        оnClick={handleSubmit((data) => onSubmit(data, false))}
                      >
                        Сохранить и отправить на согласование
                      </Button>
                    </Box>
                    <Button
                      onClick={handleReset}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="text"
                    >
                      Сбросить
                    </Button>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Button
                      color="primary"
                      onClick={handleReset}
                      size="small"
                      variant="outlined"
                    >
                      Сбросить
                    </Button>
                    <Stack direction="row" spacing={1}>
                      <Button
                        onClick={handleSubmit((data) => onSubmit(data, false))}
                        size="small"
                        variant="outlined"
                      >
                        Сохранить и отправить на согласование
                      </Button>
                      <Tooltip title="Черновики заявок доступны для просмотра ТОПам, но без возможности их согласования">
                        <Button
                          onClick={handleSubmit((data) => onSubmit(data, true))}
                          size="small"
                          variant="contained"
                        >
                          Сохранить черновик
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                )}
              </ColumnStack>
            </RowStack>
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Box>
    </Card>
  );
};

export default MyCompensationAdd;
