import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {
  ColumnStack,
  RowOptionsStack,
  RowStack,
} from "../../theme/standarts_styles";
import AlertMessage from "../Alert/AlertMessage";
import BlockFormModal from "../BlockFormModal";
import AddEditFormButtonGroup from "../forms/AddEditFormButtonGroup";
import BaseAddEditForm from "../forms/BaseAddEditForm";
import BudgetsForm from "../forms/BudgetsForm";
import InfoAboutComponent from "../InfoAboutComponent";
import MaterialTablePagination from "../MaterialTablePagination";
import LoadingSpinner from "../modals/loadingSpinner";
import TableActions from "../TableActions";
import { TableCellsWrap } from "../TableCellsWrap";

const Budgets = () => {
  const tableId = "budgets";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { setBudgets, setSelectedBudget } = useActions();

  const [selectedRow, setSelectedRow] = useState(null);
  const [openForm, setOpenForm] = useState("");
  const [confirmation, setConfirmation] = useState(false);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const typesList = ["Внутренний", "Коммерческий", "Риски", "НЗ"];

  const { budgets, selectedBudget } = useSelector(
    (state) => state.budgetsReducer,
  );
  const isMobile = useResponsive("down", "sm");

  const { project } = useSelector((state) => state.projectsReducer);

  // const [budgetId, setBudgetId] = useState(null);
  // const [openAddForm, setOpenAddForm] = useState(false);
  // const [openEditForm, setOpenEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const tableColumns = [
    {
      id: "date",
      header: "Дата",
      accessorFn: (row) => dayjs(row.date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "name",
      header: "Имя бюджета",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "type",
      header: "Вид",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "justification",
      header: "Обоснование",
      accessorFn: (row) => row.justification || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "currency",
      header: "Валюта",
      accessorFn: (row) => row.currency || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "currency_amount",
      header: "Сумма бюджета в валюте",
      accessorFn: (row) => row.currency_amount || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "hours_amount",
      header: "Сумма бюджета в часах",
      accessorFn: (row) => row.hours_amount || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={handleEditRow}
          row={row}
          setConfirmationId={setConfirmationId}
        />
      ),
    },
  ];

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/budgets/all/?project_id=${project.id}`,
      );
      setBudgets(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [project.id, setBudgets]);

  const handleAdd = () => {
    // setOpenEditForm(false);
    // setOpenAddForm(true);
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
    setSelectedRow(null);
  };

  function handleEditRow(row) {
    // setBudgetId(id);
    // setOpenAddForm(false);
    // setOpenEditForm(true);

    setSelectedRow(row);
    setSelectedBudget(row);
    setOpenForm("Update");
  }

  async function handleDeleteRow(row) {
    setLoading(true);
    try {
      await $authHost.delete(`/budgets/${row?.id}/delete/`).then((response) => {
        loadData();
        setConfirmationId(null);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setSelectedRow(null);
    setSelectedBudget(null);
    setOpenForm("");
  }

  const handleOpenViewPage = (row) => {
    setSelectedRow(row.original);
    setSelectedBudget(row.original);

    openForm !== "View" && setOpenForm("View");
  };

  const AddAction = async (data) => {
    setLoading(true);

    const { currency_amount, date, hours_amount, justification, name, type } =
      data;

    const newData = {
      currency_amount,
      hours_amount,
      justification,
      name,
      type,
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
      project_id: project?.id,
    };

    try {
      const response = await $authHost.post("/budgets/add/", newData);
      setLoading(false);
      setOpenForm("");
      loadData();
    } catch (e) {
      setLoading(false);
    }
  };

  const UpdateAction = async (data) => {
    setLoading(true);

    const { currency_amount, date, hours_amount, justification, name, type } =
      data;

    const newData = {
      currency_amount,
      hours_amount,
      justification,
      type,
      // name,
      // date: dayjs(date).format('YYYY-MM-DDTHH:mm:ss'),
    };

    try {
      const response = await $authHost.put(
        `/budgets/${selectedBudget?.id}/update/`,
        newData,
      );
      setLoading(false);
      setOpenForm("");
      loadData();
    } catch (e) {
      setLoading(false);
    }
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!openForm && selectedBudget) {
      setSelectedBudget(null);
      // resetSandboxFieldForm()
    }
  }, [openForm, selectedBudget, setSelectedBudget]);

  console.log(openForm);

  return (
    <>
      {loading && <LoadingSpinner />}
      <>
        {openForm && openForm !== "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ mb: 2 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography variant="subtitle2">
                    {openForm === "Add" ? "Добавить" : "Редактировать"}
                  </Typography>
                  {isMobile ? (
                    <IconButton
                      color="primary"
                      onClick={handleClose}
                      sx={{ p: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={handleClose}
                      sx={{ px: 1, py: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
                <Divider />
                <Box p={2}>
                  <BaseAddEditForm
                    AddAction={AddAction}
                    UpdateAction={UpdateAction}
                    action={openForm}
                    handleClose={() => setOpenForm("")}
                    resetValue={{
                      date: "",
                      name: "",
                      type: "",
                      justification: "",
                      currency_amount: "",
                      hours_amount: "",
                    }}
                    selectedRow={selectedRow}
                    setConfirmation={setConfirmation}
                    setLoading={setLoading}
                  >
                    <BudgetsForm />
                    <AddEditFormButtonGroup
                      action={openForm}
                      confirmation={confirmation}
                      handleClose={handleClose}
                    />
                  </BaseAddEditForm>
                </Box>
                <AlertMessage />
              </Card>
            </Grid>
          </Grid>
        )}
        {openForm === "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ mb: 2 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography variant="subtitle2">Бюджет</Typography>
                  {isMobile ? (
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() => setOpenForm("Update")}
                        sx={{ p: 1 }}
                      >
                        <Icon
                          height="20"
                          icon="material-symbols-light:edit-outline-rounded"
                          width="20"
                        />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={handleClose}
                        sx={{ p: 1 }}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box>
                      <Button
                        color="primary"
                        onClick={() => setOpenForm("Update")}
                        size="small"
                        startIcon={
                          <Icon
                            height="20"
                            icon="material-symbols-light:edit-outline-rounded"
                            width="20"
                          />
                        }
                        variant="text"
                      >
                        Редактировать
                      </Button>
                      <IconButton
                        color="secondary"
                        onClick={handleClose}
                        sx={{ px: 1, py: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Stack>
                <Divider />
                <Box p={2}>
                  <RowStack>
                    <ColumnStack>
                      <Typography
                        align="left"
                        component="h2"
                        pb={1}
                        variant="miniHeader"
                      >
                        Общее
                      </Typography>
                      <InfoAboutComponent
                        label="Дата"
                        loading={loading}
                        text={dayjs(selectedRow?.date).format("DD.MM.YYYY")}
                      />
                      <InfoAboutComponent
                        label="Имя бюджета"
                        loading={loading}
                        text={selectedRow?.name}
                      />
                      <InfoAboutComponent
                        label="Вид"
                        loading={loading}
                        text={selectedRow?.type}
                      />
                      <InfoAboutComponent
                        label="Обоснование"
                        loading={loading}
                        text={selectedRow?.justification}
                      />
                    </ColumnStack>
                    <ColumnStack>
                      <Typography
                        align="left"
                        component="h2"
                        pb={1}
                        variant="miniHeader"
                      >
                        Суммы
                      </Typography>
                      <InfoAboutComponent
                        label="Валюта"
                        loading={loading}
                        text={selectedRow?.currency}
                      />
                      <InfoAboutComponent
                        label="Сумма в валюте"
                        loading={loading}
                        text={selectedRow?.currency_amount}
                      />
                      <InfoAboutComponent
                        label="Сумма в коммерческих часах"
                        loading={loading}
                        text={selectedRow?.hours_amount}
                      />
                    </ColumnStack>
                  </RowStack>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
        <Card>
          <MaterialTablePagination
            columnFilters={columnFilters}
            columns={tableColumns}
            customButtonAction={handleAdd}
            customButtonDisabled={selectedBudget}
            customButtonTitle="Добавить бюджет"
            data={budgets}
            id={tableId}
            loading={loading}
            muiTableBodyCellProps={({ row }) => ({
              onClick: () => {
                handleOpenViewPage(row);
              },
              sx: {
                cursor: "pointer",
              },
            })}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={onGlobalFilterChange}
            onSortingChange={setSorting}
            pageCount={pageCount}
            pagination={pagination}
            rowCount={rowCount}
            search={searchText}
            setPagination={setPagination}
            sorting={sorting}
          />
        </Card>
        <BlockFormModal
          title="Вы не завершили действие внутри таблицы"
          when={!!confirmationId}
        />
      </>
      {/*<Stack*/}
      {/*  alignItems="center"*/}
      {/*  direction="row"*/}
      {/*  justifyContent="start"*/}
      {/*  mb={0}*/}
      {/*  sx={{ height: "70px" }}*/}
      {/*>*/}
      {/*  <Button*/}
      {/*    aria-haspopup="true"*/}
      {/*    onClick={handleAdd}*/}
      {/*    size={isMobile ? "small" : "medium"}*/}
      {/*    startIcon={<Iconify icon="eva:plus-fill" />}*/}
      {/*    sx={{ mr: 0 }}*/}
      {/*    variant="contained"*/}
      {/*  >*/}
      {/*    Добавить бюджет*/}
      {/*  </Button>*/}
      {/*</Stack>*/}
      {/*<AlertMessage/>*/}
      {/*{openAddForm && (*/}
      {/*  <BudgetsAdd handleClose={handleClose} loadDataBudgets={loadData} />*/}
      {/*)}*/}
      {/*{openEditForm && (*/}
      {/*  <BudgetsEdit*/}
      {/*    budgetId={budgetId}*/}
      {/*    handleClose={handleClose}*/}
      {/*    loadDataBudgets={loadData}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<Card>*/}
      {/*  <MaterialTable*/}
      {/*    columns={tableColumns}*/}
      {/*    data={budgets}*/}
      {/*    loading={loading}*/}
      {/*  />*/}
      {/*</Card>*/}
      {/*<BlockFormModal*/}
      {/*  title="Вы не завершили действие внутри таблицы"*/}
      {/*  when={!!confirmationId}*/}
      {/*/>*/}
    </>
  );
};

export default Budgets;
