import React, {useCallback, useEffect, useRef, useState} from "react";

import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import BlockFormModal from "../components/BlockFormModal";
import Label from "../components/label/Label";
import MaterialTablePagination from "../components/MaterialTablePagination";
import TableActions from "../components/TableActions";
import { TableCellsWrap } from "../components/TableCellsWrap";
import PersonInfo from "../components/UserCard";
import { useActions } from "../hook/useActions";
import { $authHost } from "../http";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";
import { RowOptionsStack } from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";

const Projects = () => {
  const tableId = "projects";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setProjects, setSelectedProject } = useActions();
  const { projects } = useSelector((state) => state.projectsReducer);
  const { roles } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);
  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    // {
    //   accessorKey: "actions",
    //   size: 0,
    //   enableColumnFilter: false,
    //   enableSorting: false,
    //   enableHiding: false,
    //   muiTableBodyCellProps: {
    //     onClick: (e) => {},
    //     sx: {
    //       cursor: "auto",
    //     },
    //   },
    //   Cell: ({ row }) => (
    //     <TableActions
    //       confirmationId={confirmationId}
    //       deleteFn={handleDeleteRow}
    //       editFn={handleEditRow}
    //       row={row}
    //       setConfirmationId={setConfirmationId}
    //       showDelete={roles?.some((role) => role === "ERP_Admins")}
    //     />
    //   ),
    // },
    {
      id: "code",
      header: "Код проекта",
      accessorFn: (row) => row.code || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "name",
      header: "Название проекта",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "client.name",
      header: "Клиент",
      accessorFn: (row) => row.client?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "client.code",
      header: "Код клиента",
      accessorFn: (row) => row.client?.code || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "notes",
      header: "Описание проекта",
      accessorFn: (row) => row.notes || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "status",
      header: "Статус",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue }) => {
        return (
          <TableCellsWrap>
            <Label color="default" variant="soft">
              {renderedCellValue}
            </Label>
          </TableCellsWrap>
        );
      },
    },
    {
      id: "manager.display_name",
      header: "Менеджер проекта",
      accessorFn: (row) => row.manager?.display_name || "",
      Cell: ({ row }) => {
        if (row.original.manager !== null) {
          return (
            <TableCellsWrap>
              <PersonInfo
                firstName={row.original.manager?.first_name}
                lastName={row.original.manager?.last_name}
                photo={row.original.manager?.photo}
              />
            </TableCellsWrap>
          );
        } else {
          return <TableCellsWrap></TableCellsWrap>;
        }
      },
    },
    {
      id: "team.display_name",
      header: "Команда проекта",
      Cell: ({ row }) => {
        if (Array.isArray(row.original?.team)) {
          return (
            <TableCellsWrap>
              <Stack alignItems="flex-start" direction="column" spacing={1}>
                {row.original.team.map((user) => (
                  <Chip
                    avatar={<Avatar src={user?.photo} />}
                    color="secondary"
                    key={user.source_id}
                    label={
                      user?.display_name ||
                      `${user?.first_name} ${user?.last_name}`
                    }
                    sx={{
                      border: "none",
                      color: "text.primary",
                    }}
                    variant="outlined"
                  />
                ))}
              </Stack>
            </TableCellsWrap>
          );
        }
      },
    },
    {
      id: "start_date",
      header: "Начало (план)",
      accessorFn: (row) =>
        row.start_date ? dayjs(row.start_date).format("DD.MM.YYYY") : "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "end_date",
      header: "Конец (план)",
      accessorFn: (row) =>
        row.end_date ? dayjs(row.end_date).format("DD.MM.YYYY") : "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "internal_hours_budget",
      header: "Бюджет (внутр. часы)",
      accessorFn: (row) => row.internal_hours_budget || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ row }) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={handleEditRow}
          row={row}
          setConfirmationId={setConfirmationId}
          showDelete={roles?.some((role) => role === "ERP_Admins")}
        />
      ),
    },
  ];

  const handleEditRow = (row) => {
    navigate(`/projects/edit/${row?.id}`);
    setSelectedProject(row?.id);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/projects/${id}`);
      setSelectedProject(id);
    }
  };

  function handleGoToAddPage(selectedChannel = null) {
    navigate("/projects/new");
  }

  async function handleDeleteRow(row) {
    setLoading(true);
    try {
      await $authHost
        .delete(`/projects/${row?.id}/delete/`)
        .then((response) => {
          loadData();
        });
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/projects/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      const data = response.data.items;
      setProjects(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setProjects,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  console.log(document.getElementById('titleElem')?.clientHeight)

  return (
    <Box pb={2}>
      <TitleAndButtonComponent
        action={handleGoToAddPage}
        back={() => {
          navigate("/");
        }}
        backTitle="Главная"
        name="Новый проект"
        title="Проекты"
        type={true}
      />
      <Card sx={{
        // height: `calc(100% - 70px)`,
      }}>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={projects}
          enableHiding={true}
          id={tableId}
          initialState={{
            columnVisibility: { client_code: false, notes: false, team: false },
            // columnPinning:{
            //   right:['actions']
            // }
          }}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </Box>
  );
};

export default Projects;
