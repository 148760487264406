import React, { useCallback, useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Divider, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";

const MyCompensationView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const ref1 = useRef(null);
  const { myCompensationId } = useParams();
  const { myCompensation } = useSelector((state) => state.paymentsReducer);
  const { setMyCompensation } = useActions();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (cancelConfirm) {
        await $authHost.post(`/compensation/${myCompensationId}/cancel/`, {
          comment: data.comment,
        });
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/my-compensations");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleClose = () => {
    navigate(`/payments/my-compensations`);
  };

  const handleEdit = () => {
    navigate(`/payments/my-compensations/${myCompensationId}/edit`);
    // setOpenForm("Edit");
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/history/all/?compensation_id=${myCompensationId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [myCompensationId]);

  const getMyCompensation = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/${myCompensationId}/`,
      );
      const data = response.data;
      setMyCompensation(data);
    } catch (e) {
      console.log(e);
    }
  }, [setMyCompensation, myCompensationId]);

  useEffect(() => {
    Promise.all([getMyCompensation(), getHistory()]).then(() =>
      setLoading(false),
    );
  }, [getHistory, getMyCompensation]);

  return (
    <Card sx={{ mb: 2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 2,
        }}
      >
        <Typography variant="subtitle2">Заявка</Typography>
        {isMobile ? (
          <Box>
            {myCompensation?.status === "Черновик" && (
              <IconButton
                color="primary"
                onClick={() => handleEdit()}
                sx={{ p: 1 }}
              >
                <Icon
                  height="20"
                  icon="material-symbols-light:edit-outline-rounded"
                  width="20"
                />
              </IconButton>
            )}
            <IconButton
              color="secondary"
              onClick={() => handleClose()}
              sx={{ p: 1 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            {myCompensation?.status === "Черновик" && (
              <Button
                color="primary"
                onClick={() => handleEdit()}
                size="small"
                startIcon={
                  <Icon
                    height="20"
                    icon="material-symbols-light:edit-outline-rounded"
                    width="20"
                  />
                }
                variant="text"
              >
                Редактировать
              </Button>
            )}
            <IconButton
              color="secondary"
              onClick={() => handleClose()}
              sx={{ px: 1, py: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
      <Divider />
      <Box sx={{ p: 2 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack customSpace={0.5} ref={ref1}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Вид компенсации"
                  text={myCompensation?.type}
                />
                <InfoAboutComponent
                  label="Сотрудник"
                  text={myCompensation?.user?.display_name}
                />
                <InfoAboutComponent
                  label="Отдел"
                  text={myCompensation?.department?.name}
                />
                <InfoAboutComponent
                  label="Валюта"
                  text={myCompensation?.currency}
                />
                <InfoAboutComponent
                  label="Сумма в валюте"
                  text={myCompensation?.amount}
                />
                <InfoAboutComponent
                  label="Период расходов"
                  text={
                    myCompensation?.expenses_period
                      ? dayjs(myCompensation?.expenses_period).format(
                          "MMMM YYYY",
                        )
                      : ""
                  }
                />
                <InfoAboutComponent
                  label="Период выплаты"
                  text={
                    myCompensation?.payment_period
                      ? dayjs(myCompensation?.payment_period).format(
                          "MMMM YYYY",
                        )
                      : ""
                  }
                />
                <InfoAboutComponent
                  label="Текст запроса"
                  text={myCompensation?.request_text}
                />
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  pt={2}
                  variant="miniHeader"
                >
                  Документы
                </Typography>
                <InfoAboutComponent
                  files={myCompensation?.checks}
                  label="Чек"
                  type="file"
                />
                <InfoAboutComponent
                  files={myCompensation?.photos}
                  label="Фото-отчет"
                  type="file"
                />
              </ColumnStack>
              <ColumnStack
                customSpace={0.1}
                sx={{
                  height: `${ref1?.current?.clientHeight}px`,
                  overflowY: "auto",
                }}
              >
                <Typography
                  align="left"
                  component="h2"
                  pt={1}
                  variant="miniHeader"
                >
                  История
                </Typography>
                <History history={history} />
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack>
                {(myCompensation?.status === "Новое" ||
                  myCompensation?.status === "Черновик") && (
                  <Stack
                    direction="column"
                    spacing={0}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      align="left"
                      component="h2"
                      pb={2}
                      pt={2}
                      variant="miniHeader"
                    >
                      Действия
                    </Typography>
                    <CustomTextFieldNew
                      label="Комментарий"
                      multiline
                      name="comment"
                      required
                      rows={2}
                    />
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ width: "100%" }}
                    >
                      {cancelConfirm ? (
                        <>
                          <Typography color="text.primary" variant="body2">
                            Вы уверены, что хотите отозвать заявление?
                          </Typography>

                          <Button
                            color="primary"
                            size="small"
                            type="submit"
                            variant="text"
                          >
                            Да
                          </Button>

                          <Button
                            onClick={() => setCancelConfirm(false)}
                            size="small"
                            variant="contained"
                          >
                            Нет
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => setCancelConfirm(true)}
                            size="small"
                            variant="outlined"
                          >
                            Отозвать
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Stack>
                )}
              </ColumnStack>
            </RowStack>
          </form>
        </FormProvider>
      </Box>
    </Card>
  );
};

export default MyCompensationView;
