import React, {useCallback, useEffect, useRef, useState} from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import AlertMessage from "../../components/Alert/AlertMessage";
import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { types} from "../../constants";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";


const PromotionView = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const [userBudget, setUserBudget] = useState(null);
  const { promotionId } = useParams();
  const navigate = useNavigate();
  const ref = useRef(null);
  const isMobile = useResponsive("down", "sm");

  const { setPromotion, setErrorAlertMessage, resetErrorAlertMessage } =
    useActions();

  const { promotion } = useSelector((state) => state.calendarReducer);

  useEffect(() => {
    resetErrorAlertMessage();
  }, [resetErrorAlertMessage]);

  const methods = useForm({
    mode: "onBlur",
    values: promotion,
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (promotion?.status === "draft") {
        await $authHost.patch(
          `/calendar_rules/promotion/${promotionId}/update/`,
          { comment: data.comment, amount: +data.amount },
        );
      }

      if (promotion?.status === "approved") {
        await $authHost.post(
          `/calendar_rules/promotion/${promotionId}/cancel/`,
        );
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });

      if (e?.response?.data?.detail === "Amount is more than allowed rates") {
        setError("amount", {
          type: "custom",
        });
        setErrorAlertMessage(
          "Ошибка: Количество дней отгула превышает допустимое значение, предусмотренное регламентами.",
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/schedule/promotions");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/schedule/promotions`);
  };

  const getUserBudget = useCallback(async (data) => {
    try {
      const response = await $authHost.get(
        `/calendar_rules/promotion/overwork_budget/`,
        {
          params: {
            user_id: data?.source_id,
          },
        },
      );
      setUserBudget(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${promotionId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [promotionId]);

  const getPromotion = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${promotionId}/`);
      const data = response.data;
      setPromotion(data);
      getUserBudget(data?.users[0]);
    } catch (e) {
      console.log(e);
    }
  }, [promotionId, setPromotion, getUserBudget]);

  useEffect(() => {
    Promise.all([getPromotion(), getHistory()]).then(() => setLoading(false));

    return () => {
      setPromotion({});
    };
  }, [getHistory, getPromotion, setPromotion]);

  return (
    <Card sx={{ mb: 2}}>
      {loading && <LoadingIndicator />}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p:2
        }}
      >
        <Typography variant="subtitle2">
          Поощрение
        </Typography>
        <IconButton color="secondary" onClick={() => handleBack()} sx={{ px: 1, py:isMobile ? 1 : 0 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ p:2 }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack ref={ref}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Тип заявки"
                  text={types[promotion?.type]}
                />
                <InfoAboutComponent
                  label="Дата создания"
                  text={dayjs(promotion?.created_at).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Сотрудник"
                  text={promotion?.users && promotion?.users[0]?.display_name}
                />
                <InfoAboutComponent
                  label="Отдел"
                  text={promotion?.department?.name}
                />
                <InfoAboutComponent
                  label="Причина поощрения"
                  text={promotion?.reason}
                />
                <InfoAboutComponent
                  label="Документ-основание"
                  text={promotion?.supporting_document}
                />
                <InfoAboutComponent label="Проект" text={promotion?.project?.code} />
                <InfoAboutComponent
                  label="Дата начала"
                  text={dayjs(promotion?.start_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Дата окончания"
                  text={dayjs(promotion?.end_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent label="Коэффициент" text={promotion?.rate} />
                <InfoAboutComponent
                  label="Текст запроса"
                  text={promotion?.request_text}
                />
                {promotion?.status !== "draft" && (
                  <>
                    <InfoAboutComponent
                      label="Количество отгулов"
                      text={promotion?.amount}
                    />
                    <InfoAboutComponent
                      label="Комментарий"
                      text={promotion?.comment}
                    />
                  </>
                )}
              </ColumnStack>
              <ColumnStack
                customSpace={0.1}
                sx={{
                  height:`${ref?.current?.clientHeight}px`,
                  overflowY:'auto'
                }}
              >
                <Typography align="left" component="h2" pt={1} variant="miniHeader">
                  История
                </Typography>
                <History history={history}/>
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                  Действия
                </Typography>
                {promotion?.status === "draft" && (
                  <>
                    <CustomTextFieldNew
                      label="Количество отгулов"
                      name="amount"
                      required
                      type="number"
                    />
                    <CustomTextFieldNew
                      label="Комментарий"
                      multiline
                      name="comment"
                      required
                      rows={3}
                    />
                  </>
                )}
                {cancelConfirm && (
                  <Box sx={{width:'100%'}}>
                    <Typography
                      sx={{
                        width:'100%',
                        overflow:'visible',
                        whiteSpace: "normal",
                      }}
                      variant="body2"
                    >
                      {userBudget?.overwork_budget < promotion.amount
                        ? "В выбранном заявлении количество поощрительных отгулов за переработки больше, чем остаток в бюджете переработок Сотрудника. Это значит, что Сотрудник уже израсходовал отгулы, согласованные данным Поощрением. Аннулирование Поощрения приведет к отрицательному балансу бюджета переработок. Все равно аннулировать?"
                        : `На основании заявления сотруднику было начислено ${promotion.amount} отгулов в бюджет переработок. Аннулировать заявление и отменить начисление отгулов?`}
                    </Typography>
                  </Box>
                )}
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-end"
                  sx={{width:'100%'}}
                >
                  {cancelConfirm ? (
                    <>
                      <Button
                        onClick={() => setCancelConfirm(false)}
                        size="small"
                        variant="contained"
                      >
                        Нет
                      </Button>
                      <Button size="small" type="submit" variant="text">
                        Да
                      </Button>
                    </>
                  ) : (
                    <>
                      {promotion?.status === "draft" && (
                        <Button size="small" type="submit" variant="contained">
                          Сохранить
                        </Button>
                      )}
                      {promotion?.type === "promotion" &&
                        promotion?.status === "approved" && (
                          <>
                            <Button
                              onClick={() => setCancelConfirm(true)}
                              size="small"
                              variant="contained"
                            >
                              Аннулировать
                            </Button>
                          </>
                        )}
                    </>
                  )}
                </Stack>

              </ColumnStack>
              <ColumnStack/>
            </RowStack>
          <AlertMessage />
          </form>
        </FormProvider>
      </Box>
    </Card>
  );
};

export default PromotionView;
