import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomDatepickerNew from "../../components/TextFields/CustomDatepickerNew";
import CustomFileInput from "../../components/TextFields/CustomFileInput";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isBetween);

const MyCompensationEdit = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [receiptFiles, setCheckFiles] = useState([]);
  const [receiptFilesError, setCheckFilesError] = useState(false);
  const [checkFiles, setPhotoFiles] = useState([]);
  const [checkFilesError, setPhotoFilesError] = useState(false);
  const navigate = useNavigate();
  const { myCompensationId } = useParams();
  const { myCompensation } = useSelector((state) => state.paymentsReducer);
  const { setMyCompensation } = useActions();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
    values: myCompensation,
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    if (receiptFiles.length > 0) {
      setCheckFilesError(false);
    }
  }, [receiptFiles]);

  useEffect(() => {
    if (checkFiles.length > 0) {
      setPhotoFilesError(false);
    }
  }, [checkFiles]);

  const onSubmit = async (
    { expenses_period, payment_period, request_text, amount, ...data },
    draft,
  ) => {
    console.log(data.type);
    try {
      const newData = {
        ...data,
        expenses_period: expenses_period
          ? dayjs(expenses_period).format("YYYY-MM")
          : null,
        payment_period: payment_period
          ? dayjs(payment_period).format("YYYY-MM")
          : null,
        ...(receiptFiles.length > 0 && {
          checks: receiptFiles.map((file) => file.id),
        }),
        ...(checkFiles.length > 0 && {
          photos: checkFiles.map((file) => file.id),
        }),
        ...(amount && { amount }),
        ...(request_text && { request_text }),
      };

      if (receiptFiles.length > 0) {
        setCheckFilesError(false);
        clearErrors("receiptFiles");
      }

      if (checkFiles.length > 0) {
        setPhotoFilesError(false);
        clearErrors("checkFiles");
      }

      if (!draft) {
        if (receiptFiles.length === 0) {
          setCheckFilesError(true);
          setError("root.random", {
            type: "receiptFiles",
          });
        }

        if (checkFiles.length === 0 && data.type === "Спорт") {
          setPhotoFilesError(true);
          setError("root.random", {
            type: "checkFiles",
          });
        }

        !amount &&
          setError("amount", {
            type: "custom",
            message: "Обязательное поле",
          });

        !payment_period &&
          setError("payment_period", {
            type: "custom",
            message: "Обязательное поле",
          });

        !request_text &&
          setError("request_text", {
            type: "custom",
            message: "Обязательное поле",
          });

        if (
          data.type === "Спорт" &&
          receiptFiles.length > 0 &&
          checkFiles.length > 0 &&
          request_text &&
          payment_period &&
          request_text
        ) {
          setLoading(true);

          await $authHost.put(
            `/compensation/${myCompensationId}/me/update/`,
            convertToNull({ ...newData, status: "Новое" }),
          );
        }

        if (
          data.type !== "Спорт" &&
          receiptFiles.length > 0 &&
          request_text &&
          payment_period &&
          request_text
        ) {
          setLoading(true);

          await $authHost.put(
            `/compensation/${myCompensationId}/me/update/`,
            convertToNull({ ...newData, status: "Новое" }),
          );
        }
      }

      if (draft) {
        setLoading(true);

        await $authHost.put(
          `/compensation/${myCompensationId}/me/update/`,
          convertToNull({ ...newData, status: "Черновик" }),
        );
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/${myCompensationId}/`,
      );
      const data = response.data;
      setMyCompensation(data);
      data.photos && setPhotoFiles(data.photos);
      data.checks && setCheckFiles(data.checks);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setMyCompensation, myCompensationId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/my-compensations");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/payments/my-compensations/${myCompensationId}`);
  };

  const paymentPeriod = useWatch({ control, name: "payment_period" });
  const expensesPeriod = useWatch({ control, name: "expenses_period" });

  useEffect(() => {
    if (paymentPeriod) {
      const paymentPeriodIsBetween = dayjs(paymentPeriod).isBetween(
        expensesPeriod,
        dayjs(expensesPeriod).add(2, "month"),
        "month",
        "[]",
      );

      if (!paymentPeriodIsBetween) {
        setError("payment_period", {
          type: "custom",
          message:
            "Период выплаты не может быть раньше периода расходов или превышать его более чем на 2 месяца. Пожалуйста, выберите корректный период",
        });
      } else {
        clearErrors("payment_period");
      }
    } else {
      clearErrors("payment_period");
    }
  }, [clearErrors, expensesPeriod, paymentPeriod, setError]);

  return (
    <Card sx={{ mb: 2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 2,
        }}
      >
        <Typography variant="subtitle2">Редактировать</Typography>
        {isMobile ? (
          <IconButton color="primary" onClick={handleBack} sx={{ p: 1 }}>
            <CloseIcon />
          </IconButton>
        ) : (
          <Button
            color="primary"
            onClick={handleBack}
            size="small"
            startIcon={<CloseIcon />}
            variant="text"
          >
            Отмена
          </Button>
        )}
      </Stack>
      <Divider />
      <Box sx={{ p: 2 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Общее
                </Typography>
                <CustomAutocompleteNew
                  disableClearable
                  disabled
                  helperText='Поле "Вид компенсации" будет недоступно для изменений после сохранения заявки'
                  label="Вид компенсации"
                  name="type"
                  options={[
                    "Спорт",
                    "Подарок на ДР",
                    "Командировочные расходы",
                    "Другое",
                  ]}
                  required
                />
                <CustomAutocompleteNew
                  disableClearable
                  disabled
                  helperText={
                    'Поле "Валюта" будет недоступно для изменений после сохранения заявки'
                  }
                  label="Валюта"
                  name="currency"
                  options={["RUB", "KZT"]}
                  required
                />
                <CustomTextFieldNew label="Сумма в валюте" name="amount" />
                <CustomDatepickerNew
                  disabled
                  format="MMMM YYYY"
                  helperText='Поле "Период расходов" будет недоступно для изменений после сохранения заявки'
                  label="Период расходов"
                  name="expenses_period"
                  required
                  sx={{ mb: 6 }}
                  views={["year", "month"]}
                />
                <CustomDatepickerNew
                  format="MMMM YYYY"
                  label="Период выплаты"
                  name="payment_period"
                  sx={{ mb: 2 }}
                  views={["year", "month"]}
                />
                <CustomTextFieldNew
                  label="Текст запроса"
                  multiline
                  name="request_text"
                  rows={3}
                />
              </ColumnStack>
              <ColumnStack>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Документы
                </Typography>
                <CustomFileInput
                  error={receiptFilesError}
                  files={receiptFiles}
                  helperText='Переименуйте файл перед загрузкой "Фамилия_ВидКомпенсации_ММ-ГГГГ_чек_номер" например "Иванов_Спорт_10-2024_чек_1"'
                  label="Чек"
                  setFiles={setCheckFiles}
                  setLoading={setLoading}
                />
                <CustomFileInput
                  error={checkFilesError}
                  files={checkFiles}
                  helperText='Переименуйте файл перед загрузкой "Фамилия_ВидКомпенсации_ММ-ГГГГ_фото_номер" например "Иванов_Спорт_10-2024_фото_1"'
                  label="Фото-отчет"
                  setFiles={setPhotoFiles}
                  setLoading={setLoading}
                />
              </ColumnStack>
            </RowStack>
            {isMobile ? (
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={3}
                sx={{ width: "100%", mt: 3 }}
              >
                <Box>
                  <Button
                    onClick={handleSubmit((data) => onSubmit(data, true))}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="contained"
                  >
                    Сохранить черновик
                  </Button>
                  <Button
                    size="small"
                    sx={{ width: "100%", marginTop: "16px" }}
                    variant="outlined"
                    оnClick={handleSubmit((data) => onSubmit(data, false))}
                  >
                    Сохранить и отправить на согласование
                  </Button>
                </Box>
                <Button
                  onClick={handleReset}
                  size="small"
                  sx={{ width: "100%" }}
                  variant="text"
                >
                  Сбросить
                </Button>
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Button
                  color="primary"
                  onClick={handleReset}
                  size="small"
                  variant="outlined"
                >
                  Сбросить
                </Button>
                <Stack direction="row" spacing={2}>
                  <Button
                    onClick={handleSubmit((data) => onSubmit(data, false))}
                    size="small"
                    variant="outlined"
                  >
                    Сохранить и отправить на согласование
                  </Button>
                  <Tooltip title="Черновики заявок доступны для просмотра ТОПам, но без возможности их согласования">
                    <Button
                      onClick={handleSubmit((data) => onSubmit(data, true))}
                      size="small"
                      variant="contained"
                    >
                      Сохранить черновик
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Box>
    </Card>
  );
};

export default MyCompensationEdit;
