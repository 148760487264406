import { Suspense } from "react";

import { createBrowserRouter, RouterProvider } from "react-router";

import LoadingSpinner from "./components/modals/loadingSpinner";
import { AuthProvider } from "./hoc/authProvider";
import Router, { getRoutes } from "./routes";
import ThemeProvider from "./theme";

const router = createBrowserRouter(getRoutes());

const App = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <ThemeProvider>
      {/*<AuthProvider>*/}
      <RouterProvider router={router} />
      {/*</AuthProvider>*/}
    </ThemeProvider>
  </Suspense>
);
export default App;
