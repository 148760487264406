import React, { useCallback, useEffect, useMemo, useState } from "react";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  LocalizationProvider,
  MonthCalendar,
  YearCalendar,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CardComponent from "../components/cardComponent";
import MaterialTable from "../components/MaterialTable";
import ProdCalen from "../components/ProdCalen";
import TooltipTimesheet from "../components/TooltipTimesheet/TooltipTimesheet";
import { TIMESHEET_STATUSES } from "../constants";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingTimesheet, setLoadingTimesheet] = useState(false);
  const [users, setUsers] = useState([]);
  const [timesheet, setTimesheet] = useState({});
  const [monthCalendar, setMonthCalendar] = useState(dayjs().format("M"));
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);
  const { display_name } = useSelector((state) => state.authReducer);
  const { statistics } = useSelector((state) => state.statisticsReducer);
  const { setStatistics } = useActions();
  const isMobile = useResponsive("down", "sm");

  const {
    clickup_tracked_hours,
    plan_month_hours,
    vacation_days,
    plan_vacation_days,
    sick_days,
    plan_sick_days,
    overworked_days,
    summarized_clickup_trackers = [],
    clickup_closed_tasks = [],
    clickup_day_trackers = [],
  } = statistics;

  const loadStatistics = useCallback(
    async (month, year) => {
      setLoading(true);

      try {
        const selectedMonth = dayjs(month).month() + 1;
        const selectedYear = dayjs(year).year();

        const response = await $authHost.get(
          `/statistics/me/?month=${selectedMonth}&year=${selectedYear}`,
        );

        setStatistics(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setStatistics],
  );

  const loadUsers = useCallback(async (monthCalendar) => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/users/all/birthday/?month=${monthCalendar}`,
      );

      setUsers(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadTimesheet = useCallback(async (month, year) => {
    setLoadingTimesheet(true);

    try {
      const selectedMonth = dayjs(month).month() + 1;
      const selectedYear = dayjs(year).year();

      const response = await $authHost.get(
        `/report_cards/me/?year=${selectedYear}&month=${selectedMonth}&is_calendar_with_month=false`,
      );
      setTimesheet([response.data]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTimesheet(false);
    }
  }, []);

  useEffect(() => {
    loadStatistics(month, year);
    loadTimesheet(month, year);
  }, [loadStatistics, loadTimesheet, month, year]);

  useEffect(() => {
    loadUsers(monthCalendar);
  }, [loadUsers, monthCalendar]);

  const options1 = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      // width: 3,
    },
    grid: {
      // borderColor: '#e7e7e7',
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      // categories: days,
      // title: {
      //   text: 'Дни'
      // },
      // type: 'category',
      // type: 'numeric',
      type: "datetime",
      // tickAmount: 'dataPoints',
      // tickPlacement: 'between',
      labels: {
        format: "d MMM",
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format("D MMM");
        },
      },
    },
    yaxis: {
      title: {
        text: "Часы",
      },
      decimalsInFloat: 2,
      // min: 0,
      // max: 120
    },
    noData: {
      text: "Нет данных для отображения",
      style: {
        color: "#000000",
        fontSize: "16px",
      },
    },
  };

  const series1 = [
    {
      name: "Кол-во часов",
      data: summarized_clickup_trackers,
      color: "#63a3ff",
    },
  ];

  const options2 = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2],
      curve: ["smooth", "stepline"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "d MMM",
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format("D MMM");
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Закрытые задачи",
        },
        decimalsInFloat: 2,
      },
      {
        opposite: true,
        title: {
          text: "Часы",
        },
        decimalsInFloat: 2,
      },
    ],
    noData: {
      text: "Нет данных для отображения",
      style: {
        color: "#000000",
        fontSize: "16px",
      },
    },
  };

  const series2 = [
    {
      name: "Закрытые задачи",
      type: "column",
      data: clickup_closed_tasks,
      color: "#aac4ed",
    },
    {
      name: "Часы",
      type: "line",
      data: clickup_day_trackers,
      color: "#257cff",
    },
  ];

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, "month");

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, "year");
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, "month");

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, "year");
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const days = useMemo(() => {
    const daysInMonth = dayjs(month).daysInMonth();
    const daysArr = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArr.push(i);
    }

    return daysArr;
  }, [month]);

  const { tooltipCell, isOpenTooltipCell } = useSelector(
    (state) => state.timesheetsReducer,
  );
  const { setIsOpenTooltipCell, setTooltipCell } =
    useActions();

  const dataCellView = (cell) => {
    if (cell.id === tooltipCell && isOpenTooltipCell) {
      setIsOpenTooltipCell(false);
      setTooltipCell(null);
    } else {
      setIsOpenTooltipCell(true);
      setTooltipCell(cell.id);
    }
  };

  const tableColumns = [
    ...days.map((d) => ({
      id: `calendar.${d.toString()}`,
      accessorFn: (row) => row.calendar?.[d.toString()] || "",
      header: d.toString(),
      size: 1,
      muiTableBodyCellProps: ({ cell, table }) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find(
          (status) => status.value === value,
        );
        if ((status && !status?.editing) || !status) {
          return {
            sx: {
              padding:'3px',
              cursor: "default",
            },
            onClick: () => {
              dataCellView(cell);
            },
          };
        } else {
          return {
            sx: {
              padding:'3px',
            },
            onClick: () => {
              dataCellView(cell);
            },
          };
        }
      },
      Cell: ({ renderedCellValue, row, cell }) => (
        <TooltipTimesheet
          cell={cell}
          data={row}
          element={renderedCellValue}
        />
      ),
    })),
  ];

  return (
    <Box
      sx={{
        pb: 3,
      }}
    >
      {/*<TitleAndButtonComponent title="Общие данные"/>*/}
      <Typography
        align="left"
        component="p"
        sx={{
          pt:0.5,
          pb:3
        }}
        variant="h4Wide"
      >
        Общие данные
      </Typography>
      <Stack
        direction={{ xs: "column", md: "column" }}
        spacing={4}
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          marginBottom: 5,
          borderRadius: "8px",
        }}
      >
        <Card sx={{ width: "100%" }}>
          <Stack
            direction="row"
            spacing={0}
            sx={{ flexWrap: "wrap", width: "100%" }}
            useFlexGap
          >
            <CardComponent
              color="text.primary"
              currentValue={clickup_tracked_hours}
              description="Кол-во затреканых часов в масяц"
              divider={4}
              label="Затрекано"
              totalValue={plan_month_hours}
            />
            <CardComponent
              color="text.primary"
              currentValue={vacation_days}
              description="Использовано отпускных дней"
              divider={4}
              label="Отпускные"
              totalValue={plan_vacation_days}
            />
            <CardComponent
              color="text.primary"
              currentValue={sick_days}
              description="Использовано дней оплачиваемых больничных"
              divider={4}
              label="Больничные"
              totalValue={plan_sick_days}
            />
            <CardComponent
              color="text.primary"
              currentValue={overworked_days}
              description="Кол-во дней сверхурочной работы"
              divider={4}
              label="Переработки"
            />
          </Stack>
        </Card>

        <Box sx={{ width: "100%" }}>
          <Card
            sx={(theme) => ({
              margin: "0",
              width: { xs: "100%" },
            })}
          >
            <Typography
              sx={{
                padding: {
                  xs: "16px 16px 16px 16px",
                  sm: "16px 16px 8px 16px",
                },
                mb: 1,
              }}
              variant="subtitle2"
            >
              Производственный календарь
            </Typography>
            <Divider />
            <Stack
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 1, md: 4 }}
              sx={{
                // backgroundColor:"red",
                justifyContent: "flex-start",
                alignItems:{ xs: "flex-start", sm: "stretch", md: "stretch" },
                padding: {
                  xs: "16px 16px 16px 16px",
                  sm: "16px 16px 8px 16px",
                },
              }}
            >
              <ProdCalen setDate={setMonthCalendar} users={users} />
              {isMobile ? (
                <Accordion sx={{ backgroundColor: "transparent", width: "100%" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="miniHeader">
                      Дни рождения в этом месяце
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Array.isArray(users) && users.length > 0 && (
                      <Stack
                        direction="row"
                        spacing={{ xs: 1, sm: 2 }}
                        sx={{ flexWrap: "wrap" }}
                        useFlexGap
                      >
                        {users.map((user) => (
                          <Button
                            color="primary"
                            href={`/org-structure/employees/${user?.source_id}`}
                            key={user?.source_id}
                            sx={(theme) => ({
                              paddingLeft: 1.5,
                              paddingRight: 1.5,
                            })}
                            variant="outlined"
                          >
                            <Stack
                              alignItems="center"
                              direction="row"
                              spacing={isMobile ? 1 : 1.5}
                            >
                              <Avatar
                                alt="photoURL"
                                src={user?.photo}
                                sx={{
                                  width: "28px",
                                  height: "28px",
                                }}
                              />
                              <Stack direction="column">
                                <Typography
                                  sx={{
                                    color: "text.primary",
                                    textAlign: "start",
                                    fontWeight: "bold",
                                  }}
                                  variant="body3"
                                >
                                  {user?.display_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "text.secondary",
                                    textAlign: "start",
                                    fontSize: "10px",
                                  }}
                                  variant="body3"
                                >
                                  {dayjs(user?.birth_date).format(
                                    "ddd, D MMMM",
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Button>
                        ))}
                      </Stack>
                    )}
                    {Array.isArray(users) && users.length === 0 && (
                      <Box
                        sx={{
                          minWidth: { xs: 0, sm: 0, md: 300 },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          color="primary"
                          label="Нет данных"
                          size="small"
                          variant="outlined"
                        />
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Stack
                  spacing={1}
                  sx={{
                    width: "50%",
                    paddingTop: { xs: 0, sm: 2 },
                    // backgroundColor:"lightgreen",
                    overflowX: "scroll",
                  }}
                >
                  <Typography variant="miniHeader">
                    Дни рождения в этом месяце:
                  </Typography>
                  {Array.isArray(users) && users.length > 0 && (
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 2 }}
                      sx={{ flexWrap: "wrap" }}
                      useFlexGap
                    >
                      {users.map((user) => (
                        <Button
                          color="primary"
                          href={`/org-structure/employees/${user?.source_id}`}
                          key={user?.source_id}
                          size="large"
                          sx={(theme) => ({
                            paddingLeft: 2,
                            paddingRight: 2,
                          })}
                          variant="outlined"
                        >
                          <Stack
                            alignItems="center"
                            // component='link'
                            direction="row"
                            key={user?.source_id}
                            spacing={isMobile ? 1 : 1.5}
                          >
                            <Avatar
                              alt="photoURL"
                              src={user?.photo}
                              sx={{
                                width: "35px",
                                height: "35px",
                              }}
                            />
                            <Stack direction="column">
                              <Typography
                                sx={{
                                  color: "text.primary",
                                  textAlign: "start",
                                  fontWeight: "bold",
                                }}
                                variant="body2"
                              >
                                {user?.display_name}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "start",
                                  fontSize: "12px",
                                }}
                                variant="body2"
                              >
                                {dayjs(user?.birth_date).format("ddd, D MMMM")}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Button>
                      ))}
                    </Stack>
                  )}
                  {Array.isArray(users) && users.length === 0 && (
                    <Box
                      sx={{
                        // minWidth: { xs: 0, sm: 0, md: 300 },
                        paddingTop: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        color="primary"
                        label="Нет данных"
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
          </Card>
        </Box>
      </Stack>

      <Card>
        <Stack>
          <Stack>
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 1,
                padding: {
                  xs: "16px 16px 16px 16px",
                  sm: "16px 16px 8px 16px",
                },
              }}
            >
              <Typography variant="subtitle2">
                Статистика рабочего времени
              </Typography>

              <Stack
                alignItems="center"
                direction="row"
                sx={(theme) => ({
                  // backgroundColor:'common.white',
                  borderRadius: "8px",
                  border: `1px solid ${theme.palette.divider}`,
                  px: 1,
                  py: 0.5,
                  // boxShadow: theme.customShadows.card,
                })}
              >
                <IconButton onClick={handlePrev} sx={{ padding: 0 }}>
                  <NavigateBeforeIcon color="secondary" />
                </IconButton>
                <ButtonGroup color="secondary" size="small" variant="text">
                  <Button
                    color="secondary"
                    onClick={handleClickMonth}
                    sx={{
                      paddingRight: 1,
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRightWidth: "2px !important",
                    }}
                  >
                    {dayjs(month).format("MMMM")}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleClickYear}
                    sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0 }}
                  >
                    {dayjs(year).format("YYYY")}
                  </Button>
                </ButtonGroup>
                <IconButton onClick={handleNext} sx={{ padding: 0 }}>
                  <NavigateNextIcon color="secondary" />
                </IconButton>
                <Popover
                  anchorEl={anchorMonth}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={handleCloseMonth}
                  open={!!anchorMonth}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MonthCalendar
                      onChange={(v) => setMonth(v)}
                      value={month}
                    />
                  </LocalizationProvider>
                </Popover>
                <Popover
                  anchorEl={anchorYear}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={handleCloseYear}
                  open={!!anchorYear}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <YearCalendar onChange={(v) => setYear(v)} value={year} />
                  </LocalizationProvider>
                </Popover>
              </Stack>
            </Stack>
            <Divider />

            <Stack>
              {/*<div>*/}
              <Stack
                direction="column"
                justifyContent="space-between"
                spacing={1}
                sx={{
                  marginBottom: 1,
                  padding: {
                    xs: "16px 16px 16px 16px",
                    sm: "16px 16px 8px 16px",
                  },
                }}
              >
                <Typography variant="miniHeader">Табель</Typography>
                <MaterialTable
                  columns={tableColumns}
                  data={timesheet}
                  enableBottomToolbar={false}
                  enableSorting={false}
                  enableTopToolbar={false}
                  initialState={{
                    density: "compact",
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      //borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      // borderRight: "1px solid rgba(224, 224, 224, 1)",
                      backgroundColor: "#ffffff",
                    },
                  }}
                  muiTableContainerProps={{
                    sx: {
                      // borderTop: "1px solid rgba(224, 224, 224, 1)",
                      // borderLeft: "1px solid rgba(224, 224, 224, 1)",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      // borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                  }}
                  state={{ isLoading: loadingTimesheet }}
                />
              </Stack>

              {/*</div>*/}
              <Box
                sx={{
                  marginBottom: 1,
                  padding: {
                    xs: "16px 16px 16px 16px",
                    sm: "16px 16px 8px 16px",
                  },
                }}
              >
                <Typography variant="miniHeader">
                  Распределение времени по задачам
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <ReactApexChart
                      height={350}
                      options={options1}
                      series={series1}
                      type="line"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ReactApexChart
                      height={350}
                      options={options2}
                      series={series2}
                      type="line"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default MainPage;
