import React, { useCallback, useEffect, useState } from "react";

import { Box, Card} from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router";

import Label from "../../components/label/Label";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import PersonInfo from "../../components/UserCard";
import {getColorByStatus} from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { getSortingString } from "../../utils/getSortingString";

const CompensationList = () => {
  const tableId = "myCompensationList";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { setCompensation, setCompensations } = useActions();
  const { compensation, compensations } = useSelector((state) => state.paymentsReducer);
  const [openForm, setOpenForm] = useState("");

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      accessorKey: "updated_at",
      header: "Дата последнего изменения",
      accessorFn: (row) => dayjs(row.updated_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <TableCellsWrap>
            {renderedCellValue ? (
              <Label color={getColorByStatus(row.original.status)}>
                {renderedCellValue}
              </Label>
            ) : (
              <Label color="default">Не указано</Label>
            )}
          </TableCellsWrap>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Вид выплаты",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user.display_name",
      header: "Сотрудник",
      accessorFn: (row) => row.user?.display_name || "",
      Cell: ({ renderedCellValue, row }) => {
        if (row.original.user !== null) {
          return (
            <TableCellsWrap>
              <PersonInfo
                firstName={row.original.user?.first_name}
                lastName={row.original.user?.last_name}
                photo={row.original.user?.photo}
              />
            </TableCellsWrap>
          );
        } else {
          return <TableCellsWrap></TableCellsWrap>;
        }
      },
    },
    {
      accessorKey: "department.name",
      header: "Отдел",
      accessorFn: (row) => row.department?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "currency",
      header: "Валюта",
      accessorFn: (row) => row.currency || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "amount",
      header: "Сумма",
      accessorFn: (row) => row.amount || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "expenses_period",
      header: "Период расходов",
      accessorFn: (row) =>
        row.expenses_period
          ? dayjs(row.expenses_period).format("MMMM YYYY")
          : "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "payment_period",
      header: "Период выплаты",
      accessorFn: (row) =>
        row.payment_period ? dayjs(row.payment_period).format("MMMM YYYY") : "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "request_text",
      header: "Текст запроса",
      accessorFn: (row) => row.request_text || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user_approved.display_name",
      header: "Кто решил",
      accessorFn: (row) => row.user_approved?.display_name || "",
      Cell: ({ row }) => {
        if (row.original.user_approved !== null) {
          return (
            <TableCellsWrap>
              <PersonInfo
                firstName={row.original.user_approved?.first_name}
                lastName={row.original.user_approved?.last_name}
                photo={row.original.user_approved?.photo}
              />
            </TableCellsWrap>
          );
        } else {
          return <TableCellsWrap></TableCellsWrap>;
        }
      },
    },
    {
      accessorKey: "comment",
      header: "Комментарий",
      accessorFn: (row) => row.comment || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Дата подачи",
      accessorFn: (row) => dayjs(row.created_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/payments/compensations/${id}`);
    }
  };



  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/compensation/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
      setCompensations(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setCompensations,
    sorting,
  ]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box>
      <Card>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={compensations}
          enableHiding={true}
          id={tableId}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>
  );
};

export default CompensationList;
