import React from 'react';

import {Icon} from "@iconify/react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Box, Button, IconButton, Tooltip, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";

import Iconify from "../../components/iconify";


const TitleAndButtonComponent = (props) => {


  return (
    <Stack
      direction="row"
      id="titleElem"
      sx={{
        // alignItems: props?.back ? "flex-end" :"center",
        alignItems: "flex-end",
        justifyContent: props.type ? "space-between" : "flex-start",
        width:'100%',
        height:'70px',
        pb:2
      }}
    >
      {props?.back &&
        <Stack
          direction="column"
          sx={{
            height:'100%',
            width:'80%',
            alignItems: "flex-start",
            justifyContent: "space-between",
            overflow: 'hidden',
          }}
        >
          <Button
            color="primary"
            onClick={props.back}
            size="small"
            startIcon={
              <Icon height="14" icon="material-symbols-light:arrow-back" width="14" />
            }
            sx={{
              p:0,
              fontSize:14,
            }}
            variant="text"
          >
            {props?.backTitle}
          </Button>
          <Typography
            align="left"
            component="p"
            pb={0.5}
            variant="h4Wide"
          >
            {props?.title}
          </Typography>
        </Stack>
      }
      {props?.title && !props.back &&
        <Typography
          align="left"
          component="p"
          pb={0.5}
          variant="h4Wide"
        >
          {props.title}
        </Typography>
      }
      {props?.name &&
        <Button
          aria-haspopup="true"
          onClick={props.action}
          size="small"
          startIcon={<Iconify icon="eva:plus-fill" />}
          variant="outlined"
        >
          {props.name}
        </Button>
      }

    </Stack>
  );
};

export default TitleAndButtonComponent;