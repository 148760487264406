import React, { useCallback, useEffect, useState } from "react";

import {Icon} from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Card, Divider, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import UserContractEdit from "./UserContractEdit";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import TitleAndButtonComponent from "../../layouts/StandardElementsForPages/TitleAndButtonComponent";
import {ColumnStack, RowStack } from "../../theme/standarts_styles";
import { getSortingString } from "../../utils/getSortingString";


const UserContractsView = () => {
  const tableId = "userContracts";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const isMobile = useResponsive("down", "sm");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { selectedContract, contracts } = useSelector(
    (state) => state.contractsReducer,
  );
  const { setContracts, setSelectedContract } = useActions();
  const { roles } = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);
  const [editMode, setEditMode] = useState(false);

  const tableColumns = [
    {
      accessorKey: "start_date",
      header: "Дата заключения",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => dayjs(row.end_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Состояние",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "type",
      header: "Тип",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "legal_entity",
      header: "Юр. лицо",
      accessorFn: (row) => row.legal_entity || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "date_and_number",
      header: "Дата и номер",
      accessorFn: (row) => row.date_and_number || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "interaction_capacity",
      header: "Емкость взаимодействия",
      accessorFn: (row) => row.interaction_capacity || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "hour_capacity",
      header: "Емкость часов fix",
      accessorFn: (row) => row.hour_capacity || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "loyalty_program",
      header: "Программа лояльности",
      accessorFn: (row) => row.loyalty_program || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "production_calendar",
      header: "Производственный календарь",
      accessorFn: (row) => row.production_calendar || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [
          {
            accessorKey: "month_rate",
            header: "Ставка мес.",
            accessorFn: (row) => row.month_rate || "",
            Cell: ({ renderedCellValue }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
          {
            accessorKey: "hour_rate",
            header: "Час. ставка",
            accessorFn: (row) => row.hour_rate || "",
            Cell: ({ renderedCellValue }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
        ]
      : []),
    {
      accessorKey: "vacation_days",
      header: "Отпуск в днях в год",
      accessorFn: (row) => row.vacation_days || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "sick_days",
      header: "Больничные в год",
      accessorFn: (row) => row.sick_days || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      setSelectedContract(id);
    }
  };

  const handleBack = () => {
    navigate("/employee-relations/contracts");
  };

  const handleEdit = () => {
    // navigate(`/employee-relations/contracts/${contractId}/edit`);
    setEditMode(true);
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/agreements/all/`, {
        params: {
          user_id: selectedContract.user_id,
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setContracts(response.data.items);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    selectedContract.user_id,
    setContracts,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box pb={2}>
      <TitleAndButtonComponent
        back={handleBack}
        backTitle="Вернуться к списку"
        title={`Договорные отношения ${selectedContract.user?.display_name}`}
        // title={`Сотрудник ${selectedEmployee?.display_name}`}
      />
      {editMode ? (
        <Card sx={{ mt:1, mb: 2 }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p:2
            }}
          >
            <Typography variant="subtitle2">
              Редактировать
            </Typography>
              {isMobile ?
                <IconButton color="primary" onClick={() => setEditMode(false)} sx={{ p: 1 }}>
                  <CloseIcon />
                </IconButton>
                :
                <Box>
                  <Button
                    color="primary"
                    onClick={() => setEditMode(false)}
                    size="small"
                    startIcon={
                      <CloseIcon />
                    }
                    variant="text"
                  >
                    Отмена
                  </Button>
                </Box>
              }
          </Stack>
          <Divider/>
          <UserContractEdit />
        </Card>
      ) : (
        <Card sx={{ mt:1, mb: 2 }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p:2
            }}
          >
            <Typography variant="subtitle2">
              Текущий договор
            </Typography>
            {roles?.some((role) => role === "ERP_Admins") && (
              <>
                {isMobile ?
                  <IconButton color="primary" onClick={handleEdit} sx={{ p: 1 }}>
                    <Icon height="20" icon="material-symbols-light:edit-outline-rounded" width="20"  />
                  </IconButton>
                  :
                  <Box>
                    <Button
                      color="primary"
                      onClick={handleEdit}
                      size="small"
                      startIcon={
                        <Icon height="20" icon="material-symbols-light:edit-outline-rounded" width="20"  />
                      }
                      variant="text"
                    >
                      Редактировать
                    </Button>
                  </Box>
                }
              </>
            )}

          </Stack>
          <Divider/>
          <Box sx={{ p: 2 }}>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Номер договора"
                  text={selectedContract.date_and_number}
                />
                <InfoAboutComponent
                  label="Дата заключения"
                  text={dayjs(selectedContract.start_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Дата окончания"
                  text={
                    selectedContract.is_unlimited
                      ? "Бессрочный"
                      : dayjs(selectedContract.end_date).format("DD.MM.YYYY")
                  }
                />
                <InfoAboutComponent
                  label="Статус договора"
                  text={selectedContract.status}
                />
                <InfoAboutComponent label="Тип договора" text={selectedContract.type} />
                <InfoAboutComponent
                  label="Юр. лицо"
                  text={selectedContract.legal_entity}
                />
                <InfoAboutComponent
                  label="Производственный календарь"
                  text={selectedContract.production_calendar}
                />
                <InfoAboutComponent
                  label="Программа лояльности"
                  text={selectedContract.loyalty_program}
                />
              </ColumnStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Условия
                </Typography>
                <InfoAboutComponent
                  label="Вид занятости"
                  text={selectedContract.interaction_capacity}
                />
                {selectedContract.interaction_capacity === "Fix" && (
                  <>
                    <InfoAboutComponent
                      label="Объем часов Fix"
                      text={selectedContract.hour_capacity}
                    />
                    {roles?.some((role) => role === "ERP_Admins") && (
                      <InfoAboutComponent
                        label="Ставка в час"
                        text={selectedContract.hour_rate}
                      />
                    )}
                  </>
                )}
                {roles?.some((role) => role === "ERP_Admins") &&
                  selectedContract.interaction_capacity === "Fulltime" && (
                    <InfoAboutComponent
                      label="Ставка в месяц"
                      text={selectedContract.month_rate}
                    />
                  )}
                {roles?.some((role) => role === "ERP_Admins") && (
                  <InfoAboutComponent
                    label="Валюта договора"
                    text={selectedContract.currency}
                  />
                )}
                <InfoAboutComponent
                  label="Отпуск в год, дней"
                  text={selectedContract.vacation_days}
                />
                <InfoAboutComponent
                  label="Больничные в год, дней"
                  text={selectedContract.sick_days}
                />

              </ColumnStack>
            </RowStack>
          </Box>
        </Card>
      )}


      <Card>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          customTitle="Все договоры по сотруднику"
          data={contracts}
          enableHiding={true}
          id={tableId}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>
  );
};

export default UserContractsView;
