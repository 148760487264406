import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import BlockFormModal from "../BlockFormModal";

const PlanningAdd = ({ handleClose, loadDataPlans }) => {
  const [loading, setLoading] = useState(false);

  const { projectId } = useParams();
  const isMobile = useResponsive("down", "sm");

  const { project } = useSelector((state) => state.projectsReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    register,
    reset,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    const { user, time_estimated, start_date, end_date } = data;

    const newData = {
      time_estimated,
      user_id: user?.source_id,
      start_date: dayjs(start_date).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs(end_date).format("YYYY-MM-DDTHH:mm:ss"),
      type: "Plan",
      project_id: projectId,
    };

    try {
      const response = await $authHost.post("/calendar_entries/add/", newData);
      setLoading(false);
      handleClose();
      loadDataPlans();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card sx={{ p: 3, mb: 2 }}>
          {isMobile && (
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 3 }}
            >
              <Grid item>
                <Typography color="text.secondary" variant="subtitle1">
                  Добавить
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              alignItems="flex-start"
              container
              direction="column"
              justifyContent="space-between"
            >
              <Controller
                control={control}
                name="user"
                render={({
                  field: { onChange, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <Autocomplete
                      {...field}
                      getOptionLabel={(option) => option.display_name}
                      onChange={(e, value) => onChange(value)}
                      options={project?.team}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error?.message}
                          label="Сотрудник"
                          size="small"
                        />
                      )}
                      sx={{
                        mb: 2,
                        width: "100%",
                      }}
                    />
                  );
                }}
                rules={
                  {
                    // required: 'Обязательное поле',
                  }
                }
              />
              <TextField
                label="Плановая нагрузка"
                {...register("time_estimated", {
                  // required: 'Обязательное поле',
                  pattern: {
                    value: /^\d*$/,
                    message: "Некорректный ввод",
                  },
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">h</InputAdornment>
                  ),
                }}
                error={!!errors?.time_estimated}
                fullWidth
                helperText={errors?.time_estimated?.message}
                size="small"
                sx={{
                  mb: 2,
                }}
              />
              <Grid container spacing={isMobile ? 0 : 2}>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                  <Controller
                    control={control}
                    name="start_date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          label="Дата начала"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                            },
                          }}
                          sx={{
                            width: "100%",
                            mb: 2,
                          }}
                          value={value}
                        />
                      </LocalizationProvider>
                    )}
                    rules={
                      {
                        // required: 'Обязательное поле',
                      }
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                  <Controller
                    control={control}
                    name="end_date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          label="Дата конца"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                            },
                          }}
                          sx={{
                            width: "100%",
                            mb: 2,
                          }}
                          value={value}
                        />
                      </LocalizationProvider>
                    )}
                    rules={
                      {
                        // required: 'Обязательное поле',
                      }
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: "100%", mb: 2 }}>
              {isMobile ? (
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{ width: "100%", mt: 3 }}
                >
                  <Button
                    disabled={loading}
                    sx={{ width: "100%" }}
                    type="submit"
                    variant="contained"
                  >
                    Сохранить
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => reset()}
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    Очистить
                  </Button>
                </Stack>
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      disabled={loading}
                      sx={{ mr: 2 }}
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleClose} variant="text">
                      Закрыть
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={loading}
                      onClick={() => reset()}
                      variant="text"
                    >
                      Очистить
                    </Button>
                  </Grid>
                </Stack>
              )}
            </Grid>
          </form>
        </Card>
      </Grid>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Grid>
  );
};

export default PlanningAdd;
