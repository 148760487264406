import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  calendar_rules: [],
  promotions: [],
  myPromotion: {},
  promotion: {},
  selectedStatement: null,
  statement: null,
};

const CalendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setCalendarRules(state, { payload }) {
      state.calendar_rules = payload;
    },
    setPromotions(state, { payload }) {
      state.promotions = payload;
    },
    setMyPromotion(state, { payload }) {
      state.myPromotion = payload;
    },
    setPromotion(state, { payload }) {
      state.promotion = payload;
    },
    setSelectedStatement(state, { payload }) {
      const index = state.calendar_rules.findIndex(
        (statement) => statement.id === payload,
      );
      state.selectedStatement = state.calendar_rules[index];
    },
    setStatement(state, { payload }) {
      state.statement = payload;
    },
  },
});

export const { actions: calendarActions, reducer: calendarReducer } =
  CalendarSlice;
export default CalendarSlice.reducer;
