import React from "react";

import {
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import AccountPopover from "./AccountPopover";
import Logo from "../../components/Logo";
import {
  StyledRootAppBar,
  StyledToolbar,
} from "../../theme/standarts_styles";

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header() {

  const { isInitAuth } = useSelector((state) => state.authReducer);

  // const currentLocation = status === 'Active' || status === 'Locked'
  const currentLocation = isInitAuth === true;
  const { openNav } = useSelector((state) => state.serviceReducer);

  return (
    <StyledRootAppBar openNav={openNav}>
      <StyledToolbar openNav={openNav}>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            width:'100%',
          }}
        >
          <Box/>
          <Box>{currentLocation && <AccountPopover />}</Box>
        </Stack>
      </StyledToolbar>
    </StyledRootAppBar>
  );
}
