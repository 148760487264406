import { useCallback, useEffect, useState } from "react";

import {Autocomplete, TextField, Typography} from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { $authHost } from "../../../http";
import { getLocalizedForDropdownValues } from "../../../utils/getLocalizedForDropdownValues";
import CustomAutocompleteNew from "../../TextFields/CustomAutocompleteNew";
import CustomDatepickerNew from "../../TextFields/CustomDatepickerNew";
import CustomMultiAutocompleteForObjectLocalized from "../../TextFields/CustomMultiAutocompleteForObjectLocalized";
import CustomTextFieldNew from "../../TextFields/CustomTextFieldNew";

const LeaveApplicationFields = ({ openForm }) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state.authReducer);
  // const { users } = useSelector((state) => state.usersReducer);
  // const { setUsers } = useActions();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [payerOptions, setPayerOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([
    {
      name: "Отпуск",
      original_name: "vacation",
    },
    {
      name: "Отгул",
      original_name: "day_off",
    },
    {
      name: "Больничный",
      original_name: "sick_leave",
    },
    {
      name: "Увольнение",
      original_name: "fired",
    },
    {
      name: "Сверхурочные",
      original_name: "overtime_work_days",
    },
    {
      name: "Работа в праздники/выходные",
      original_name: "working_holidays",
    },
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [
          {
            name: "Поощрение",
            original_name: "promotion",
          },
        ]
      : []),
  ]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [statements, setStatements] = useState([]);

  const [selectedTypeOption, setSelectedTypeOption] = useState(null);

  const getProjects = useCallback(async () => {
    const response = await $authHost.get(`/projects/list/`);
    setProjects(response.data);
  }, []);

  const loadData = useCallback(async () => {
    try {
      const [payerOptionsResponse, typeOptionsResponse, usersResponse] =
        await Promise.all([
          $authHost.get(`/dropdowns/payer_options/`),
          $authHost.get(`/dropdowns/type_options/`),
          $authHost.get(`/users/list/`),
        ]);
      console.log(typeOptionsResponse.data.dropdown);
      setPayerOptions(payerOptionsResponse.data.dropdown);
      // setTypeOptions(typeOptionsResponse.data.dropdown);
      setUsers(usersResponse.data);
    } catch (e) {
      console.log(e);
    }
  }, [setUsers]);

  const FilterPayOptionForTypes = (options) => {
    let baseRule = [
      {
        original_name: "company_expense",
        available_types: [
          "vacation",
          "sick_leave",
          "overtime_work_days",
          "working_holidays",
        ],
      },
      {
        original_name: "self_expense",
        available_types: ["vacation", "sick_leave"],
      },
      {
        original_name: "overwork_expense",
        available_types: ["day_off"],
      },
    ];
    let available_original_name = baseRule.filter((el) =>
      el.available_types.includes(selectedTypeOption),
    );
    return options.filter((el) =>
      available_original_name
        .map((x) => x.original_name)
        .includes(el.original_name),
    );
  };

  useEffect(() => {
    loadData();
    getProjects();
  }, [getProjects, loadData]);

  const startDate = useWatch({ control, name: "start_date" });

  useEffect(() => {
    if (selectedTypeOption === "day_off" && startDate) {
      setValue("end_date", startDate);
    }
  }, [selectedTypeOption, setValue, startDate]);

  const user = useWatch({ control, name: "user" });
  const type = useWatch({ control, name: "type" });

  const getStatements = useCallback(async (userId) => {
    try {
      const response = $authHost.get(`/calendar_rules/all/`, {
        params: {
          user_id: userId,
        },
      });
      setStatements(response.data.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (user?.source_id && type === "promotion") {
      getStatements(user?.source_id);
    }
  }, [user, type, getStatements]);

  useEffect(() => {
    if (type === "promotion") {
      setValue("rate", "1");
    }
  }, [setValue, type]);

  return (
    <>
      <CustomMultiAutocompleteForObjectLocalized
        getoptionlabel={(option) => option.name}
        label="Тип заявления"
        name="type"
        onChangeValue={(e) => {
          setSelectedTypeOption(e);
          setValue("payer", null);
          setValue("start_date", null);
          setValue("end_date", null);
          setValue("users", null);
          setValue("request_text", null);
        }}
        options={getLocalizedForDropdownValues(typeOptions, "type")}
        rules={{
          required: t("main.another.requiredField"),
        }}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 2 }}
      />
      {selectedTypeOption !== "fired" && selectedTypeOption !== "promotion" && (
        <CustomMultiAutocompleteForObjectLocalized
          label="За чей счет"
          name="payer"
          onChangeValue={(e) => {}}
          options={FilterPayOptionForTypes(
            getLocalizedForDropdownValues(payerOptions, "payer"),
          )}
          rules={{
            required:
              selectedTypeOption !== "fired" && t("main.another.requiredField"),
          }}
          size="small"
          sx={{ width: "100%", fontSize: "12px", mb: 2 }}
        />
      )}
      {(selectedTypeOption === "working_holidays" ||
        selectedTypeOption === "promotion" ||
        selectedTypeOption === "overtime_work_days") && (
        <CustomAutocompleteNew
          disableClearable={selectedTypeOption !== "promotion"}
          getOptionLabel={(option) => option.code || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          label="Проект"
          name="project"
          options={projects}
          required={selectedTypeOption !== "promotion"}
          sx={{mb:2}}
          value={"id"}
        />
      )}
      <Stack direction="row" mb={4} spacing={2}>
        <CustomDatepickerNew
          disablePast
          helperText={
            selectedTypeOption === "promotion" &&
            "Период, за переработки в котором начисляются отгулы"
          }
          label={
            selectedTypeOption === "fired" ? "Дата увольнения" : "Дата начала"
          }
          name="start_date"
          required
        />
        {selectedTypeOption !== "fired" && (
          <CustomDatepickerNew
            disablePast
            disabled={selectedTypeOption === "day_off"}
            label="Дата окончания"
            name="end_date"
            required
          />
        )}
      </Stack>
      {(selectedTypeOption === "fired" || selectedTypeOption === "promotion") &&
        roles?.some((role) => role === "ERP_Admins") && (
          <>
            <Controller
              control={control}
              name="user"
              render={({
                field: { onChange, ...field },
                fieldState: { error },
              }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option?.display_name || ""}
                    onChange={(e, value) => {
                      onChange(value);
                    }}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        label="Сотрудник *"
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message}
                        sx={{ width: "100%", fontSize: "12px", mb: 2 }}
                      />
                    )}
                    size="small"
                  />
                );
              }}
              rules={{
                required:
                  (selectedTypeOption === "fired" ||
                    selectedTypeOption === "promotion") &&
                  roles?.some((role) => role === "ERP_Admins") &&
                  t("main.another.requiredField"),
              }}
            />
            {selectedTypeOption === "fired" && (
              <Alert severity="info" sx={{ mb: 3, width: "100%" }}>
                <Typography
                  sx={{
                    width:"100%",
                    overflowX: "visible",
                    whiteSpace: "wrap",
                  }}
                  variant="body2"
                >
                Обратите внимание, что заявлению на увольнение сотрудника,
                созданному Администратором, не требуется согласование, оно будет
                автоматически принято.
                </Typography>
              </Alert>
            )}
          </>
        )}
      {selectedTypeOption === "promotion" && (
        <>
          <CustomAutocompleteNew
            disableClearable
            label="Причина поощрения"
            name="reason"
            options={["Переработка", "Другое"]}
            required
          />
          <CustomAutocompleteNew
            disableClearable
            // disabled
            label="Документ-основание"
            name="document"
            options={["—"]}
            required
          />
          <CustomTextFieldNew
            helperText={
              "За полный день переработок в выходные дни РП полагается 2 отгула (коэффициент=2), за сверхурочную работу в будни - один отгул (коэффициент=1)"
            }
            label="Коэффициент"
            name="rate"
            required
            type="number"
          />
          <CustomTextFieldNew
            label="Количество отгулов"
            name="amount"
            required
            type="number"
          />
        </>
      )}
      {selectedTypeOption !== "promotion" && (
        <CustomTextFieldNew
          label="Текст запроса"
          multiline
          name="request_text"
          required
          rows={3}
        />
      )}
      {selectedTypeOption === "promotion" && (
        <CustomTextFieldNew
          label="Комментарий"
          multiline
          name="comment"
          required
          rows={3}
        />
      )}
    </>
  );
};

export default LeaveApplicationFields;
