import React, {useCallback, useEffect, useRef, useState} from "react";

import CloseIcon from "@mui/icons-material/Close";
import {Box, Card, Divider, IconButton, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import { types} from "../../constants";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";


dayjs.extend(utc);


const MyPromotionView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const { myPromotionId } = useParams();
  const { myPromotion } = useSelector((state) => state.calendarReducer);
  const { setMyPromotion } = useActions();
  const ref = useRef(null);
  const isMobile = useResponsive("down", "sm");

  const handleBack = () => {
    navigate(`/schedule/my-promotions`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${myPromotionId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [myPromotionId]);

  const getMyPromotion = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${myPromotionId}/`);
      const data = response.data;
      setMyPromotion(data);
    } catch (e) {
      console.log(e);
    }
  }, [setMyPromotion, myPromotionId]);

  useEffect(() => {
    Promise.all([getMyPromotion(), getHistory()]).then(() => setLoading(false));
  }, [getHistory, getMyPromotion]);

  return (
    <Card sx={{ mb: 2 }}>
      {loading && <LoadingIndicator />}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p:2
        }}
      >
        <Typography variant="subtitle2">
          Поощрение
        </Typography>
        <IconButton color="secondary" onClick={() => handleBack()} sx={{ px: 1, py:isMobile ? 1 : 0 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ p: 2 }}>
        <RowStack>
          <ColumnStack ref={ref}>
            <Typography align="left" component="h2" pb={1} variant="miniHeader">
              Общее
            </Typography>
            <InfoAboutComponent label="Тип заявки" text={types[myPromotion?.type]} />
            <InfoAboutComponent
              label="Дата создания"
              text={dayjs(myPromotion?.created_at).format("DD.MM.YYYY")}
            />
            <InfoAboutComponent
              label="Причина поощрения"
              text={myPromotion?.reason}
            />
            <InfoAboutComponent
              label="Документ-основание"
              text={myPromotion?.supporting_document}
            />
            <InfoAboutComponent label="Проект" text={myPromotion?.project?.code} />
            <InfoAboutComponent
              label="Дата начала"
              text={dayjs(myPromotion?.start_date).format("DD.MM.YYYY")}
            />
            <InfoAboutComponent
              label="Дата окончания"
              text={dayjs(myPromotion?.end_date).format("DD.MM.YYYY")}
            />
            <InfoAboutComponent label="Коэффициент" text={myPromotion?.rate} />
            <InfoAboutComponent
              label="Текст запроса"
              text={myPromotion?.request_text}
            />
            <InfoAboutComponent
              label="Количество отгулов"
              text={myPromotion?.amount}
            />
            <InfoAboutComponent label="Комментарий" text={myPromotion?.comment} />
          </ColumnStack>
          <ColumnStack
            customSpace={0.1}
            sx={{
              height:`${ref?.current?.clientHeight}px`,
              overflowY:'auto'
            }}
          >
            <Typography align="left" component="h2" pt={1} variant="miniHeader">
              История
            </Typography>
            <History history={history}/>
          </ColumnStack>
        </RowStack>
      </Box>
    </Card>
  );
};

export default MyPromotionView;
