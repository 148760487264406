import React from "react";

import {
  Box,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate} from "react-router";

import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";

const Schedule = () => {
  const { t, i18n } = useTranslation();
  const { roles } = useSelector((state) => state.authReducer);
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { label: "Мое расписание", path: "my-schedule" },
    { label: "Мои заявления", path: "my-statements" },
    { label: "Мои поощрения", path: "my-promotions" },
    ...(roles?.some(
      (role) => role === "ERP_Admins" || role === "ERP_ReportCardAdmins",
    )
      ? [{ label: "Согласование", path: "statements-approval" }]
      : []),
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [{ label: "Поощрения", path: "promotions" }]
      : []),
  ];

  // if (!tabs.some((tab) => location.pathname.endsWith(tab.path)) &&
  // 	!location.pathname.endsWith('new'))
  // {
  // 	let redirectPath = location.pathname;
  // 	if (!location.pathname.endsWith('/')) {
  // 		redirectPath += '/';
  // 	}
  // 	redirectPath += tabs[0].path;
  // 	return <Navigate to={redirectPath} />;
  // }

  return (
    <Box sx={{ pb: 2 }}>
      <TitleAndButtonComponent
        back={()=>{navigate("/");}}
        backTitle="Главная"
        title="Расписание"
      />
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Box>
  );
};

export default Schedule;
