import React, { useState } from 'react';

import {Icon} from "@iconify/react";
import { Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";

import useResponsive from "../../hook/useResponsive";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";
import InfoAboutComponent from "../InfoAboutComponent";


const Info = () => {

    const { 
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        department,
        street_address,
        work_schedule,
        communication_channels,
        startTime,
        groups,
        occupation,
        manager
    } = useSelector(
      (state) => state.authReducer,
    );

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isMobile = useResponsive("down", "sm");

  return (
    <Box pb={2}>
      <Card sx={{width:{ xs: "100%", md:"100%" }}}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle2">Подробная информация</Typography>
            {isMobile ? (
              <IconButton color="primary" sx={{ p: 0 }}>
                <Icon
                  height="20"
                  icon="material-symbols-light:edit-outline-rounded"
                  width="20"
                />
              </IconButton>
            ) : (
              <Button
                color="primary"
                onClick={() => navigate("/account/edit")}
                size="small"
                startIcon={
                  <Icon
                    height="20"
                    icon="material-symbols-light:edit-outline-rounded"
                    width="20"
                  />
                }
                variant="text"
              >
                Редактировать
              </Button>
            )}
          </Stack>
          <Divider />
          <Box p={2}>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Общие данные
                </Typography>
                <InfoAboutComponent
                  label="Имя"
                  loading={loading}
                  text={first_name}
                />
                <InfoAboutComponent
                  label="Фамилия"
                  loading={loading}
                  text={last_name}
                />
                <InfoAboutComponent
                  label="Дата рождения"
                  loading={loading}
                  text={dayjs(birth_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Обо мне"
                  loading={loading}
                  text={about_me}
                />
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  pt={4}
                  variant="miniHeader"
                >
                  Орг. структура
                </Typography>
                <InfoAboutComponent
                  label="Отдел"
                  loading={loading}
                  text={department?.name}
                />
                <InfoAboutComponent
                  label="Менеджер"
                  loading={loading}
                  text={manager?.display_name}
                />
                <InfoAboutComponent
                  label="Должность"
                  loading={loading}
                  text={occupation}
                />
              </ColumnStack>
              <ColumnStack customSpace={0.5}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Контакты
                </Typography>
                <InfoAboutComponent
                  copyButton={true}
                  label="Телефон"
                  loading={loading}
                  text={phone}
                />
                <InfoAboutComponent
                  copyButton={true}
                  label="Email"
                  loading={loading}
                  text={email}
                />
                <InfoAboutComponent
                  label="Доп. контакт"
                  loading={loading}
                  text={communication_channels}
                />
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  pt={4}
                  variant="miniHeader"
                >
                  Локация
                </Typography>
                <InfoAboutComponent
                  label="Страна"
                  loading={loading}
                  text={country}
                />
                <InfoAboutComponent
                  label="Рабочий график"
                  loading={loading}
                  text={work_schedule}
                />
                <InfoAboutComponent
                  label="Почтовый адрес"
                  loading={loading}
                  text={street_address}
                />
              </ColumnStack>
            </RowStack>
          </Box>
      </Card>
    </Box>
  );
};

export default Info;