import React, {useState} from "react";

import {Icon} from "@iconify/react";
import {Avatar, Box, Button, Card, IconButton, Tooltip, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {useSelector} from "react-redux";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router";


import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import useResponsive from "../hook/useResponsive";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "sm");

  const {
    display_name,
    first_name,
    last_name,
    email,
    phone,
    birth_date,
    about_me,
    country,
    photo,
    department,
    street_address,
    work_schedule,
    communication_channels,
    startTime,
    groups,
    occupation,  } = useSelector(
    (state) => state.authReducer,
  );

  const tabs = [
    { label: "Обзор", path: "general" },
    { label: "Договорные отношения", path: "contracts" },
  ];

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Box
      sx={{
        pb:2
      }}
    >
      <TitleAndButtonComponent
        back={()=>{navigate("/");}}
        backTitle="Главная"
        title="Профиль"
      />
      <Card
        sx={{backgroundColor:'none', border:'none'}}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          py={1}
          spacing={isMobile? 1 : 2}
          sx={{
            justifyContent: "flex-start",
            alignItems: { xs: "center", sm: "stretch" },
          }}
        >
          <Avatar
            alt="photoURL"
            src={photo}
            sx={(theme) => ({
              width: 130, height: 130,
              border:`2px solid ${theme.palette.secondary.light}`
            })}
          />
          <Stack
            direction={{ xs: "column" }}
            spacing={isMobile? 2:3}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Box>
              <Typography
                align={isMobile ? "center" : "flex-start"}
                color="text.primary"
                component="p"
                variant="h5Wide"
              >
                {first_name} {last_name}
              </Typography>
              <Typography align={isMobile ? "center" : "flex-start"} color="text.secondary" component="p" variant="body3">
                {occupation} / {department ? department?.name : `Отдел не указан`}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              {email && (
                <>
                  { isMobile ?
                    <IconButton
                      color="secondary"
                      onClick={() => copy(email)}
                      size="small"
                      sx={(theme)=>({
                        borderRadius:'90px',
                        border:`1px solid ${theme.palette.secondary.main}`,
                      })}
                    >
                      <Icon icon="material-symbols-light:stacked-email-outline-rounded" />
                    </IconButton>
                    :
                    <Tooltip title="Копировать почту">
                      <Chip
                        color="secondary"
                        icon={<Icon icon="material-symbols-light:stacked-email-outline-rounded" />}
                        label={email}
                        onClick={() => copy(email)}
                        sx={{fontSize:12}}
                        variant="outlined"
                      />
                    </Tooltip>
                  }
                </>
              )}
              {phone && (
                <>
                  {
                    isMobile ?
                      <IconButton
                        color="secondary"
                        onClick={() => copy(phone)}
                        size="small"
                        sx={(theme)=>({
                          borderRadius:'90px',
                          border:`1px solid ${theme.palette.secondary.main}`,
                        })}
                      >
                        <Icon icon="material-symbols-light:phone-enabled-outline" />
                      </IconButton>
                      :
                      <Tooltip title="Копировать телефон">
                        <Chip
                          color="secondary"
                          icon={<Icon icon="material-symbols-light:phone-enabled-outline" />}
                          label={phone}
                          onClick={() => copy(phone)}
                          sx={{fontSize: 12}}
                          variant="outlined"
                        />
                      </Tooltip>
                  }
                </>
              )}
            </Stack>

          </Stack>
        </Stack>
      </Card>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Box>
  );
};

export default Account;
