import React, { useCallback, useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Card, Divider, IconButton, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { $authHost } from "../http";
import AlertMessage from "./Alert/AlertMessage";
import AddEditFormButtonGroup from "./forms/AddEditFormButtonGroup";
import BaseAddEditForm from "./forms/BaseAddEditForm";
import LeaveApplicationFields from "./forms/formFields/LeaveApplicationFields";
import History from "./History";
import InfoAboutComponent from "./InfoAboutComponent";
import Label from "./label/Label";
import MaterialTablePagination from "./MaterialTablePagination";
import { TableCellsWrap } from "./TableCellsWrap";
import {getColorByStatus, LabelStatuses, payers} from "../constants";
import CustomTextField from "./TextFields/CustomTextField";
import PersonInfo from "./UserCard";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import ApprovalStatement from "../pages/Statements/ApprovalStatement";
import { ColumnStack, RowStack } from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";
import { scrollToTop } from "../utils/scrollToTop";


const ApproveSchedule = ({ id, admin }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const fieldsRef = useRef(null);
  const [confirmation, setConfirmation] = useState(false);
  const isMobile = useResponsive("down", "sm");
  const [history, setHistory] = useState([]);
  const ref1 = useRef(null);
  const tableId = `ApproveSchedule${id}`;
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { userId, roles } = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const navigate = useNavigate();

  const {
    setCalendarRules,
    setSelectedContact,
    setSelectedStatement,
    setErrorAlertMessage,
    resetErrorAlertMessage,
  } = useActions();

  const { calendar_rules, selectedStatement } = useSelector(
    (state) => state.calendarReducer,
  );

  const [recallConfirm, setRecallConfirm] = useState(false);
  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    resetErrorAlertMessage();
  }, [resetErrorAlertMessage]);

  const onRecall = async (data) => {
    setLoading(true);

    try {
      await $authHost.post(
        `/calendar_rules/claim/${selectedStatement?.id}/cancel/`,
        {
          comment: data.comment,
        },
      );
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setOpenForm("");
    }
  }, [isSubmitSuccessful, navigate]);

  const types = {
    vacation: "Отпуск",
    day_off: "Отгул",
    sick_leave: "Больничный",
    overtime_work_days: "Сверхурочные часы",
    fired: "Увольнение",
    working_holidays: "Работа в праздники/выходные",
    promotion: "Поощрение",
  };


  const tableColumns = [
    {
      accessorKey: "updated_at",
      header: "Последние изменения",
      accessorFn: (row) => dayjs(row.updated_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус",
      accessorFn: (row) => LabelStatuses(row.status) || "",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <TableCellsWrap>
            {renderedCellValue ? (
              <Label color={getColorByStatus(row.original.status)}>
                {renderedCellValue}
              </Label>
            ) : (
              <Label color="default">Не указано</Label>
            )}
          </TableCellsWrap>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Тип заявки",
      accessorFn: (row) => types[row.type] || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "user.display_name",
      header: "Сотрудник",
      accessorFn: (row) =>
        row.users
          .map(
            (user) =>
              user?.display_name || `${user?.first_name} ${user?.last_name}`,
          )
          .join(", "),
      Cell: ({ row }) => {
        if (Array.isArray(row.original?.users)) {
          return (
            <TableCellsWrap>
              <Stack alignItems="flex-start" direction="column" spacing={1}>
                {row.original.users.map((user) => (
                  <PersonInfo
                    firstName={user?.first_name}
                    key={uuidv4()}
                    lastName={user?.last_name}
                    photo={user?.photo}
                  />
                ))}
              </Stack>
            </TableCellsWrap>
          );
        }
      },
    },
    {
      id: "department.name",
      header: "Отдел",
      accessorFn: (row) => row.department?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата начала",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => row,
      Cell: ({ renderedCellValue }) => {
        switch (renderedCellValue.type) {
          case "fired": {
            return <TableCellsWrap>{""}</TableCellsWrap>;
          }
          default:
            return (
              <TableCellsWrap>
                {dayjs(renderedCellValue.end_date).format("DD.MM.YYYY") || ""}
              </TableCellsWrap>
            );
        }
      },
    },
    {
      id: "request_text",
      header: "Текст запроса",
      accessorFn: (row) => row.request_text || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },

    {
      accessorKey: "user_approved.display_name",
      header: "Кто решил",
      accessorFn: (row) =>
        row.user_approved?.display_name ||
        `${row.user_approved?.first_name} ${row.user_approved?.last_name}`,
      Cell: ({ row }) => (
        <TableCellsWrap>
          <PersonInfo
            firstName={row.original.user_approved?.first_name}
            lastName={row.original.user_approved?.last_name}
            photo={row.original.user_approved?.photo}
          />
        </TableCellsWrap>
      ),
    },
    {
      id: "comment",
      header: "Комментарий",
      accessorFn: (row) => row?.comment || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Дата подачи",
      accessorFn: (row) => dayjs(row.created_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleClose = () => {
    setSelectedStatement(null);
    setSelectedRow(null);
    setOpenForm("");
  };

  const handleAdd = () => {
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
    scrollToTop();
    setSelectedRow(null);
  };
  const handleOpenViewPage = (row) => {
    // setSelectedStatement(row.original.id);
    // setSelectedRow(row.original);
    // setSelectedContact(row.id);

    switch (admin) {
      case true:
        navigate(`/schedule/statements-approval/${row.original.id}`);
        break;
      default:
        navigate(`/schedule/my-statements/${row.original.id}`);
        break;
    }
  };

  const AddAction = async ({
    type,
    payer,
    start_date,
    end_date,
    user,
    request_text,
    project,
    amount,
    comment,
    rate,
    reason,
  }) => {
    resetErrorAlertMessage();
    try {
      if (type === "fired" && roles?.some((role) => role === "ERP_Admins")) {
        const newData = {
          calendar_rules_data: {
            status: "approved",
            subtype: "claim",
            type: type || null,
            start_date: dayjs(start_date).format("YYYY-MM-DD") || null,
            end_date: dayjs(start_date).format("YYYY-MM-DD") || null,
            users: [user?.source_id] || null,
            request_text: request_text || null,
          },
        };
        await $authHost.post(
          `/calendar_rules/add/?accept_to_all=false`,
          newData,
        );
      } else if (type === "promotion") {
        const newData = {
          start_date: dayjs(start_date).format("YYYY-MM-DD") || null,
          end_date: dayjs(end_date).format("YYYY-MM-DD") || null,
          amount: amount || null,
          comment: comment || null,
          rate: rate || null,
          reason: reason || null,
          project_id: project || null,
          users: [user?.source_id] || null,
          // claim_id: null,
        };
        await $authHost.post(`/calendar_rules/promotion/add/`, newData);
      } else {
        const newData = {
          type: type || null,
          payer: payer || null,
          ...((type === "working_holidays" ||
            type === "overtime_work_days") && { project_id: project || null }),
          start_date: dayjs(start_date).format("YYYY-MM-DD") || null,
          end_date: dayjs(end_date).format("YYYY-MM-DD") || null,
          request_text: request_text || null,
        };
        await $authHost.post(`/calendar_rules/me/add/`, newData);
      }

      loadData();
      setOpenForm("");
    } catch (e) {
      console.log(e);

      if (
        e?.response?.data?.detail ===
        "Error while creating claim: One or more days are not holidays"
      ) {
        setErrorAlertMessage(
          "Ошибка: Один или несколько дней из указанного периода не являются выходным или праздничным днем по производственному календарю. Выберите другой период и повторите попытку.",
        );
      }

      if (
        e?.response?.data?.detail ===
        "Error while creating claim: One or more days are not working days"
      ) {
        setErrorAlertMessage(
          "Ошибка: Один или несколько дней из указанного периода не являются рабочими днями по производственному календарю. Выберите другой период и повторите попытку.",
        );
      }

      if (
        e?.response?.data?.detail ===
        "Error while creating claim: (400, 'You have no overwork budget')"
      ) {
        setErrorAlertMessage(
          "Ошибка: Количество указанных дней превышает количество доступных отгулов в бюджете переработок.",
        );
      }

      if (
        e?.response?.data?.detail ===
        "Error while creating claim: (400, 'Cannot update claim in closed month')"
      ) {
        setErrorAlertMessage(
          "Ошибка: Невозможно создать заявление в закрытом периоде табеля.",
        );
      }
    }
  };

  const ViewAction = async (data) => {
    const { status, comment, id } = data;
    try {
      await $authHost.patch(`/calendar_rules/claim/${id}/update/`, {
        status,
        comment,
      });
      loadData();
      setOpenForm("");
    } catch (e) {
      console.log(e);
    }
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  const loadData = useCallback(async () => {
    setSelectedRow(null);
    setSelectedStatement(null);
    setOpenForm("");
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(
        id === 1 ? `/calendar_rules/me/` : `/calendar_rules/all/`,
        {
          params: {
            subtype: "claim",
            page: pagination.pageIndex + 1,
            size: pagination.pageSize,
            ...(searchText && { search: searchText }),
            ...(sortStr && { sort_by: sortStr }),
            ...columnFilters.reduce(
              (acc, column) => ({
                ...acc,
                ...{ [`${column.id}_text`]: column.value },
              }),
              {},
            ),
          },
        },
      );

      const data = response.data.items;
      setCalendarRules(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    id,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setCalendarRules,
    sorting,
    setSelectedStatement,
  ]);

  const getHistory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${selectedStatement.id}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [selectedStatement]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    getHistory();
  }, [selectedStatement, getHistory]);

  return (
    <Box>
      {/*<AlertMessage/>*/}
      { openForm === "Add" && (
        <Card sx={{ mb: 2 }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p:2
            }}
          >
            <Typography variant="subtitle2">
              Добавить
            </Typography>
            {isMobile ?
              <IconButton color="primary" onClick={handleClose} sx={{ p: 0 }}>
                <CloseIcon />
              </IconButton>
              :
              <Button
                color="primary"
                onClick={handleClose}
                size="small"
                startIcon={
                  <CloseIcon />
                }
                sx={{ px: 1, py:0 }}
              >
                Отмена
              </Button>
            }
          </Stack>
          <Divider/>
          <Box p={2}>
            <BaseAddEditForm
              AddAction={AddAction}
              ViewAction={ViewAction}
              action={openForm}
              fieldsRef={fieldsRef}
              handleClose={handleClose}
              selectedRow={selectedRow}
              setConfirmation={setConfirmation}
              setLoading={setLoading}
            >
              <LeaveApplicationFields openForm={openForm} />
              <AlertMessage />
              <AddEditFormButtonGroup
                action={openForm}
                confirmation={confirmation}
                handleClose={handleClose}
              />
            </BaseAddEditForm>
          </Box>
        </Card>
      )}
      <Card>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          customButtonAction={handleAdd}
          customButtonDisabled={selectedRow}
          customButtonTitle={!admin && "Создать заявление"}
          data={calendar_rules}
          enableHiding={true}
          id={tableId}
          initialState={
            id === 1 && {
              columnVisibility: {
                user: false,
              },
            }
          }
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>
  );
};

export default ApproveSchedule;
