import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import AlertMessage from "./Alert/AlertMessage";
import BlockFormModal from "./BlockFormModal";
import AddEditFormButtonGroup from "./forms/AddEditFormButtonGroup";
import BaseAddEditForm from "./forms/BaseAddEditForm";
import ContactsForm from "./forms/ContactsForm";
import InfoAboutComponent from "./InfoAboutComponent";
import MaterialTablePagination from "./MaterialTablePagination";
import LoadingSpinner from "./modals/loadingSpinner";
import { TableCellsWrap } from "./TableCellsWrap";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import TableActions from "./TableActions";
import {
  ButtonStack,
  ColumnStack,
  RowOptionsStack,
  RowStack,
} from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";

const Contacts = () => {
  const tableId = "contacts";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const [confirmationId, setConfirmationId] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const isMobile = useResponsive("down", "sm");

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const { setContacts, setSelectedContact } = useActions();
  const { updateFullSelectedClient } = useActions();
  const { setClientsContacts } = useActions();

  const { contacts, selectedContact } = useSelector(
    (state) => state.contactsReducer,
  );

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const { clientId } = useParams();

  useEffect(() => {
    const loadClientData = () => {
      try {
        const newselectedClient = async () => {
          const response = await $authHost.get(`/clients/${clientId}/`);
          return response.data;
        };
        newselectedClient().then((data) => {
          updateFullSelectedClient(data);
        });
      } catch (e) {
        console.log(e);
      }
    };
    loadClientData();
  }, [clientId, updateFullSelectedClient]);

  const tableColumns = [
    {
      accessorKey: "person.full_name",
      header: "ФИО",
      accessorFn: (row) => row.person?.full_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "position",
      header: "Должность",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "phone",
      header: "Телефон",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={handleEditRow}
          row={row}
          setConfirmationId={setConfirmationId}
        />
      ),
    },
  ];

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setSelectedContact(row.id);
    setOpenForm("Update");
  };

  const handleDeleteRow = async (row) => {
    try {
      await $authHost
        .delete(`/contacts/${row?.source_id}/delete/`)
        .then((response) => {
          loadData();
          setConfirmationId(null);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAdd = () => {
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
    setSelectedRow(null);
  };

  const handleOpenViewPage = (row) => {
    setSelectedRow(row.original);
    setSelectedContact(row.original.id);

    openForm !== "View" && setOpenForm("View");
  };

  const UpdateAction = async (data) => {
    try {
      const updatePerson = async () => {
        await $authHost.put(`/persons/${selectedContact.person_id}/update/`, {
          full_name: data.full_name,
        });
      };
      const updateContact = async () => {
        let contact_data = data;
        contact_data["person_id"] = selectedContact.person_id;
        contact_data["client_id"] = selectedClient.source_id;
        delete contact_data.full_name;
        return await $authHost.put(
          `/contacts/${selectedContact.source_id}/update/`,
          contact_data,
        );
      };
      updatePerson().then(() => {
        updateContact().then((data) => {
          //console.log(data);
          setOpenForm("");
          loadData();
        });
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const AddAction = async (data) => {
    try {
      const newPerson = async () => {
        const response = await $authHost.post(`/persons/add/`, {
          full_name: data.full_name,
        });
        return response.data.source_id;
      };
      const newContact = async (person_id) => {
        let contact_data = data;
        contact_data["person_id"] = person_id;
        contact_data["client_id"] = selectedClient.source_id;
        delete contact_data.full_name;
        const response = await $authHost.post(`/contacts/add/`, contact_data);
        return response.data;
      };
      newPerson().then((data) => {
        newContact(data).then((data) => {
          // console.log(data);
          setOpenForm("");
          loadData();
        });
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/contacts/all/`, {
        params: {
          client_id: selectedClient?.source_id,
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      const data = response.data.items;
      setContacts(data);
      setClientsContacts(response.data?.total);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    selectedClient?.source_id,
    setClientsContacts,
    setContacts,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!openForm && selectedContact) {
      setSelectedContact(null);
      // resetSandboxFieldForm()
    }
  }, [openForm, selectedContact, setSelectedContact]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <>
        {openForm && openForm !== "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ mb: 2 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography variant="subtitle2">
                    {openForm === "Add" ? "Добавить" : "Редактировать"}
                  </Typography>
                  {isMobile ? (
                    <IconButton
                      color="primary"
                      onClick={() => setOpenForm("")}
                      sx={{ p: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={() => setOpenForm("")}
                      sx={{ px: 1, py: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
                <Divider />
                <Box p={2}>
                  <BaseAddEditForm
                    AddAction={AddAction}
                    UpdateAction={UpdateAction}
                    action={openForm}
                    handleClose={() => setOpenForm("")}
                    resetValue={{
                      type: "",
                      position: "",
                      phone: "",
                      email: "",
                    }}
                    selectedRow={selectedRow}
                    setConfirmation={setConfirmation}
                    setLoading={setLoading}
                  >
                    <ContactsForm />
                    <AddEditFormButtonGroup
                      action={openForm}
                      confirmation={confirmation}
                      handleClose={() => setOpenForm("")}
                    />
                  </BaseAddEditForm>
                </Box>
                <AlertMessage />
              </Card>
            </Grid>
          </Grid>
        )}
        {openForm === "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ mb: 2 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography variant="subtitle2">Контакт</Typography>
                  {isMobile ? (
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() => setOpenForm("Update")}
                        sx={{ p: 1 }}
                      >
                        <Icon
                          height="20"
                          icon="material-symbols-light:edit-outline-rounded"
                          width="20"
                        />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => setOpenForm("")}
                        sx={{ p: 1 }}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box>
                      <Button
                        color="primary"
                        onClick={() => setOpenForm("Update")}
                        size="small"
                        startIcon={
                          <Icon
                            height="20"
                            icon="material-symbols-light:edit-outline-rounded"
                            width="20"
                          />
                        }
                        variant="text"
                      >
                        Редактировать
                      </Button>
                      <IconButton
                        color="secondary"
                        onClick={() => setOpenForm("")}
                        sx={{ px: 1, py: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Stack>
                <Divider />
                <Box p={2}>
                  <RowStack>
                    <ColumnStack>
                      <Typography
                        align="left"
                        component="h2"
                        pb={1}
                        variant="miniHeader"
                      >
                        Общее
                      </Typography>
                      <InfoAboutComponent
                        label="ФИО"
                        loading={loading}
                        text={selectedRow?.full_name}
                      />
                      <InfoAboutComponent
                        label="Должность"
                        loading={loading}
                        text={selectedRow?.position}
                      />
                      <InfoAboutComponent
                        label="Комментарий"
                        loading={loading}
                        text={selectedRow?.notes}
                      />
                    </ColumnStack>
                    <ColumnStack>
                      <Typography
                        align="left"
                        component="h2"
                        pb={1}
                        variant="miniHeader"
                      >
                        Связаться
                      </Typography>
                      <InfoAboutComponent
                        copyButton={true}
                        label="Телефон"
                        loading={loading}
                        text={selectedRow?.phone}
                      />
                      <InfoAboutComponent
                        copyButton={true}
                        label="Email"
                        loading={loading}
                        text={selectedRow?.email}
                      />
                      <InfoAboutComponent
                        label="Дополнительноя коммуникация"
                        loading={loading}
                        text={selectedRow?.additional_communication}
                      />
                    </ColumnStack>
                  </RowStack>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
        <Card>
          <MaterialTablePagination
            columnFilters={columnFilters}
            columns={tableColumns}
            customButtonAction={handleAdd}
            customButtonDisabled={!selectedClient}
            customButtonTitle="Добавить контакт"
            data={contacts}
            id={tableId}
            loading={loading}
            muiTableBodyCellProps={({ row }) => ({
              onClick: () => {
                handleOpenViewPage(row);
              },
              sx: {
                cursor: "pointer",
              },
            })}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={onGlobalFilterChange}
            onSortingChange={setSorting}
            pageCount={pageCount}
            pagination={pagination}
            rowCount={rowCount}
            search={searchText}
            setPagination={setPagination}
            sorting={sorting}
          />
        </Card>
        <BlockFormModal
          title="Вы не завершили действие внутри таблицы"
          when={!!confirmationId}
        />
      </>
    </>
  );
};

export default Contacts;
