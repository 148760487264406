import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  budgets: [],
  budget: {},
  selectedBudget: null,
};

const BudgetsSlice = createSlice({
  name: "budgets",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setSelectedBudget(state, { payload }) {
      state.selectedBudget = payload;
    },
    setBudgets(state, { payload }) {
      state.budgets = payload;
    },
    setBudget(state, { payload }) {
      state.budget = payload;
    },
  },
});

export const { actions: budgetsActions, reducer: budgetsReducer } =
  BudgetsSlice;
export default BudgetsSlice.reducer;
