import React, {useCallback, useEffect, useRef, useState} from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";

const statusOptions = ["Доступ настроен"];


const EmploymentView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const { employmentId } = useParams();
  const { employment } = useSelector((state) => state.contractsReducer);
  const { setEmployment } = useActions();
  const ref2 = useRef(null);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (cancelConfirm) {
        await $authHost.post(`/employments/${employmentId}/cancel`, {
          comment: data.comment,
        });
      } else {
        await $authHost.put(`/employments/${employmentId}/update/`, {
          status: data.status,
        });
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/employee-relations/employments");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/employee-relations/employments`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/employments/history/all/?employment_id=${employmentId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [employmentId]);

  const getEmployment = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/employments/${employmentId}/`);
      const data = response.data;
      setEmployment(data);
    } catch (e) {
      console.log(e);
    }
  }, [setEmployment, employmentId]);

  useEffect(() => {
    Promise.all([getEmployment(), getHistory()]).then(() => setLoading(false));
  }, [getHistory, getEmployment]);

  return (
    <Card sx={{ mb:2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p:2
        }}
      >
        <Typography variant="subtitle2">
          Трудоустройство ({employment?.employee})
        </Typography>
        <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider/>
      <Box sx={{ p: 2 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack customSpace={0.5} ref={ref2}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Статус"
                  text={employment?.status !== "Отозвано"
                    ? "Действующее"
                    : "Отозванное"}
                />
                <InfoAboutComponent
                  label="Дата создания"
                  text={dayjs(employment?.created_at).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Сотрудник"
                  text={employment?.employee}
                />
                <InfoAboutComponent
                  label="Должность"
                  text={employment?.occupation}
                />
                <InfoAboutComponent label="Email" text={employment?.email} />
                <InfoAboutComponent
                  label="Дата выхода на работу"
                  text={dayjs(employment?.start_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Страна трудоустройства"
                  text={employment?.country}
                />
                <InfoAboutComponent
                  label="Юр. лицо"
                  text={employment?.company_name}
                />
                <InfoAboutComponent
                  label="Форма трудоустройства"
                  text={employment?.employment_type}
                />
              </ColumnStack>
              <ColumnStack
                customSpace={0.1}
                sx={{
                  height:`${ref2?.current?.clientHeight}px`,
                  overflowY:'auto'
                }}
              >
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  История
                </Typography>
                <History history={history} />
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} pt={2} variant="miniHeader">
                  Действия
                </Typography>
                {employment?.status === "Новое" && !cancelConfirm && (
                  <CustomAutocompleteNew
                    label="Статус"
                    name="status"
                    options={statusOptions}
                    required
                    sx={{ mb:2 }}
                  />
                )}
                {(employment?.status === "Новое" ||
                  employment?.status === "Доступ настроен") &&
                  cancelConfirm && (
                    <CustomTextField
                      label="Комментарий"
                      multiline
                      name="comment"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                      }}
                      rows={3}
                      size="small"
                      sx={{ width: "100%", mb:0 }}
                    />
                  )
                }

                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                  sx={{width:'100%'}}
                >
                  {cancelConfirm ? (
                    <>
                      <Typography
                        sx={{
                          width:'100%',
                          overflow:'visible',
                          whiteSpace: "normal",
                        }}
                        variant="body2"
                      >
                        Вы уверены, что хотите аннулировать заявление?
                      </Typography>
                      <Button
                        color="primary" onClick={() => setCancelConfirm(false)}
                        size="small"
                        variant="contained"
                      >
                        Нет
                      </Button>
                      <Button color="primary" size="small" type="submit" variant="text">
                        Да
                      </Button>
                    </>
                  ) : (
                    <>
                      {employment?.status === "Новое" && (
                        <Button color="primary" size="small" type="submit" variant="contained">
                          Сохранить
                        </Button>
                      )}
                      {(employment?.status === "Новое" ||
                        employment?.status === "Доступ настроен") && (
                        <Tooltip title="Трудоустройство может быть отозвано, если оно в статусе “Новое” или “Доступ настроен”.">
                          <Button
                            disabled={
                              employment?.status !== "Новое" &&
                              employment?.status !== "Доступ настроен"
                            }
                            onClick={() => setCancelConfirm(true)}
                            size="small"
                            sx={{
                              "&.Mui-disabled": {
                                pointerEvents: "auto",
                              },
                            }}
                            variant="outlined"
                          >
                            Отозвать
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Stack>
              </ColumnStack>
            </RowStack>
          </form>
        </FormProvider>
      </Box>
    </Card>
  );
};

export default EmploymentView;
