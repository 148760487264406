import React, { useCallback, useEffect } from "react";

import { Box, Card, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router";

import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import { useActions } from "../hook/useActions";
import { $authHost } from "../http";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";
import { TitleStack } from "../theme/standarts_styles";

const ClientsViewPage = () => {
  const { setClientsContacts } = useActions();
  const navigate = useNavigate();
  const { selectedClient } = useSelector((state) => state.clientsReducer);
  const { amountClientContact } = useSelector((state) => state.contactsReducer);

  const location = useLocation();

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get(
          `/contacts/all/?client_id=${selectedClient?.source_id}&page=1&size=50`,
        );
        return response.data.items;
      };
      newChannelList().then((data) => {
        setClientsContacts(data.length);
      });
    } catch (e) {
      console.log(e);
    }
  }, [selectedClient?.source_id, setClientsContacts]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleBack = () => {
    navigate("/clients");
  };

  const tabs = [
    { label: "Обзор", path: "general" },
    { label: "Контактные лица", path: "contacts", count: amountClientContact },
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (
    !tabs.some((tab) => location.pathname.endsWith(tab.path)) &&
    !location.pathname.endsWith("new")
  ) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Box>
      <TitleAndButtonComponent
        back={handleBack}
        backTitle="Вернуться к списку"
        title={`Клиент ${selectedClient?.name}`}
      />
        <RemoteDynamicTabs tabs={tabs} />
        <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Box>
  );
};

export default ClientsViewPage;
