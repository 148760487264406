import React, { useEffect, useState } from "react";

import { AlertTitle } from "@mui/lab";
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { jwtDecode } from "jwt-decode";

import { blackLogo } from "../../../constants";
import { $host } from "../../../http";

const LoginSuccess = () => {
  const [status, setStatus] = useState("progress");

  useEffect(() => {
    const paramsString = document.location.search;
    const searchParams = new URLSearchParams(paramsString);

    const codeAuth = searchParams.get("code");
    const sessionState = searchParams.get("session_state");

    if (codeAuth && sessionState) {
      getToken(codeAuth, sessionState);
    }
  }, []);

  const getToken = async (codeAuth, sessionState) => {
    try {
      const response = await $host.get(
        `/auth/callback?code=${codeAuth}&session_state=${sessionState}`,
        {
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        },
      );

      const {
        data: { access_token, refresh_token, id_token },
        headers: { date },
      } = response;

      const decodedToken = jwtDecode(access_token);
      const tokenLifetime =
        decodedToken.exp * 1000 - (Date.parse(date) || Date.now());

      const decodedIdToken = jwtDecode(id_token);
      const idTokenLifetime =
        decodedIdToken.exp * 1000 - (Date.parse(date) || Date.now());

      const channel = new BroadcastChannel("login-data");
      channel.postMessage({
        token: access_token,
        refreshToken: refresh_token,
        tokenLifetime: tokenLifetime,
        id_token: id_token,
        idTokenLifetime: idTokenLifetime,
      });

      setStatus("success");

      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (e) {
      console.log(e);
      setStatus("error");
    }
  };

  if (status === "success") {
    return (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
          height: '100%',
          background: 'background.default'
        }}
      >
        <Box
          component="img"
          src={blackLogo}
          sx={{
            height: { xs: 14, sm: 14, md: 18, lg: 20, xl: 20 },
          }}
        />
        <div>
          <Typography 
            align="center" 
            component="p"
            mb={2}
            variant="h4Wide"
          >
            Single Sign-On
          </Typography>
          <Alert
            severity="success"
            sx={{
              boxShadow: "0px 5px 10px 2px rgba(34, 60, 80, 0.2)",
              width: {
                xs: "80vw",
                sm: "80vw",
                md: "50vw",
                lg: "40vw",
                xl: "30vw",
              },
            }}
          >
            <AlertTitle>Успешно</AlertTitle>
            <Typography
              sx={{
                width:'100%',
                overflow:'visible',
                whiteSpace: "normal",
              }}
              variant="body2"
            >
              Авторизация прошла успешно. Вы будете перенаправлены на главную
              страницу.
            </Typography>
          </Alert>
        </div>
        <Typography gutterBottom mb={0} variant="body3">
          © 2023-2025, ASPEX
        </Typography>
      </Stack>
    );
  }

  if (status === "error") {
    return (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
          height: '100%',
          background: 'background.default'
        }}
      >
        <Box
          component="img"
          src={blackLogo}
          sx={{
            height: { xs: 14, sm: 14, md: 18, lg: 20, xl: 20 },
          }}
        />
        <div>
          <Typography align="center" component="p" mb={2} variant="h4Wide">
            Single Sign-On
          </Typography>
          <Alert
            severity="error"
            sx={{
              boxShadow: "0px 5px 10px 2px rgba(34, 60, 80, 0.2)",
              width: {
                xs: "80vw",
                sm: "80vw",
                md: "50vw",
                lg: "40vw",
                xl: "30vw",
              },
            }}
          >
            <AlertTitle>Ошибка</AlertTitle>
            <Typography
              sx={{
                width:'100%',
                overflow:'visible',
                whiteSpace: "normal",
              }}
              variant="body2"
            >
              Произошла ошибка. Пожалуйста, попробуйте еще раз.
            </Typography>

          </Alert>
        </div>
        <Typography gutterBottom mb={0} variant="body3">
          © 2023-2025, ASPEX
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={3}
      sx={{
        justifyContent: "space-around",
        alignItems: "center",
        height: '100%',
        background: 'background.default'
      }}
    >
      <Box
        component="img"
        src={blackLogo}
        sx={{
          height: { xs: 14, sm: 14, md: 18, lg: 20, xl: 20 },
        }}
      />
      <div>
        <Typography align="center" component="p" mb={2} variant="h4Wide">
          Single Sign-On
        </Typography>
        <Alert
          severity="info"
          sx={{
            boxShadow: "0px 5px 10px 2px rgba(34, 60, 80, 0.2)",
            width: {
              xs: "80vw",
              sm: "80vw",
              md: "50vw",
              lg: "40vw",
              xl: "30vw",
            },
          }}
        >
          <AlertTitle>Уведомление</AlertTitle>

          <Typography
            sx={{
              width:'100%',
              overflow:'visible',
              whiteSpace: "normal",
            }}
            variant="body2"
          >
            Идет авторизация. Подождите немного.
          </Typography>
        </Alert>
      </div>
      <Typography gutterBottom mb={0} variant="body3">
        © 2023-2025, ASPEX
      </Typography>
    </Stack>
  );
};

export default LoginSuccess;
