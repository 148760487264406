import { useEffect, useState } from "react";

import { Box, Tooltip, tooltipClasses, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import ClickAwayListener from "react-click-away-listener";
import { useSelector } from "react-redux";

import { TIMESHEET_STATUSES } from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const TooltipTimesheet = (props) => {
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [loading, setLoading] = useState(props.cell);

  const { isOpenTooltipCell, tooltipCell } = useSelector(
    (state) => state.timesheetsReducer,
  );
  const { setIsOpenTooltipCell, setTooltipCell } = useActions();

  const handleTooltipClose = () => {
    setIsOpenTooltipCell(false);
    setTooltipCell(null);
  };

  const user_id = props.data.original.user_id;
  const cell_id = props.cell.id;
  const date = `${props.data.original.year}-${props.data.original.month}-${props.cell.id.split(".")[1]}`;
  const cell_value = props.data.original.calendar[props.cell.id.split(".")[1]];

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await $authHost
          .get(
            `/calendar_rules/claim/get_one/?search_date=${date}&user_id=${user_id}`,
          )
          .then((response) => {
            console.log(response);
            setTooltipInfo(response?.data);
          });
      } catch (e) {
        if (e.response?.data?.detail === "CalendarRules not found") {
          setTooltipInfo(cell_value);
        }
      } finally {
        setLoading(false);
      }
    };
    if (tooltipCell === cell_id && isOpenTooltipCell) {
      loadData();
    }
  }, [tooltipCell, isOpenTooltipCell, cell_id, date, user_id, cell_value]);

  const renderBaseContent = (data) => (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{data.description}</Typography>
        {/*<IconButton size="small">*/}
        {/*    <CloseIcon fontSize="inherit"/>*/}
        {/*</IconButton>*/}
      </Stack>
    </>
  );

  const renderAttendanceContent = (data) => (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{data.description}</Typography>
        {/*<IconButton size="small">*/}
        {/*  <CloseIcon fontSize="inherit" />*/}
        {/*</IconButton>*/}
      </Stack>
      <br />
      <Typography variant="caption">
        ✍️ Изменено {tooltipInfo?.user_approved?.display_name} (
        {dayjs(tooltipInfo?.updated_at).format("DD.MM.YYYY HH:mm")})
      </Typography>
    </>
  );

  const renderContent = (data) => (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{data.description}</Typography>
        {/*<IconButton size="small">*/}
        {/*  <CloseIcon fontSize="inherit" />*/}
        {/*</IconButton>*/}
      </Stack>
      <br />
      <Typography variant="caption">
        🗨 Запрошено {tooltipInfo?.user.display_name} (
        {dayjs(tooltipInfo?.created_at).format("DD.MM.YYYY HH:mm")})
      </Typography>
      <br />
      <Typography variant="caption">
        Комментарий: "{tooltipInfo?.request_text}"
      </Typography>
      <br />
      <Typography variant="caption">
        ✅ Согласовано {tooltipInfo?.user_approved.display_name} (
        {dayjs(tooltipInfo?.updated_at).format("DD.MM.YYYY HH:mm")})
      </Typography>
    </>
  );

  const convertData = () => {
    const status = TIMESHEET_STATUSES.find(
      (status) => status.value === cell_value,
    );
    console.log(tooltipInfo?.type);
    if (
      tooltipInfo?.status === "recalled" ||
      tooltipInfo?.status === "cancelled" ||
      tooltipInfo?.status === "canceled" ||
      tooltipInfo?.status === "rejected"
    ) {
      return renderBaseContent(status);
    } else {
      switch (tooltipInfo?.type) {
        case "sick_leave":
        case "fired":
        case "day_off":
        case "vacation":
        case "overtime_work_days":
          return renderContent(status);
        case "working_holidays":
        case "part_time_work_day":
        case "additional_day_off":
        case "absenteeism":
          return renderAttendanceContent(status);
        default:
          return renderBaseContent(status);
      }
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      open={tooltipCell === cell_id && isOpenTooltipCell}
      {...props}
      arrow
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#f5f5f9",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      border: "1px solid #dadde9",
    },
  }));

  const value = props.cell.getValue();
  const status = TIMESHEET_STATUSES.find(
    (status) => status.value === value,
  );

  return (
    <HtmlTooltip
      title={
        <>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            {loading ? (
              <CircularProgress color="inherit" size="20px" sx={{ m: 1 }} />
            ) : (
              convertData()
            )}
          </ClickAwayListener>
        </>
      }
    >
      <Box
        sx={{
          backgroundColor: `${status?.color} !important`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "35px",
          height: "35px",
          borderRadius:'6px'
        }}
      >
        <Typography variant="body3">
          {props.element}
        </Typography>
      </Box>
    </HtmlTooltip>
  );
};

export default TooltipTimesheet;
