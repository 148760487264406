import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete, Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import CustomTextField from "../../components/TextFields/CustomTextField";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {ColumnStack, RowStack, TitleStack} from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isSameOrBefore);

const UserContractEdit = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedContract } = useSelector((state) => state.contractsReducer);
  const { contractId } = useParams();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
    values: selectedContract,
  });

  const {
    handleSubmit,
    reset,
    setError,
    control,
    setValue,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  const onSubmit = async ({ start_date, end_date, ...data }) => {
    setLoading(true);

    const newData = {
      ...data,
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      end_date: dayjs(end_date).format("YYYY-MM-DD"),
    };

    try {
      await $authHost.put(
        `/agreements/${selectedContract.id}/update/`,
        convertToNull(newData),
      );
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/employee-relations/contracts");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/employee-relations/contracts/${contractId}`);
  };

  const unlimited = useWatch({ control, name: "is_unlimited" });
  const capacity = useWatch({ control, name: "interaction_capacity" });
  const startDate = useWatch({ control, name: "start_date" });
  const endDate = useWatch({ control, name: "end_date" });
  const status = useWatch({ control, name: "status" });

  // useEffect(() => {
  //   const startDateIsBefore = dayjs(startDate).isSameOrBefore(endDate, 'day');
  //
  //   if (!startDateIsBefore) {
  //     setValue('end_date', startDate);
  //   }
  // }, [startDate]);

  return (
    <Box sx={{p:2}}>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <CustomTextField
                  label="Номер договора"
                  name="date_and_number"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 3 }}
                />
                <Controller
                  control={control}
                  name="start_date"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        control={control}
                        format="DD.MM.YYYY"
                        label="Дата заключения *"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            size: "small",
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                        sx={(theme) => ({
                          '& .MuiOutlinedInput-root':{
                            '& fieldset': {
                              borderColor: `${theme.palette.divider}`,
                            },
                          },
                          '& input': {
                            fontSize:'15px'
                          },
                          '& .MuiInputBase-inputMultiline':{
                            fontSize:'15px'
                          },
                          mb: 2, width:'100%'
                        })}
                        value={dayjs(value)}
                      />
                    </LocalizationProvider>
                  )}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="is_unlimited"
                      render={({ field: { value, ...field } }) => {
                        return (
                          <Switch checked={value} color="primary" {...field} />
                        );
                      }}
                    />
                  }
                  label="Бессрочный"
                  labelPlacement="start"
                  sx={{ ml: 1, mb: 2 }}
                />
                {!unlimited && (
                  <Controller
                    control={control}
                    name="end_date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          format="DD.MM.YYYY"
                          label="Дата окончания *"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          // minDate={startDate}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                          sx={(theme) => ({
                            '& .MuiOutlinedInput-root':{
                              '& fieldset': {
                                borderColor: `${theme.palette.divider}`,
                              },
                            },
                            '& input': {
                              fontSize:'15px'
                            },
                            '& .MuiInputBase-inputMultiline':{
                              fontSize:'15px'
                            },
                            mb: 2, width:'100%'
                          })}
                          value={dayjs(value)}
                        />
                      </LocalizationProvider>
                    )}
                    rules={{
                      required: t("main.another.requiredField"),
                    }}
                  />
                )}
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={[
                          "На согласовании",
                          "Действующий",
                          "Расторгнут",
                          "Исполнен",
                        ]}
                        renderInput={(params) => (
                          <TextField
                            label="Статус договора *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                {status === "Расторгнут" && (
                  <Controller
                    control={control}
                    name="termination_date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          format="DD.MM.YYYY"
                          label="Дата прекращения *"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                          sx={(theme) => ({
                            '& .MuiOutlinedInput-root':{
                              '& fieldset': {
                                borderColor: `${theme.palette.divider}`,
                              },
                            },
                            '& input': {
                              fontSize:'15px'
                            },
                            '& .MuiInputBase-inputMultiline':{
                              fontSize:'15px'
                            },
                            mb: 2, width:'100%'
                          })}
                          value={dayjs(value)}
                        />
                      </LocalizationProvider>
                    )}
                    rules={{
                      required: t("main.another.requiredField"),
                    }}
                  />
                )}
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Трудоустройство", "Самозанятость", "ИП"]}
                        renderInput={(params) => (
                          <TextField
                            label="Тип договора *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <CustomTextField
                  label="Юридическое лицо"
                  name="legal_entity"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <Controller
                  control={control}
                  name="production_calendar"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Россия", "Казахстан"]}
                        renderInput={(params) => (
                          <TextField
                            label="Производственный календарь *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              }
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <Controller
                  control={control}
                  name="loyalty_program"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Core", "Assotiate", "Contractor"]}
                        renderInput={(params) => (
                          <TextField
                            label="Программа лояльности *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
              </ColumnStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Условия
                </Typography>
                <Controller
                  control={control}
                  name="interaction_capacity"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Fulltime", "Fix"]}
                        renderInput={(params) => (
                          <TextField
                            label="Вид занятости *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                {capacity === "Fix" && (
                  <>
                    <CustomTextField
                      label="Объем часов Fix"
                      name="hour_capacity"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                        pattern: {
                          value: /^\d*$/,
                          message: t("main.another.wrongInput"),
                        },
                      }}
                      size="small"
                      sx={{ width: "100%", mb: 2 }}
                    />
                    <CustomTextField
                      label="Ставка в час"
                      name="hour_rate"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                        pattern: {
                          value: /^\d*$/,
                          message: t("main.another.wrongInput"),
                        },
                      }}
                      size="small"
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </>
                )}
                {capacity === "Fulltime" && (
                  <CustomTextField
                    label="Ставка в месяц"
                    name="month_rate"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                      pattern: {
                        value: /^\d*$/,
                        message: t("main.another.wrongInput"),
                      },
                    }}
                    size="small"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
                <Controller
                  control={control}
                  name="currency"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["RUB", "KZT"]}
                        renderInput={(params) => (
                          <TextField
                            label="Валюта договора *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <CustomTextField
                  label="Отпуск в год, дней"
                  name="vacation_days"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^\d*$/,
                      message: t("main.another.wrongInput"),
                    },
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <CustomTextField
                  label="Больничные в год, дней"
                  name="sick_days"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^\d*$/,
                      message: t("main.another.wrongInput"),
                    },
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
              </ColumnStack>
            </RowStack>
            {isMobile ? (
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ width: "100%", mt: 2 }}
              >
                <Button
                  disabled={loading}
                  sx={{ width: "100%" }}
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={handleReset}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  Сбросить
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} sx={{justifyContent: "flex-end"}}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={handleReset}
                  size="small"
                  variant="outlined"
                >
                  Сбросить
                </Button>
                <Button
                  color="primary"
                  disabled={loading}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </Button>
              </Stack>
            )}
          </form>
        </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Box>
  );
};

export default UserContractEdit;
