
export const darkLogo = "/assets/icons/navbar/main-white-logo.png";
export const lightLogo = "/assets/icons/navbar/main-blue-logo.png";
export const whiteLogo = "/assets/icons/navbar/main_logo_white_2.png";
export const blackLogo = "/assets/icons/navbar/main_logo.png";
export const erpLogo = "/assets/icons/navbar/erp_log.png";
export const eLogo = "/assets/icons/navbar/e_logo.png";
export const asLogo = "/assets/icons/asp_logo.svg";

export const desiredLocations = [
  // искомые локации
  "/primary-registration",
  "/new-eula",
  "/reset-password",
  "/change-password",
];
export const acceptFileType = ["image/jpeg", "image/png"];
export const acceptFileTypeXL = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
export const APP_BAR_MOBILE = 50;
export const HEADER_MOBILE = 50;
export const APP_BAR_DESKTOP = 58;
export const HEADER_DESKTOP = 58;
export const NAV_WIDTH = 215;
export const NAV_WIDTH_CLOSE = 64;
export const currencies2 = ["USD", "EUR", "BTC", "JPY", "KZT"];

export const CURRENCIES = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export const AspexIcon = (props) => (
  <svg
    fill="none"
    height={props.height}
    viewBox="0 0 1052 149"
    width={props.width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M695.233 92.7128V56.3622H850.229V92.7128H695.233Z" fill={props.color} />
    <path
      clipRule="evenodd"
      d="M443.103 46.8436H395.51V46.244C395.51 41.4472 394.16 37.7747 391.462 35.3763C388.764 32.9779 384.642 31.7787 379.246 31.7787H322.284C312.765 31.7787 306.544 32.6031 303.471 34.327C300.398 36.0508 298.899 38.9739 298.899 43.246C298.899 47.4432 300.249 50.4412 303.022 52.3149C305.795 54.1886 310.816 55.3878 318.236 55.9125L399.557 57.1117C418.069 57.5614 431.186 61.3089 438.756 68.3541C446.326 75.3994 450.148 87.3913 450.148 104.405C450.148 114.523 448.724 122.318 445.951 127.864C443.178 133.41 438.456 138.132 431.86 142.03C427.513 144.503 421.592 146.302 414.172 147.351C406.677 148.4 395.884 149 381.794 149H315.913C288.856 149 270.793 146.302 261.724 140.831C252.581 135.359 248.009 125.241 248.009 110.401C248.009 109.847 248.042 109.022 248.087 107.926C248.109 107.391 248.134 106.792 248.158 106.129C248.216 104.584 248.36 102.819 248.526 100.804C248.577 100.18 248.63 99.532 248.683 98.8587L297.475 98.7837V101.407C297.475 106.878 298.974 110.551 301.897 112.5C304.895 114.448 311.116 115.423 320.56 115.423H382.618C388.689 115.423 393.036 114.523 395.659 112.649C398.283 110.776 399.557 107.703 399.557 103.431C399.557 99.0835 398.058 95.9356 395.06 94.0619C392.062 92.1881 385.616 90.914 375.648 90.2395L298.075 88.6655C278.663 88.1408 265.547 84.8431 258.651 78.6972C251.831 72.5513 248.383 61.3838 248.383 45.2696C248.383 36.9502 249.283 30.0548 251.082 24.6585C252.88 19.2621 255.803 14.7651 259.776 11.1675C264.497 7.12024 270.643 4.27215 278.213 2.54831C285.783 0.824465 298.225 0 315.388 0H390.788C409.45 0 422.941 3.07295 431.186 9.21883C439.43 15.3647 443.552 25.2581 443.552 39.0488C443.552 39.9482 443.552 40.9225 443.477 41.9718C443.477 42.7963 443.328 44.5201 443.103 46.8436ZM119.095 36.3506L88.3657 87.916H150.499L119.095 36.3506ZM183.852 147.201L168.187 122.918H69.6283L53.8888 147.201H0L88.3657 1.57395H151.398L238.115 147.201H183.852Z"
      fill={props.color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M658.433 98.4839C651.013 106.728 638.571 110.851 620.958 110.851H528.545V147.126H479.003V1.57395H603.12C614.138 1.57395 622.907 2.02364 629.503 2.99799C636.023 3.97233 641.644 5.47134 646.216 7.64488C654.386 11.4673 660.307 17.2384 664.054 24.8084C667.727 32.3783 669.601 42.8712 669.601 56.2123C669.451 76.1489 665.778 90.2395 658.433 98.4839ZM601.396 37.8496C607.617 37.8496 612.114 39.1987 614.962 41.8969C617.735 44.5951 619.159 48.9422 619.234 54.8632C619.234 62.2832 617.885 67.4548 615.262 70.3778C612.639 73.3008 607.992 74.7249 601.396 74.7249H528.62V37.8496H601.396Z"
      fill={props.color}
      fillRule="evenodd"
    />
    <path
      d="M695.233 147.576V146.901V129.063V109.052H864.076L902.619 73.3008L866.167 37.8496H695.233V1.49899L890.702 1.6489L940.993 52.6897L991.734 1.6489H1051.32L979.892 73.3008L1051.32 147.059L993.833 147.201L940.919 96.1604L887.404 147.059V147.576H695.233Z"
      fill={props.color}
    />
  </svg>
);

export const MONTHES = [
  { value: 1, name: "Январь", quarter: 1, days: 31 },
  { value: 2, name: "Февраль", quarter: 1, days: 28 },
  { value: 3, name: "Март", quarter: 1, days: 31 },
  { value: 4, name: "Апрель", quarter: 2, days: 30 },
  { value: 5, name: "Май", quarter: 2, days: 31 },
  { value: 6, name: "Июнь", quarter: 2, days: 30 },
  { value: 7, name: "Июль", quarter: 3, days: 31 },
  { value: 8, name: "Август", quarter: 3, days: 31 },
  { value: 9, name: "Сентябрь", quarter: 3, days: 30 },
  { value: 10, name: "Октябрь", quarter: 4, days: 31 },
  { value: 11, name: "Ноябрь", quarter: 4, days: 30 },
  { value: 12, name: "Декабрь", quarter: 4, days: 31 },
];

// export const TIMESHEET_STATUSES = [
//   {value: 'Я', color: '#bdd7ee', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
//   {value: 'ЯП', color: '#bdd7ee', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
//   {value: 'ЯН', color: '#bdd7ee', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
//   {value: 'В', color: '#e2efdb', editing: true, options: ['В', 'РП']},
//   {value: 'ОТ', color: '#a9d090', editing: false},
//   {value: 'ОП', color: '#a9d090', editing: false},
//   {value: 'БЛ', color: '#cfbce9', editing: false},
//   {value: 'РП', color: '#d9d9d9', editing: true, options: ['В', 'РП']},
//   {value: 'С', color: '#cfbce9', editing: false},
//   {
//     value: 'НО',
//     color: '#d9d9d9', editing: false
//   },
//   {value: 'У', color: '#d9d9d9', editing: false},
//   {value: 'ПР', color: '#ff8584', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
// ];

export const TIMESHEET_STATUSES = [
  {
    value: "Я",
    color: "rgba(53,130,0,0.12)",
    colorSvg: "233582001f",
    editing: true,
    options: ["Я", "ЯП", "ЯН", "ПР"],
    description: "Явка, полный рабочий день",
  },
  {
    value: "ЯП",
    color: "rgba(53,130,0,0.12)",
    colorSvg: "233582001f",
    editing: true,
    options: ["Я", "ЯП", "ЯН", "ПР"],
    description: "Явка с перерывом, полный рабочий день ",
  },
  {
    value: "ЯН",
    color: "rgb(217,225,255)",
    colorSvg: "23D9E1E1",
    editing: true,
    options: ["Я", "ЯП", "ЯН", "ПР"],
    description: "Явка-неявка. Отгул за счет переработок в рабочие дни",
  },
  {
    value: "ОП",
    color: "#d9e1ff",
    colorSvg: "23d9e1ff",
    editing: false,
    description: "Отгул за счет переработок",
  },
  {
    value: "ОТ",
    color: "#b6b4ff",
    colorSvg: "23b6b4ff",
    editing: false,
    description: "Отпуск основной",
  },
  {
    value: "ОТВ",
    color: "#b6b4ff",
    colorSvg: "23b6b4ff",
    editing: false,
    description: "Отпуск в выходной день",
  },
  {
    value: "БЛ",
    color: "rgba(159,65,233,0.5)",
    colorSvg: "239f41e980",
    editing: false,
    description: "Больничный лист ",
  },
  {
    value: "В",
    color: "rgba(253,232,147,0.37)",
    colorSvg: "23fde8935e",
    editing: false,
    description: "Выходные и праздничные дни по трудовому календарю",
  },
  {
    value: "РП",
    color: "rgba(255,183,154,0.76)",
    colorSvg: "23ffb79ac2",
    editing: false,
    description: "Рабочие праздники и рабочие выходные",
  },
  {
    value: "С",
    color: "rgba(255,183,154,0.76)",
    colorSvg: "23ffb79ac2",
    editing: false,
    description: "Рабочий день + сверхурочная работа",
  },
  {
    value: "НО",
    color: "#e8e8e8",
    colorSvg: "23e8e8e8",
    editing: false,
    description:
      "Административный отпуск неоплачиваемый (отпуск без сохранения заработной платы)",
  },
  {
    value: "У",
    color: "#ffffff",
    colorSvg: "23ffffff",
    editing: false,
    description: "Уволен",
  },
  {
    value: "ПР",
    color: "#ffb0b0",
    colorSvg: "23ffb0b0",
    editing: true,
    options: ["Я", "ЯП", "ЯН", "ПР"],
    description: "Прогул без уважительной причины",
  },
];




export const types = {
  vacation: "Отпуск",
  day_off: "Отгул",
  sick_leave: "Больничный",
  overtime_work_days: "Сверхурочные часы",
  fired: "Увольнение",
  working_holidays: "Работа в праздники/выходные",
  promotion: "Поощрение",
};

export const payers = {
  company_expense: "За счет компании",
  self_expense: "За свой счет",
  overwork_expense: "За счет переработок",
};

// export const StatusesLabels = {
//   new: { label: "Новое", color: "#414bb2" },
//   approved: { label: "Согласовано", color: "#8fd14f" },
//   rejected: { label: "Отклонено", color: "#f24726" },
//   cancelled: { label: "Аннулировано", color: "#f24726" },
//   recalled: { label: "Отозвано", color: "#f24726" },
// };
//
// export const statuses = {
//   new: "Новое",
//   approved: "Согласовано",
//   rejected: "Отклонено",
//   cancelled: "Аннулировано",
//   recalled: "Отозвано",
//   draft: "Черновик",
//   paid: "Выплачено",
// };

export const LabelStatuses = (value) => {
  switch (value) {
    case "new":
    case "Новое":
      return "Новое";
    case "approved":
    case "Согласовано":
      return "Согласовано";
    case "paid":
    case "Выплачено":
      return "Выплачено";
    case "Доступ настроен":
      return "Доступ настроен";
    case "rejected":
    case "Отклонено":
      return "Отклонено";
    case "cancelled":
    case "Аннулировано":
      return "Аннулировано"
    case "recalled":
    case "Отозвано":
      return "Отозвано";
    case "draft":
    case "Черновик":
      return "Черновик";
    case "Черновик (Изменено)":
      return "Черновик (Изменено)";
    default:
      return value;
  }
}

export const getColorByStatus = (value) => {
  switch (value) {
    case "new":
    case "Новое":
      return "info";
    case "approved":
    case "Согласовано":
    case "paid":
    case "Выплачено":
    case "Доступ настроен":
      return "success";
    case "rejected":
    case "Отклонено":
      return "error";
    case "cancelled":
    case "recalled":
    case "draft":
    case "Отозвано":
    case "Черновик":
    case "Черновик (Изменено)":
    case "Аннулировано":
      return "secondary";
    default:
      return "secondary";
  }
};
