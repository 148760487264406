import React, { useCallback, useEffect, useState } from "react";

import { Box, Card } from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";

import CardComponent from "../../components/cardComponent";
import Label from "../../components/label/Label";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import PersonInfo from "../../components/UserCard";
import {getColorByStatus, LabelStatuses, types} from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { getSortingString } from "../../utils/getSortingString";

const MyPromotionsList = () => {
  const tableId = "Promotions";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setPromotions } = useActions();
  const { promotions } = useSelector((state) => state.calendarReducer);
  const { source_id, display_name } = useSelector((state) => state.authReducer);
  const [userBudget, setUserBudget] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      accessorKey: "updated_at",
      header: "Последние изменения",
      accessorFn: (row) => dayjs(row.updated_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус",
      accessorFn: (row) => LabelStatuses(row.status) || "",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <TableCellsWrap>
            {renderedCellValue ? (
              <Label color={getColorByStatus(row.original.status)}>
                {renderedCellValue}
              </Label>
            ) : (
              <Label color="default">Не указано</Label>
            )}
          </TableCellsWrap>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Тип заявления",
      accessorFn: (row) => types[row.type] || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "users.display_name",
      header: "Сотрудник",
      accessorFn: (row) =>
        row.users
          .map(
            (user) =>
              user?.display_name || `${user?.first_name} ${user?.last_name}`,
          )
          .join(", "),
      Cell: ({ row }) => {
        if (Array.isArray(row.original?.users)) {
          return (
            <TableCellsWrap>
              <Stack alignItems="flex-start" direction="column" spacing={1}>
                {row.original.users.map((user) => (
                  <PersonInfo
                    firstName={user?.first_name}
                    key={uuidv4()}
                    lastName={user?.last_name}
                    photo={user?.photo}
                  />
                ))}
              </Stack>
            </TableCellsWrap>
          );
        }
      },
    },
    {
      id: "department.name",
      header: "Отдел",
      accessorFn: (row) => row.department?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "reason",
      header: "Причина поощрения",
      accessorFn: (row) => row.reason || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "supporting_document",
      header: "Документ-основание",
      accessorFn: (row) => row.supporting_document || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "project.code",
      header: "Проект",
      accessorFn: (row) => row.project?.code || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата начала",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => row,
      Cell: ({ renderedCellValue }) => {
        switch (renderedCellValue.type) {
          case "fired": {
            return <TableCellsWrap>{""}</TableCellsWrap>;
          }
          default:
            return (
              <TableCellsWrap>
                {dayjs(renderedCellValue.end_date).format("DD.MM.YYYY") || ""}
              </TableCellsWrap>
            );
        }
      },
    },
    {
      id: "rate",
      header: "Коэффициент",
      accessorFn: (row) => row.rate || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "request_text",
      header: "Текст запроса",
      accessorFn: (row) => row.request_text || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "overwork_deposit",
      header: "Начисления",
      accessorFn: (row) => row.overwork_deposit || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "overwork_withdrawal",
      header: "Списания",
      accessorFn: (row) => row.overwork_withdrawal || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user_approved.display_name",
      header: "Кто решил",
      accessorFn: (row) =>
        row.user_approved?.display_name ||
        `${row.user_approved?.first_name} ${row.user_approved?.last_name}`,
      Cell: ({ row }) => (
        <TableCellsWrap>
          <PersonInfo
            firstName={row.original.user_approved?.first_name}
            lastName={row.original.user_approved?.last_name}
            photo={row.original.user_approved?.photo}
          />
        </TableCellsWrap>
      ),
    },
    {
      id: "overwork_budget",
      header: "Бюджет переработок",
      accessorFn: (row) => row?.overwork_budget || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Дата подачи",
      accessorFn: (row) => dayjs(row.created_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/schedule/my-promotions/${id}`);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/calendar_rules/promotion/me/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
      setPromotions(response.data.items);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setPromotions,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const getUserBudget = useCallback(async () => {
    try {
      const response = await $authHost.get(
        `/calendar_rules/promotion/overwork_budget/`,
        {
          params: {
            user_id: source_id,
          },
        },
      );
      setUserBudget(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [source_id, setUserBudget]);

  useEffect(() => {
    getUserBudget();
  }, [getUserBudget]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <Box>
      <Card sx={{ width: "100%", mb: 2 }}>
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
          }}
          useFlexGap
        >
          <CardComponent
            color="text.primary"
            currentValue={userBudget ? userBudget?.overwork_deposit : 0}
            description="Сформулировать описание показателя"
            divider={3}
            label="Начисления"
          />
          <CardComponent
            color="text.primary"
            currentValue={userBudget ? userBudget?.overwork_withdrawal : 0}
            description="Сформулировать описание показателя"
            divider={3}
            label="Списания"
          />
          <CardComponent
            color="text.primary"
            currentValue={userBudget ? userBudget?.overwork_budget : 0}
            description="Сформулировать описание показателя"
            divider={3}
            label="Бюджет переработок"
          />
        </Stack>
      </Card>

      <Card>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={promotions}
          enableHiding={true}
          id={tableId}
          initialState={{
            columnVisibility: {
              "project.code": false,
            },
          }}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>

  );
};

export default MyPromotionsList;
