import React from 'react';

import { Box, LinearProgress, Tooltip, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";

const CardComponent = ({currentValue, totalValue = false, label, description, divider}) => {
    const progress = (currentValue / totalValue) * 100;
    return (
      <Box sx={(theme)=>({
          width: {xs:'100%',sm:'100%', md:`calc(100% / ${divider})`, lg:`calc(100% / ${divider})`, xl:`calc(100% / ${divider})`},
          px: {xs: 1, sm: 1.5, md: 2, lg: 3, xl: 3},
          py: {xs: 0.5, sm: 1, md: 1.5, lg:2, xl: 2},
          border:'none',
      })}>
        <Stack
          alignItems="flex-start"
          direction="column"
          justifyContent="flex-start"
          spacing={{xs:1, sm: 1, md: 1}}
          sx={{ width:'100%', whiteSpace: "nowrap", overflow: "hidden", textOverflow:'ellipsis'}}
        >
          <Typography color="text.primary" gutterBottom sx={{ width:'100%',}} variant="body2">
            {label}
          </Typography>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                width:'100%',
              }}
            >
              {totalValue !== false ?
                <div>
                  <Typography component="span" variant="h3">{currentValue}</Typography>
                  <Typography color="text.secondary" component="span" sx={{fontWeight:'normal'}} variant="h3">{`/`}</Typography>
                  <Typography color="text.secondary" component="span" sx={{fontWeight:'normal'}} variant="h3">{totalValue}</Typography>
                </div>
                :
                <>
                  <Typography  variant="h3">{currentValue}</Typography>
                </>
              }
              {totalValue !== false &&
                <LinearProgress
                  color="primary"
                  sx={{height: 6, width: "50%", borderRadius: 30}}
                  value={progress > 100 ? 100 : progress}
                  variant="determinate"
                />
              }
              <Tooltip arrow title={description}>
                <Box sx={{ width:'70%' }}>
                  <Typography align="center" color="text.secondary" gutterBottom  sx={{overflow: 'hidden', textOverflow:'ellipsis', width:'100%', display:'block'}} variant="body3">
                    {description}
                  </Typography>
                </Box>
              </Tooltip>
            </Stack>
        </Stack>
      </Box>

    );
};

export default CardComponent;