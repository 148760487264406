import React, {useEffect, useState} from 'react';

import {Autocomplete, Box, InputAdornment, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {CURRENCIES} from "../../constants";
import useResponsive from "../../hook/useResponsive";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import SkeletonInput from "../SkeletonInput";
import CustomTextField from "../TextFields/CustomTextField";


const BudgetsForm = () => {
  const { t } = useTranslation();
  const isMobile = useResponsive("down", "sm");
  const [loading, setLoading] = useState(false);
  const [currencyAdornmentLabel, setCurrencyAdornmentLabel] = useState("$");

  const { project } = useSelector((state) => state.projectsReducer);
  
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (project?.currency) {
      const currency = CURRENCIES.find((v) => v.value === project?.currency);
      currency && setCurrencyAdornmentLabel(currency.label);
    }
  }, [project]);

  const typesList = ["Внутренний", "Коммерческий", "Риски", "НЗ"];


  return (
    <Box>
      <RowStack>
        <ColumnStack>
          <Typography align="left" component="h2" pb={1} variant="miniHeader">
            Общее
          </Typography>
          <SkeletonInput loading={loading}>
            <Controller
              control={control}
              name="date"
              render={({
                         field: { onChange, value },
                         fieldState: { error },
                       }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    control={control}
                    disabled
                    label="Дата"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: "small",
                      },
                    }}
                    sx={{
                      width: "100%",
                      mb: 2,
                    }}
                    value={dayjs(value)}
                  />
                </LocalizationProvider>
              )}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
            />
          </SkeletonInput>
          <SkeletonInput loading={loading}>
            <CustomTextField
              helperText={errors?.name?.message}
              label="Имя бюджета"
              name="name"
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />
            {/*<TextField*/}
            {/*  label="Имя бюджета"*/}
            {/*  {...register("name", {*/}
            {/*    // required: 'Обязательное поле',*/}
            {/*  })}*/}
            {/*  disabled*/}
            {/*  error={!!errors?.name}*/}
            {/*  fullWidth*/}
            {/*  helperText={errors?.name?.message}*/}
            {/*  size="small"*/}
            {/*  sx={{*/}
            {/*    mb: 2,*/}
            {/*  }}*/}
            {/*/>*/}
          </SkeletonInput>
          <SkeletonInput loading={loading}>
            <Controller
              control={control}
              name="type"
              render={({
                         field: { onChange, value,  ...field },
                         fieldState: { error },
                       }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(e, value) => {
                      console.log("value ", value);
                      onChange(value);
                    }}
                    options={typesList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message}
                        label="Вид"
                      />
                    )}
                    size="small"
                    sx={{
                      mb: 2,
                      width: "100%",
                    }}
                    value={value || null}
                  />
                );
              }}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
            />
          </SkeletonInput>
          <SkeletonInput height={150} loading={loading}>
            <CustomTextField
              helperText={errors?.name?.message}
              label="Обоснование"
              multiline
              name="justification"
              rows={4}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: isMobile ? 1 : 3 }}
            />
            {/*<TextField*/}
            {/*  label="Обоснование"*/}
            {/*  {...register("justification")}*/}
            {/*  error={!!errors?.notes}*/}
            {/*  fullWidth*/}
            {/*  helperText={errors?.notes?.message}*/}
            {/*  multiline*/}
            {/*  rows={4}*/}
            {/*  size="small"*/}
            {/*  sx={{*/}
            {/*    mb: 2,*/}
            {/*  }}*/}
            {/*/>*/}
          </SkeletonInput>
        </ColumnStack>
        <ColumnStack>
          <Typography align="left" component="h2" pb={1} variant="miniHeader">
            Суммы
          </Typography>
          <SkeletonInput loading={loading}>
            <CustomTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencyAdornmentLabel}
                  </InputAdornment>
                ),
              }}
              error={!!errors?.currency_amount}
              fullWidth
              helperText={errors?.currency_amount?.message}
              label="Сумма в валюте"
              name="currency_amount"
              registerOptions={{
                pattern: {
                  value: /^\d*$/,
                  message: "Некорректный ввод",
                },
              }}
              size="small"
              sx={{
                mb: 2,
              }}
            />
          </SkeletonInput>
          <SkeletonInput loading={loading}>
            <CustomTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">h</InputAdornment>
                ),
              }}
              error={!!errors?.hours_amount}
              fullWidth
              helperText={errors?.hours_amount?.message}
              label="Сумма в коммерческих часах"
              name="hours_amount"
              registerOptions={{
                pattern: {
                  value: /^\d*$/,
                  message: "Некорректный ввод",
                },
              }}
              size="small"
              sx={{
                mb: 2,
              }}
            />
            {/*<TextField*/}
            {/*  label="Сумма в коммерческих часах"*/}
            {/*  {...register("hours_amount", {*/}
            {/*    // required: 'Обязательное поле',*/}
            {/*    pattern: {*/}
            {/*      value: /^\d*$/,*/}
            {/*      message: "Некорректный ввод",*/}
            {/*    },*/}
            {/*  })}*/}
            {/*  InputProps={{*/}
            {/*    startAdornment: (*/}
            {/*      <InputAdornment position="start">h</InputAdornment>*/}
            {/*    ),*/}
            {/*  }}*/}
            {/*  error={!!errors?.hours_amount}*/}
            {/*  fullWidth*/}
            {/*  helperText={errors?.hours_amount?.message}*/}
            {/*  size="small"*/}
            {/*  sx={{*/}
            {/*    mb: 2,*/}
            {/*  }}*/}
            {/*/>*/}
          </SkeletonInput>
        </ColumnStack>
      </RowStack>
    </Box>
  );
};

export default BudgetsForm;