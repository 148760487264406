import { useCallback, useEffect, useRef, useState } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  Card,
  Box,
  Grid,
  Tooltip,
  Popover,
  Typography,
  Button,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import ScheduleFilters from "./ScheduleFilters";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { EventDiv, StyledChipTime } from "../../theme/standarts_styles";
import LoadingIndicator from "../LoadingIndicator";

dayjs.extend(utc);
const utcOffset = dayjs().utcOffset();

const EventTime = ({ event }) => {
  const [TAnchorEl, setTAnchorEl] = useState(null);
  const [CAnchorEl, setCAnchorEl] = useState(null);

  const handleClickOne = (event) => {
    setTAnchorEl(event.currentTarget);
  };
  const handleClickTwo = (event) => {
    setCAnchorEl(event.currentTarget);
  };

  const handleCloseOne = () => {
    setTAnchorEl(null);
  };
  const handleCloseTwo = () => {
    setCAnchorEl(null);
  };

  const openT = Boolean(TAnchorEl);
  const openC = Boolean(CAnchorEl);

  const clickUpTimeEntries = event.extendedProps?.time_entries_clickup;
  const teamsTimeEntries = event.extendedProps?.time_entries_teams;
  const isMobile = useResponsive("down", "sm");

  return (
    <>
      {!isMobile && (
        <>
          <StyledChipTime
            color_type={"#6E70B9"}
            fontSize="12"
            label={`${(event.extendedProps?.minutes_teams / 60).toFixed(2)} ч`}
            onClick={handleClickOne}
            variant="outlined"
          />
          <StyledChipTime
            color_type={"#07A092"}
            fontSize="12"
            label={`${(event.extendedProps?.minutes_clickup / 60).toFixed(2)} ч`}
            onClick={handleClickTwo}
            sx={{ ml: 1 }}
            variant="outlined"
          />
          {teamsTimeEntries.length > 0 && (
            <Popover
              anchorEl={TAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={handleCloseOne}
              open={openT}
            >
              <Typography sx={{ p: 1 }} variant="subtitle1">
                Часы за день:
              </Typography>
              {teamsTimeEntries.map((t) => (
                <Typography key={t.title} sx={{ p: 1 }}>
                  {t.start} - {t.end} <b>{t.title}</b>:{" "}
                  <Typography component="text" sx={{ color: "text.secondary" }}>
                    {(t.duration / 3600).toFixed(2)} ч.
                  </Typography>
                </Typography>
              ))}
            </Popover>
          )}
          {clickUpTimeEntries.length > 0 && (
            <Popover
              anchorEl={CAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={handleCloseTwo}
              open={openC}
            >
              <Typography sx={{ p: 1 }} variant="subtitle1">
                Часы за день:
              </Typography>
              {clickUpTimeEntries.map((t) => (
                <Typography key={t.title} sx={{ p: 1 }}>
                  {t.start} - {t.end} <b>{t.project}</b> {t.title}:{" "}
                  <Typography component="text" sx={{ color: "text.secondary" }}>
                    {(t.duration / 3600).toFixed(2)} ч.
                  </Typography>
                </Typography>
              ))}
            </Popover>
          )}
        </>
      )}
    </>
  );
};

const MySchedule = ({ props }) => {
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const isMiniMobile = useResponsive("down", "sm");
  const isMobile = useResponsive("down", "md");
  const calendarRef = useRef(null);
  var calendarApi;

  useEffect(() => {
    const searchParams = new URLSearchParams("");

    const filterKeys = Object.keys(filterValues);
    const filterString = filterKeys.map((key) => {
      const currentFilter = filterValues[key];
      if (typeof currentFilter === "object") {
        const currentValues = Object.keys(currentFilter).map((currentKey) => {
          if (currentFilter[currentKey] === true) {
            return { name: currentKey };
          }
        });
        return { name: key, childrens: currentValues };
      } else if (currentFilter) {
        return { name: key };
      }
    });

    filterString.forEach((f) => {
      if (f && Array.isArray(f.childrens)) {
        const filterChildrens = f.childrens.filter((c) => c);
        if (filterChildrens.length > 0) {
          filterChildrens.forEach((fc) => {
            searchParams.append(f.name, fc.name);
          });
        }
      } else if (f) {
        searchParams.append(f.name, "true");
      }
    });
    setFilterParams(searchParams.toString());
  }, [filterValues]);

  const renderEventContent = useCallback(({ event }) => {
    if (event.extendedProps.type === "time") {
      return <EventTime event={event} />;
    }

    if (event.extendedProps?.source === "Teams") {
      return (
        <Tooltip title={event?.title}>
          <EventDiv color={"rgb(121,122,180)"}>
            <p
              style={{
                textOverflow: "ellipsis",
                margin: "0px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <b>{event.extendedProps?.timeStart}</b> {event?.title}
            </p>
          </EventDiv>
        </Tooltip>
      );
    }

    if (event.extendedProps?.source === "ClickUp") {
      return (
        <Tooltip title={event?.title}>
          <EventDiv color={"rgb(83,171,164)"}>
            <p
              style={{
                textOverflow: "ellipsis",
                margin: "0px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <b>{event.extendedProps?.project}</b> {event?.title}
            </p>
          </EventDiv>
        </Tooltip>
      );
    }

    return <></>;
  }, []);

  const getEvents = useCallback(
    async (fetchInfo, successCallback, failureCallback) => {
      setLoading(true);

      const startDate = fetchInfo.startStr;
      const endDate = fetchInfo.endStr;
      let response;
      // let response_backs;
      //
      // let current_month = dayjs(fetchInfo.startStr).add(8, 'day').format('MM')
      // let current_year = dayjs(fetchInfo.startStr).add(8, 'day').format('YYYY')
      // console.log(current_month," " , current_year);

      try {
        // if (admin) {
        //   response = await $authHost.get(
        //     `/calendar_entries/all/?start_date=${startDate}&end_date=${endDate}&${filterParams}`,
        //   );
        // } else {
        response = await $authHost.get(
          `/calendar_entries/me/?start_date=${startDate}&end_date=${endDate}&${filterParams}`,
        );
        // }
        // response_backs = await $authHost.get(`/report_cards/me/?month=${current_month}&year=${current_year}`);

        let myEvents = []; // Все ивенты
        const days = []; // Дни, в которых есть ивенты

        if (Array.isArray(response.data)) {
          // Создаем массив всех ивентов в нужном нам формате
          myEvents = response.data.map((e) => {
            let minutes = null;

            if (Array.isArray(e.time_entries) && e.time_entries.length > 0) {
              e.time_entries.forEach((t) => {
                minutes += t.duration / 60;
              });
            }

            return {
              title: e.name,
              start: dayjs(e.start_date).add(utcOffset, "m").format(),
              end: dayjs(e.end_date).add(utcOffset, "m").format(),
              timeStart: dayjs(e.start_date)
                .add(utcOffset, "m")
                .format("HH:mm"),
              timeEnd: dayjs(e.end_date).add(utcOffset, "m").format("HH:mm"),
              minutes: minutes,
              source: e.source,
              project: e.project_name,
              time_entries: e.time_entries,
              backgroundColor: "rgba(255,255,255,0)",
              borderColor: "rgba(255,255,255,0)",
            };
          });
        }

        // console.log(response_backs.data.calendar);

        // Добавляем в массив days дни, которые содержат ивенты
        myEvents.forEach((e) => {
          const start = dayjs(e.start).format("YYYY-MM-DD");
          const day = days.find((d) => d?.start === start);
          if (!day) {
            days.push({
              start: start,
              minutes_teams: null,
              minutes_clickup: null,
              events_teams: [],
              events_clickup: [],
              time_entries_teams: [],
              time_entries_clickup: [],
              type: "time",
            });
          }
        });

        // Добавление ивентов и минут для каждого дня из массива days
        myEvents.forEach((e) => {
          const start = dayjs(e.start).format("YYYY-MM-DD");

          days.forEach((d) => {
            if (d.start === start) {
              if (e.source === "Teams") {
                if (e.minutes) {
                  d.minutes_teams += e.minutes;
                  d.events_teams.push(e);
                  e.time_entries.forEach((t) => {
                    d.time_entries_teams.push({
                      ...t,
                      title: e.title,
                      start: dayjs(t.start).add(utcOffset, "m").format("HH:mm"),
                      end: dayjs(t.end).add(utcOffset, "m").format("HH:mm"),
                    });
                  });
                }
              }
            }

            if (e.source === "ClickUp") {
              if (e.minutes) {
                e.time_entries.forEach((t) => {
                  if (dayjs(t.start).format("YYYY-MM-DD") === d.start) {
                    d.time_entries_clickup.push({
                      ...t,
                      title: e.title,
                      project: e.project,
                      start: dayjs(t.start).add(utcOffset, "m").format("HH:mm"),
                      end: dayjs(t.end).add(utcOffset, "m").format("HH:mm"),
                    });
                    d.minutes_clickup += t.duration / 60;
                    d.events_clickup.push(e);
                  }
                });
              }
            }
          });
        });

        successCallback([...myEvents, ...days]);
        setLoading(false);
      } catch (e) {
        failureCallback(e);
        setLoading(false);
      }
    },
    [filterParams],
  );

  if (calendarRef.current !== null) calendarApi = calendarRef.current.getApi();

  function goNext() {
    calendarApi.next();
  }

  function goPrev() {
    calendarApi.prev();
  }

  function goToday() {
    calendarApi.today();
  }

  function dayGridDay() {
    calendarApi.changeView("dayGridDay");
  }

  function listWeek() {
    calendarApi.changeView("listWeek");
  }

  return (
    <Box pt={2}>
      <Card sx={{ padding: isMobile ? 1 : 2 }}>
        <Box pt={2} sx={{ width: "100%" }}>
          {isMobile && !isMiniMobile && (
            <Stack
              alignItems="center"
              direction="column"
              justifyContent="center"
              spacing={1.5}
              sx={{ width: "100%", mb: 3 }}
            >
              <Typography variant="h4">{calendarApi?.view.title}</Typography>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <ScheduleFilters setFilterValues={setFilterValues} />
                  <ButtonGroup color="inherit" variant="outlined">
                    <Button onClick={listWeek} size="small">
                      Неделя
                    </Button>
                    <Button onClick={dayGridDay} size="small">
                      День
                    </Button>
                  </ButtonGroup>
                </Grid>

                <ButtonGroup color="inherit" variant="outlined">
                  <Button onClick={goPrev} size="small">
                    <ArrowBackIos size="small" />
                  </Button>
                  <Button onClick={goToday} size="small">
                    Сегодня
                  </Button>
                  <Button onClick={goNext} size="small">
                    <ArrowForwardIos size="small" />
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          )}
          {isMiniMobile && (
            <Stack
              alignItems="center"
              direction="column"
              justifyContent="center"
              spacing={1.5}
              sx={{ width: "100%", mb: 3 }}
            >
              <Typography variant="h4">{calendarApi?.view.title}</Typography>
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={1.5}
                sx={{ width: "100%" }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <ScheduleFilters setFilterValues={setFilterValues} />
                  <ButtonGroup color="inherit" variant="outlined">
                    <Button onClick={listWeek} size="small">
                      Неделя
                    </Button>
                    <Button onClick={dayGridDay} size="small">
                      День
                    </Button>
                  </ButtonGroup>
                </Grid>

                <ButtonGroup color="inherit" variant="outlined">
                  <Button onClick={goPrev} size="small">
                    <ArrowBackIos size="small" />
                  </Button>
                  <Button onClick={goToday} size="small">
                    Сегодня
                  </Button>
                  <Button onClick={goNext} size="small">
                    <ArrowForwardIos size="small" />
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          )}
          {!isMobile && <ScheduleFilters setFilterValues={setFilterValues} />}
          <FullCalendar
            aspectRatio={isMobile ? 0.7 : 1.35}
            dayMaxEventRows={true}
            eventClassNames={({ event }) => {
              if (event.extendedProps?.type === "time") {
                return ["fc-type-time-container"];
              }
            }}
            eventContent={renderEventContent}
            events={getEvents}
            firstDay="1"
            headerToolbar={
              !isMobile
                ? {
                    start: isMobile
                      ? "dayGridDay,listWeek"
                      : "dayGridMonth,dayGridWeek",
                    center: "title",
                    end: "today prev,next",
                  }
                : false
            }
            initialView={isMobile ? "listWeek" : "dayGridMonth"}
            locale="ru"
            plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
            ref={calendarRef}
          />
        </Box>
        {loading && <LoadingIndicator />}
      </Card>
    </Box>
  );
};

export default MySchedule;
