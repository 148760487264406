import React, {useCallback, useEffect, useState} from "react";

import {Box} from "@mui/material";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router";

import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";


const ProjectsEditForm = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const tabs = [
    { label: "Общее", path: "general" },
    { label: "Бюджеты", path: "budgets" },
    // { label: 'Планирования', path: 'planning' },
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  const handleBack = () => {
    navigate("/projects");
  }

  return (
    <Box sx={{ pb:2 }}>
      <TitleAndButtonComponent
        back={handleBack}
        backTitle="Вернуться к списку"
        title="Управление проектом"
      />
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Box>
  );
};

export default ProjectsEditForm;
