import React, { useCallback, useEffect, useState } from "react";

import {Icon} from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar, Box,
  Button,
  Card, Divider,
  Grid,
  IconButton,
  TextField, Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {Controller, FormProvider, useForm, useWatch} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import AlertMessage from "../../components/Alert/AlertMessage";
import BlockFormModal from "../../components/BlockFormModal";
import CustomViewField from "../../components/CustomViewField";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import SkeletonInput from "../../components/SkeletonInput";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import TitleAndButtonComponent from "../../layouts/StandardElementsForPages/TitleAndButtonComponent";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";

const EmployeesForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [changedManager, setChangedManager] = useState(null);
  const isMobile = useResponsive("down", "sm");

  const { employeeId } = useParams();

  const { departments } = useSelector((state) => state.departmentsReducer);

  const { selectedEmployee } = useSelector((state) => state.employeesReducer);

  const { setDataSelectedEmployee, setDepartments, setErrorAlertMessage } =
    useActions();

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    reset(selectedEmployee);
  }, [reset, selectedEmployee]);

  const department = useWatch({ control, name: "department" });

  const onSubmit = async (data) => {
    setLoading(true);
    const newData = {
      occupation: data?.occupation || null,
      department_id: data.department?.id || null,
      manager_id: data.department?.manager_id || null,
    };

    try {
      await $authHost.put(
        `/users/update/${selectedEmployee.source_id}/`,
        newData,
      );
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.detail ===
          "The manager id cannot be the same as your source id"
      ) {
        setErrorAlertMessage("Пользователь не может быть менеджером для себя");
      }
    }
  };

  useEffect(() => {
    setChangedManager(getValues("department")?.manager);
  }, [department, getValues]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/org-structure/employees");
    }
  }, [isSubmitSuccessful, navigate]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/users/${employeeId}/`);

      setDataSelectedEmployee(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [employeeId, setDataSelectedEmployee]);

  const loadDeparts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get("/departments/all/");
      setDepartments(response.data.items);
    } catch (e) {
      console.log(e);
    }
  }, [setDepartments]);

  const handleBack = () => {
    navigate(`/org-structure/employees/${employeeId}`);
  };

  useEffect(() => {
    Promise.all([loadData(), loadDeparts()]).then(() => setLoading(false));
  }, [loadData, loadDeparts]);

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };

  return (
    <Box sx={{ pb: 2 }}>
      <TitleAndButtonComponent
        back={handleBack}
        backTitle="Вернуться к просмотру"
        title={`Управление сотрудником`}
        // title={`Сотрудник ${selectedEmployee?.display_name}`}
      />
      <Card
        sx={{backgroundColor:'none', border:'none'}}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          py={1}
          spacing={isMobile? 2 : 3}
          sx={{
            justifyContent: "flex-start",
            alignItems: { xs: "center", sm: "stretch" },
          }}
        >
          <Avatar
            alt="photoURL"
            src={selectedEmployee?.photo}
            sx={(theme) => ({
              width: 130, height: 130,
              border:`2px solid ${theme.palette.secondary.light}`
            })}
          />
          <Stack
            direction={{ xs: "column" }}
            spacing={isMobile?1:2}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Box>
              <Typography
                align={isMobile ? "center" : "flex-start"}
                color="text.primary"
                component="p"
                variant="h5Wide"
              >
                {selectedEmployee?.first_name} {selectedEmployee?.last_name}
              </Typography>
              <Typography align={isMobile ? "center" : "flex-start"} color="text.secondary" component="p" variant="body3">
                {selectedEmployee?.occupation} / {selectedEmployee?.department ? selectedEmployee?.department?.name : `Отдел не указан`}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              {selectedEmployee?.email && (
                <>
                  { isMobile ?
                    <IconButton
                      color="secondary"
                      onClick={() => copy(selectedEmployee?.email)}
                      size="small"
                      sx={(theme)=>({
                        borderRadius:'90px',
                        border:`1px solid ${theme.palette.secondary.main}`,
                      })}
                    >
                      <Icon icon="material-symbols-light:stacked-email-outline-rounded" />
                    </IconButton>
                    :
                    <Tooltip title="Копировать почту">
                      <Chip
                        color="secondary"
                        icon={<Icon icon="material-symbols-light:stacked-email-outline-rounded" />}
                        label={selectedEmployee?.email}
                        onClick={() => copy(selectedEmployee?.email)}
                        sx={{fontSize:12}}
                        variant="outlined"
                      />
                    </Tooltip>
                  }
                </>
              )}
              {selectedEmployee?.phone && (
                <>
                  {
                    isMobile ?
                      <IconButton
                        color="secondary"
                        onClick={() => copy(selectedEmployee?.phone)}
                        size="small"
                        sx={(theme)=>({
                          borderRadius:'90px',
                          border:`1px solid ${theme.palette.secondary.main}`,
                        })}
                      >
                        <Icon icon="material-symbols-light:phone-enabled-outline" />
                      </IconButton>
                      :
                      <Tooltip title="Копировать телефон">
                        <Chip
                          color="secondary"
                          icon={<Icon icon="material-symbols-light:phone-enabled-outline" />}
                          label={selectedEmployee?.phone}
                          onClick={() => copy(selectedEmployee?.phone)}
                          sx={{fontSize: 12}}
                          variant="outlined"
                        />
                      </Tooltip>
                  }
                </>
              )}
            </Stack>

          </Stack>
        </Stack>
      </Card>

      <Card
        sx={(theme) => ({
          width:{ xs: "100%", md:"100%" },
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        })}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{
                p: 2,
              }}
            >
              <Typography variant="subtitle2">Редактировать</Typography>
              {isMobile ?
                <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
                :
                <Button
                  color="primary"
                  onClick={handleBack}
                  size="small"
                  startIcon={
                    <CloseIcon />
                  }
                  variant="text"
                >
                  Отмена
                </Button>
              }
            </Stack>
            <Divider />
            <Box p={2}>
              <RowStack>
                <ColumnStack customSpace={0.5}>
                  <SkeletonInput loading={loading} width={"90%"}>
                    <Controller
                      control={control}
                      name="department"
                      render={({
                                 field: { onChange, ...field },
                                 fieldState: { error },
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            getOptionLabel={(option) => option?.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(e, value) => {
                              onChange(value);
                            }}
                            options={departments}
                            renderInput={(params) => (
                              <TextField
                                label="Отдел"
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!!error}
                                helperText={error?.message}
                                sx={(theme) => ({
                                  '& .MuiOutlinedInput-root':{
                                    '& fieldset': {
                                      borderColor: `${theme.palette.divider}`,
                                    },
                                  },
                                  width:'100%',
                                  '& input': {
                                    fontSize:'15px'
                                  },
                                  '& .MuiInputBase-inputMultiline':{
                                    fontSize:'15px'
                                  }
                                })}
                              />
                            )}
                            size="small"
                            sx={{
                              mb: 2,
                              width:'100%',
                            }}
                          />
                        );
                      }}
                      rules={
                        {
                          // required: t('main.another.requiredField'),
                        }
                      }
                    />
                  </SkeletonInput>

                  <SkeletonInput loading={loading} width={"90%"}>
                    <CustomTextField
                      label="Должность"
                      name="occupation"
                      size="small"
                      sx={{ width: "100%", fontSize: "12px", mb: 2, pr: 5 }}
                    />
                  </SkeletonInput>
                </ColumnStack>
                <ColumnStack>
                  <SkeletonInput loading={loading} width={"90%"}>
                    <CustomViewField
                      label="Менеджер"
                      loading={false}
                      text={changedManager !== null ? changedManager?.display_name : selectedEmployee?.manager?.display_name}
                    />
                  </SkeletonInput>
                </ColumnStack>
              </RowStack>
              {isMobile ? (
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{ width: "100%", mt: 3 }}
                >
                  <Button disabled={loading} size="small" sx={{ width: "100%" }} type="submit" variant="contained">
                    Сохранить
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isDirty}
                    onClick={() => reset()}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                </Stack>
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                >

                  <Button
                    color="primary"
                    onClick={() => reset()}
                    size="small"
                    sx={{ ml: 1 }}
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                  <Button disabled={loading} size="small" type="submit" variant="contained">
                    Сохранить
                  </Button>
                </Stack>
              )}
            </Box>
          </form>
        </FormProvider>
        <AlertMessage />
      </Card>
      <Card
        sx={(theme) => ({
          width:{ xs: "100%", md:"100%" },

        })}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            p: 2,
          }}
        >
          <Typography variant="subtitle2">Подробная информация</Typography>
        </Stack>
        <Divider />
        <Box p={2}>
          <RowStack>
            <ColumnStack customSpace={0.5}>
              <Typography
                align="left"
                component="h2"
                pb={1}
                variant="miniHeader"
              >
                Контакты
              </Typography>
              <InfoAboutComponent
                copyButton={true}
                label="Телефон"
                text={selectedEmployee?.phone}
              />
              <InfoAboutComponent
                copyButton={true}
                label="Email"
                text={selectedEmployee?.email}
              />
              <InfoAboutComponent
                label="Доп. контакт"
                text={selectedEmployee?.communication_channels}
              />
              <Typography
                align="left"
                component="h2"
                pb={1}
                pt={4}
                variant="miniHeader"
              >
                Локация
              </Typography>
              <InfoAboutComponent
                label="Страна"
                text={selectedEmployee?.country}
              />
              <InfoAboutComponent
                label="Рабочий график"
                text={selectedEmployee?.work_schedule}
              />
              <InfoAboutComponent
                label="Почтовый адрес"
                text={selectedEmployee?.street_address}
              />
            </ColumnStack>
            <ColumnStack customSpace={0.5}>
              <Typography
                align="left"
                component="h2"
                pb={1}
                variant="miniHeader"
              >
                Общие данные
              </Typography>
              <InfoAboutComponent
                label="Дата рождения"
                text={dayjs(selectedEmployee?.birth_date).format("DD.MM.YYYY")}
              />
              <InfoAboutComponent
                label="Обо мне"
                text={selectedEmployee?.about_me}
              />
            </ColumnStack>
          </RowStack>
        </Box>
      </Card>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />

      <AlertMessage />
    </Box>
  );
};

export default EmployeesForm;
