import React, { useCallback, useEffect, useState } from "react";

import {Icon} from "@iconify/react";
import { Box, Button, Card, Divider, Grid, IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";

const ClientsFormView = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const isMobile = useResponsive("down", "sm");
  const { updateFullSelectedClient } = useActions();

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const loadData = useCallback(async () => {
    try {
      const response = await $authHost.get(`/clients/${clientId}/`);
      updateFullSelectedClient(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [clientId, updateFullSelectedClient]);

  useEffect(() => {
    loadData();
  }, [loadData]);


  const handleEdit = () => {
    navigate(`/clients/edit/${clientId}`);
  };

  return (

    <Box>
      <Card>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            p:2
          }}
        >
          <Typography variant="subtitle2">
            Справочная информация
          </Typography>
          {isMobile ?
            <IconButton color="primary" onClick={handleEdit} sx={{ p: 0 }}>
              <Icon height="20" icon="material-symbols-light:edit-outline-rounded" width="20"  />
            </IconButton>
            :
            <Button
              color="primary"
              onClick={handleEdit}
              size="small"
              startIcon={
                <Icon height="20" icon="material-symbols-light:edit-outline-rounded" width="20"  />
              }
              variant="text"
            >
              Редактировать
            </Button>
          }
        </Stack>
        <Divider/>
        <Box
          sx={{
            flexDirection: "column",
            px: 2, pb: 4, pt:2
          }}
        >
          <RowStack>
            <ColumnStack>
              <Typography align="left" component="h2" pb={1} variant="miniHeader">
                Общее
              </Typography>
              <InfoAboutComponent
                label="Наименование"
                loading={loading}
                text={selectedClient?.name}
              />
              <InfoAboutComponent
                label="Полное наименование"
                loading={loading}
                text={selectedClient?.full_name}
              />
              <InfoAboutComponent
                label="Код клиента"
                loading={loading}
                text={selectedClient?.code}
              />
              <InfoAboutComponent
                label="Веб-сайт"
                loading={loading}
                path={selectedClient?.web_site}
                text={selectedClient?.web_site}
                type="link"
              />
              <Typography align="left" component="h2" pb={1} pt={2} variant="miniHeader">
                Контакты
              </Typography>
              <InfoAboutComponent
                copyButton={true}
                label="Email"
                loading={loading}
                text={selectedClient?.email}
              />
            </ColumnStack>
            <ColumnStack>
              <Typography align="left" component="h2" pb={1} variant="miniHeader">
                Регистрация
              </Typography>
              <InfoAboutComponent
                label="Адрес"
                text={selectedClient?.address}
              />

              <InfoAboutComponent
                copyButton={true}
                label="Реквизиты"
                loading={loading}
                text={selectedClient?.details}
              />
              <InfoAboutComponent
                label="Страна"
                loading={loading}
                text={selectedClient?.country}
              />
            </ColumnStack>
          </RowStack>
          {loading && <LoadingSpinner />}
        </Box>
      </Card>
    </Box>


  );
};

export default ClientsFormView;
