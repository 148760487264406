import React, {useCallback, useEffect, useRef, useState} from "react";

import CloseIcon from "@mui/icons-material/Close";
import {Box, Card, Divider, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {useNavigate, useParams} from "react-router";

import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";


dayjs.extend(isBetween);

const CompensationView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const ref = useRef(null);
  const { compensationId } = useParams();
  const { compensation } = useSelector((state) => state.paymentsReducer);
  const { setCompensation } = useActions();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
    values: { payment_period: compensation?.payment_period },
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
    reset,
    clearErrors,
    control,
  } = methods;

  useEffect(() => {
    reset({ payment_period: compensation?.payment_period });
  }, [reset, compensation]);
  
  const onSubmit = async ({ payment_period, ...data }) => {
    setLoading(true);

    const newData = {
      ...compensation,
      ...data,
      payment_period: payment_period
        ? dayjs(payment_period).format("YYYY-MM")
        : null,
      ...(compensation.checks.length > 0 && {
        checks: compensation.checks.map((file) => file.id),
      }),
      ...(compensation.photos.length > 0 && {
        photos: compensation.photos.map((file) => file.id),
      }),
    };

    try {
      await $authHost.put(`/compensation/${compensationId}/update/`, newData);
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeStatus = async (status) => {
    setLoading(true);

    const newData = {
      ...compensation,
      ...(compensation.checks.length > 0 && {
        checks: compensation.checks.map((file) => file.id),
      }),
      ...(compensation.photos.length > 0 && {
        photos: compensation.photos.map((file) => file.id),
      }),
      status,
    };

    try {
      await $authHost.put(`/compensation/${compensationId}/update/`, newData);
      handleClose();
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/compensations");
    }
  }, [isSubmitSuccessful, navigate]);


  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/history/all/?compensation_id=${compensationId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [compensationId]);

  const getMyCompensation = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/compensation/${compensationId}/`);
      const data = response.data;
      setCompensation(data);
    } catch (e) {
      console.log(e);
    }
  }, [setCompensation, compensationId]);

  useEffect(() => {
    Promise.all([getMyCompensation(), getHistory()]).then(() =>
      setLoading(false),
    );
  }, [getHistory, getMyCompensation]);

  const handleClose = () => {
    navigate(`/payments/compensations`);
  };

  const paymentPeriod = useWatch({ control, name: "payment_period" });

  useEffect(() => {
    const paymentPeriodIsBetween = dayjs(paymentPeriod).isBetween(
      compensation?.expenses_period,
      dayjs(compensation?.expenses_period).add(2, "month"),
      "month",
      "[]",
    );

    if (!paymentPeriodIsBetween) {
      setError("payment_period", {
        type: "custom",
        message:
          "Период выплаты не может быть раньше периода расходов или превышать его более чем на 2 месяца. Пожалуйста, выберите корректный период",
      });
    } else {
      clearErrors("payment_period");
    }
  }, [clearErrors, compensation?.expenses_period, paymentPeriod, setError]);

  return (
    <Card sx={{mb:2}}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p:2
        }}
      >
        <Typography variant="subtitle2">
          Заявка
        </Typography>
        <IconButton color="secondary" onClick={handleClose} sx={{ px: 1, py:isMobile ? 1 : 0 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ p: 2 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack customSpace={0.5} ref={ref} >
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Вид компенсации"
                  text={compensation?.type}
                />
                <InfoAboutComponent
                  label="Сотрудник"
                  text={compensation?.user?.display_name}
                />
                <InfoAboutComponent
                  label="Отдел"
                  text={compensation?.department?.name}
                />
                <InfoAboutComponent label="Валюта" text={compensation?.currency} />
                <InfoAboutComponent
                  label="Сумма в валюте"
                  text={compensation?.amount}
                />
                <InfoAboutComponent
                  label="Период расходов"
                  text={
                    compensation?.expenses_period
                      ? dayjs(compensation?.expenses_period).format("MMMM YYYY")
                      : ""
                  }
                />
                {compensation?.status === "Новое" ? (
                  <InfoAboutComponent
                    format="MMMM YYYY"
                    label="Период выплаты"
                    name="payment_period"
                    required
                    views={["year", "month"]}
                  />
                ) : (
                  <InfoAboutComponent
                    label="Период выплаты"
                    text={
                      compensation?.payment_period
                        ? dayjs(compensation?.payment_period).format(
                          "MMMM YYYY",
                        )
                        : ""
                    }
                  />
                )}
                <InfoAboutComponent
                  label="Текст запроса"
                  text={compensation?.request_text}
                />
                <Typography align="left" component="h2" pb={1} pt={2} variant="miniHeader">
                  Документы
                </Typography>
                <InfoAboutComponent
                  files={compensation?.checks}
                  label="Чек"
                  type="file"
                />
                <InfoAboutComponent
                  files={compensation?.photos}
                  label="Фото-отчет"
                  type="file"
                />
              </ColumnStack>
              <ColumnStack
                customSpace={0.1}
                sx={{
                  height:`${ref?.current?.clientHeight}px`,
                  overflowY:'auto'
              }}
              >
                <Typography align="left" component="h2" pt={1} variant="miniHeader">
                  История
                </Typography>
                <History history={history}/>
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack>
                {(compensation?.status === "Новое" || compensation?.status === 'Согласовано') && (
                  <Stack
                    direction="column"
                    spacing={0}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography align="left" component="h2" pb={2} pt={2} variant="miniHeader">
                      Действия
                    </Typography>
                    {compensation?.status === "Новое" && (
                      <>
                        <CustomAutocompleteNew
                          label="Статус"
                          name="status"
                          options={["Согласовано", "Отклонено"]}
                          required
                          sx={{mb:2}}
                        />
                        <CustomTextFieldNew
                          label="Комментарий"
                          multiline
                          name="comment"
                          required
                          rows={2}
                        />
                        <Stack
                          alignItems="center"
                          direction="row"
                          justifyContent="flex-end"
                          sx={{width:'100%'}}
                        >
                          <Button
                            size="small"
                            type="submit"
                            variant="contained"
                          >
                            Сохранить
                          </Button>
                        </Stack>
                      </>
                    )}
                    {compensation?.status === "Согласовано" && (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{width:'100%', alignItems:'center', justifyContent:'flex-end'}}
                      >

                        <Button
                          onClick={() => onChangeStatus("Аннулировано")}
                          size="small"
                          variant="outlined"
                        >
                          Аннулировать
                        </Button>
                        <Button
                          onClick={() => onChangeStatus("Выплачено")}
                          size="small"
                          variant="contained"
                        >
                          Выплачено
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                )}
              </ColumnStack>
              <ColumnStack/>
            </RowStack>
          </form>
        </FormProvider>
      </Box>
    </Card>
  );
};

export default CompensationView;
