import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { TIMESHEET_STATUSES } from "../../constants";

const Descriptions = () => {
  // TODO: вынести в константы
  // const rows = [
  //   { value: 'Я', color: '#bdd7ee', description: 'Явка, полный рабочий день' },
  //   {
  //     value: 'В',
  //     color: '#e2efdb',
  //     description: 'Выходные и праздничные дни по трудовому календарю',
  //   },
  //   { value: 'ОТ', color: '#a9d090', description: 'Отпуск основной' },
  //   {
  //     value: 'ОВ',
  //     color: '#a9d090',
  //     description: 'Оплачиваемый выходной, day-off за счет компании',
  //   },
  //   {
  //     value: 'РП',
  //     color: '#cfbce9',
  //     description: 'Рабочие праздники и рабочие выходные',
  //   },
  //   {
  //     value: 'С',
  //     color: '#cfbce9',
  //     description: 'Рабочий день + сверхурочная работа',
  //   },
  //   {
  //     value: 'НВ',
  //     color: '#d9d9d9',
  //     description: 'Неоплачиваемый выходной, day-off за свой счет',
  //   },
  //   {
  //     value: 'НО',
  //     color: '#d9d9d9',
  //     description:
  //       'Административный отпуск неоплачиваемый (отпуск без сохранения заработной платы)',
  //   },
  //   { value: 'У', color: '#d9d9d9', description: 'Уволен' },
  //   {
  //     value: 'ПР',
  //     color: '#ff8584',
  //     description: 'Прогул без уважительной причины',
  //   },
  // ];

  return (
    // <Accordion sx={{ backgroundColor: '#f9fafb' }}>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">Описание обозначений</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table size="small" sx={{ mb: 2 }}>
            <TableBody>
              {TIMESHEET_STATUSES.map((row) => (
                <TableRow hover key={row.value}>
                  <TableCell
                    align="center"
                    sx={{ backgroundColor: row?.color }}
                  >
                    {row?.value}
                  </TableCell>
                  <TableCell>{row?.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default Descriptions;
