import React, { useEffect, useRef, useState } from "react";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {Box, Button, IconButton, InputAdornment} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import {
  MaterialReactTable,
  MRT_ColumnActionMenu,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ShowHideColumnsMenu,
  MRT_ToggleFiltersButton,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { useTranslation } from "react-i18next";
import {useMediaQuery} from "react-responsive";

import Iconify from "./iconify";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";

const MaterialTablePaginationTimeSheet = ({
  data,
  columns,
  loading = false,
  state = {},
  initialState = {},
  id = null,
  setPagination,
  pagination,
  rowCount,
  pageCount,
  onGlobalFilterChange,
  onColumnFiltersChange,
  onSortingChange,
  search = "",
  sorting = [],
  columnFilters = [],
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const theme = useTheme();
  const [localization, setLocalization] = useState(MRT_Localization_RU);
  const isMobile = useResponsive("down", "sm");
  const isFirstRender = useRef(true);

  useEffect(() => {
    switch (language) {
      case "ru":
        setLocalization(MRT_Localization_RU);
        break;
      case "en":
        setLocalization(MRT_Localization_EN);
        break;
      default:
        setLocalization(MRT_Localization_EN);
    }
  }, [language]);

  const { setTableState } = useActions();

  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    localization: localization,
    enableColumnFilterModes: true,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    globalFilterFn: "contains",
    onGlobalFilterChange: (v) => onGlobalFilterChange(v || ""),
    onColumnFiltersChange: (v) => onColumnFiltersChange(v || ""),
    onSortingChange: onSortingChange,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    paginationDisplayMode: "pages",
    muiPaginationProps: {
      siblingCount: 2,
    },
    initialState: {
      showGlobalFilter: true,
      ...initialState,
    },
    onPaginationChange: (updater) => {
      if (!isFirstRender.current) {
        setPagination((prevState) => {
          const newState = updater(prevState);
          if (prevState.pageSize !== newState.pageSize) {
            return { pageSize: newState.pageSize, pageIndex: 0 };
          }
          return newState;
        });
      } else {
        isFirstRender.current = false;
      }
    },
    rowCount: rowCount,
    state: {
      isLoading: loading,
      globalFilter: search,
      pagination: pagination,
      sorting: sorting,
      columnFilters: columnFilters,
      ...state,
    },
    pageCount: pageCount,
    muiTableProps: {
      sx: {
        '& thead tr th':{
          borderBottom:'none',
          fontSize:'12px',
          fontWeight:'normal'
        },
        '& thead':{
          boxShadow:'none',
        },
        fontSize:'10px',
        backgroundColor: "background.paper",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: 'text.primary',
        backgroundColor: "background.paper",
        fontWeight:'normal'

      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize:'10px',
      },
    },
    muiTableHeadProps: {
      sx: {
      },
    },
    muiTableBodyProps:{
      sx:{
        fontSize:'10px',

        '& td': {
          // borderBottomColor: 'divider',
          borderBottom: 'none',
          borderRight: 'none',
        },
      }
    },
    muiTableBodyRowProps:{
      sx:{
        backgroundColor: "background.paper",
      }
    },
    muiTopToolbarProps: {
      sx: {
        px: "0px !important",
        backgroundColor: "background.paper",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: "background.paper",
        fontSize:'12px',
        '& nav': {
          fontSize:'10px'
        },
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx:  {
        borderRadius:'8px',
        // height: '100%',
      },
    },
    muiSearchTextFieldProps: {
      placeholder: t("src.sections.@dashboard.search"),
      InputProps: {
        sx: {
          width: "150px",
          // marginLeft:1,
          '& input': {
            fontSize:'12px'
          },
          // transition: theme.transitions.create(["box-shadow", "width"], {
          //   easing: theme.transitions.easing.easeInOut,
          //   duration: theme.transitions.duration.shorter,
          // }),
          "&.Mui-focused": {
            width: "240px",
          //   // boxShadow: theme.customShadows.z8,
          },
          "& fieldset": {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.divider} !important`,
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: "text.disabled", width: 20, height: 20 }}
            />
          </InputAdornment>
        ),
      },
      variant: "outlined",
      size: "small",
    },
    renderTopToolbar: ({table}) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            gap: '0.5rem',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center'
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table}/>
            {/*<MRT_ToggleFiltersButton*/}
            {/*  sx={{*/}
            {/*    color:'text.disabled',*/}
            {/*  }}*/}
            {/*  table={table}*/}
            {/*/>*/}
            {/*<MRT_ShowHideColumnsButton*/}
            {/*  sx={{*/}
            {/*    color:'text.disabled',*/}
            {/*  }}*/}
            {/*  table={table}*/}
            {/*/>*/}
          </Box>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {props?.customFilter &&
              <Box>
                {props.customFilter}
              </Box>
            }
            {props?.customButtonTitle &&
              <Box>
                { !isMobile ?
                    <Button
                      color='primary'
                      disabled={props.customButtonDisabled}
                      onClick={props.customButtonAction}
                      size="small"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="outlined"
                    >
                      {props.customButtonTitle}
                    </Button>
                  :
                    <IconButton
                      color="primary"
                      disabled={props.customButtonDisabled}
                      onClick={props.customButtonAction}
                      size="small"
                      sx={(theme)=>({
                        borderRadius:'8px',
                        height:'34px',
                        border:`1px solid ${theme.palette.primary.main}`,
                      })}
                    >
                      <AddOutlinedIcon/>
                      {/*<Iconify icon="eva:plus-fill" />*/}
                    </IconButton>
                }
              </Box>
            }
          </Box>
        </Box>
      )
    },
    ...props,
  });

  useEffect(() => {
    if (id) {
      return () => {
        const tableState = table.getState();
        setTableState({ id, tableState });
      };
    }
  }, [id, setTableState, table]);

  return <MaterialReactTable table={table} />;
};

export default MaterialTablePaginationTimeSheet;
