import React, { useCallback, useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import AlertMessage from "../components/Alert/AlertMessage";
import BlockFormModal from "../components/BlockFormModal";
import Iconify from "../components/iconify";
import MaterialTablePagination from "../components/MaterialTablePagination";
import TableActions from "../components/TableActions";
import { TableCellsWrap } from "../components/TableCellsWrap";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";
import { RowOptionsStack } from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";

const Clients = () => {
  const tableId = "clients";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setClients, setSelectedClient } = useActions();
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);
  const { roles } = useSelector((state) => state.authReducer);

  const { clients, selectedClient } = useSelector(
    (state) => state.clientsReducer,
  );

  const { setErrorAlertMessage, resetErrorAlertMessage } = useActions();

  const tableColumns = [
    {
      accessorKey: "name",
      header: "Наименование",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "full_name",
      header: "Полное наименование",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "code",
      header: "Код клиента",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'TIN',
    //   header: 'TIN',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: "address",
      header: "Адрес",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "web_site",
      header: "Веб-сайт",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'phone',
    //   header: 'Телефон',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: "country",
      header: "Страна",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "details",
      header: "Реквизиты",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={handleEditRow}
          row={row}
          setConfirmationId={setConfirmationId}
          showDelete={roles?.some((role) => role === "ERP_Admins")}
        />
      ),
    },
  ];

  const handleAddClient = (event) => {
    navigate(`/clients/new`);
  };

  const handleEditRow = (row) => {
    setSelectedClient(row?.source_id);
    navigate(`/clients/edit/${row?.source_id}`);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.source_id;
    if (id) {
      navigate(`/clients/${id}`);
      setSelectedClient(id);
    }
  };

  async function handleDeleteRow(row) {
    setLoading(true);
    resetErrorAlertMessage();
    try {
      await $authHost
        .delete(`/clients/${row?.source_id}/delete/`)
        .then((response) => {
          loadData();
        });
    } catch (e) {
      if (
        e.response.data?.detail === "Cannot delete client with active projects"
      ) {
        setErrorAlertMessage(
          "Для данного клиента существуют связанные проекты. Удаление невозможно.",
        );
      }
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/clients/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      const data = response.data.items;
      setClients(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setClients,
    sorting,
  ]);

  useEffect(() => {
    resetErrorAlertMessage();
  }, [resetErrorAlertMessage]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  const titleref = useRef(null);
  const heightTitle = document.getElementById("titleElem")?.clientHeight;

  return (
    <Box
      sx={{
        pb: 2,
        // height:`calc(100% - 42px)`,
      }}
    >
      <TitleAndButtonComponent
        action={handleAddClient}
        back={() => {
          navigate("/");
        }}
        backTitle="Главная"
        name="Новый клиент"
        ref={titleref}
        title="Клиенты"
        type={true}
      />
      <AlertMessage />
      <Card>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={clients}
          id={tableId}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          // muiTableContainerProps={({table})=>({
          //   sx: {
          //     height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
          //   }
          // })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </Box>
  );
};

export default Clients;
