import React from "react";

import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
// hooks
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import { AspexIcon, blackLogo, whiteLogo } from "../constants";
import { LoginForm } from "../sections/auth/login";
import { StyledRoot } from "../theme/standarts_styles";

export default function Login() {
  const [authTypeOffice, setAuthTypeOffice] = React.useState(false);
  const { t, i18n } = useTranslation();
  //const mdUp = useResponsive('up', 'md');

  let current_token = localStorage.getItem("token");

  const { token } = useSelector((state) => state.authReducer);
  const theme = useTheme();

  if (token) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <StyledRoot>
        <Container sx={{ padding: "0px !important" }}>
          <Stack
            direction="column"
            spacing={3}
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
              background: "linear-gradient(100deg, #FCFCFC, #bbe1ff)",
              backgroundSize: "400% 400%",
              animation: "gradient 20s ease infinite",
              transform: "translate3d(0, 0, 0)",
            }}
          >
            <Box
              sx={{
                height: { xs: 14, sm: 14, md: 18, lg: 20, xl: 20 },
              }}
            >
              <AspexIcon
                color={theme.palette.primary.main}
                height={{xs:14, sm:14, md:18, lg:20, xl:20}}
                width="auto"
              />
            </Box>

            <div>
              <Box
                sx={(theme) => ({
                  margin: { xs: 2, sm: 3, md: 4, lg: 6 },
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "layout.main",
                  width: {
                    xs: "90vw",
                    sm: "80vw",
                    md: "50vw",
                    lg: "40vw",
                    xl: "30vw",
                  },
                  px: { xs: 3, sm: 4, md: 4 },
                  pb: { xs: 3, sm: 4, md: 4, lg: 6, xl: 8 },
                  pt: { xs: 2, sm: 2, md: 2, lg: 3, xl: 4 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LinearProgress color="secondary" sx={{opacity:'0.4', borderRadius:'90px', width: '13%'}} value={100} variant='determinate' />

                  <Typography
                    align="center" color="text.secondary" component="p"
                    sx={{
                      mb: {xs:2, sm: 2, md: 2, lg: 3, xl: 4},
                      mt: {xs:3, sm: 3, md: 3, lg: 4, xl: 5}
                    }}
                    variant="h4Wide"
                  >
                    {authTypeOffice ? "Авторизация Office 365" : "Вход в ASPEX DG"}
                  </Typography>

                  <LoginForm
                    authTypeOffice={authTypeOffice}
                    setAuthTypeOffice={setAuthTypeOffice}
                  />

                  <Typography
                    align="center"
                    color="action.disabled"
                    component="p"
                    sx={{
                      maxWidth: { xs: "240px", sm: "310px", md: "350px" },
                    }}
                    variant="body3"
                  >
                    Авторизоваться можно только используя корпоративный аккаунт
                  </Typography>
                </Box>
              </Box>
            </div>

            <Typography gutterBottom mb={0} variant="body3">
              © 2023-2025, ASPEX
            </Typography>
          </Stack>
        </Container>
      </StyledRoot>
    </>
  );
}
