import { Avatar, Stack, Typography } from "@mui/material";

import useResponsive from "../hook/useResponsive";

const PersonInfo = ({ photo, firstName, lastName }) => {
  const isMobile = useResponsive("down", "sm");

  return (
    <Stack alignItems="center" direction="row" spacing={isMobile ? 1 : 1.5}>
      <Avatar
        alt="photoURL"
        src={photo}
        sx={{
          width: "28px",
          height: "28px",
        }}
      />
      <Stack direction="column">
        <Typography
          sx={{
            color: "text.primary",
            textAlign: "start",
          }}
          variant="body2"
        >
          {firstName}
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
            textAlign: "start",
          }}
          variant="body2"
        >
          {lastName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PersonInfo;
