import React, { useEffect, useRef, useState } from "react";

import {Box, Button, InputAdornment, Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { useTranslation } from "react-i18next";

import Iconify from "./iconify";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";

const MaterialTablePagination = ({
  data,
  columns,
  loading = false,
  state = {},
  initialState = {},
  id = null,
  setPagination,
  pagination,
  rowCount,
  pageCount,
  onGlobalFilterChange,
  onColumnFiltersChange,
  onSortingChange,
  search = "",
  sorting = [],
  columnFilters = [],
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const theme = useTheme();
  const [localization, setLocalization] = useState(MRT_Localization_RU);
  const isMobile = useResponsive("down", "sm");
  const isFirstRender = useRef(true);

  useEffect(() => {
    switch (language) {
      case "ru":
        setLocalization(MRT_Localization_RU);
        break;
      case "en":
        setLocalization(MRT_Localization_EN);
        break;
      default:
        setLocalization(MRT_Localization_EN);
    }
  }, [language]);

  const { setTableState } = useActions();

  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    localization: localization,
    enableColumnFilterModes: true,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    // positionGlobalFilter: "left",
    globalFilterFn: "contains",
    onGlobalFilterChange: (v) => onGlobalFilterChange(v || ""),
    onColumnFiltersChange: (v) => onColumnFiltersChange(v || ""),
    onSortingChange: onSortingChange,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    paginationDisplayMode: "pages",
    muiPaginationProps: {
      siblingCount: 2,
    },
    initialState: {
      showGlobalFilter: true,
      ...initialState,
    },
    onPaginationChange: (updater) => {
      if (!isFirstRender.current) {
        setPagination((prevState) => {
          const newState = updater(prevState);
          if (prevState.pageSize !== newState.pageSize) {
            return { pageSize: newState.pageSize, pageIndex: 0 };
          }
          return newState;
        });
      } else {
        isFirstRender.current = false;
      }
    },
    rowCount: rowCount,
    state: {
      isLoading: loading,
      globalFilter: search,
      pagination: pagination,
      sorting: sorting,
      columnFilters: columnFilters,
      ...state,
    },
    pageCount: pageCount,
    muiTableProps: {
      sx: {
        '& thead tr th':{
          borderBottom:'none',
          backgroundColor: "background.paper",
          fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
            xl: '14px',
          },
          padding: isMobile? '6px 8px' : '8px 12px',
        },
        backgroundColor: "background.paper",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: 'text.primary',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: "background.paper",
      },
    },
    muiTableHeadProps: {
      sx: {
      },
    },
    muiTableBodyProps:{
      sx:{
        '& td': {
          borderBottomColor: 'divider',
          backgroundColor: "background.paper",
          padding: isMobile? '6px 8px' : '8px 12px',
          fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
            xl: '14px',
          },
        },
        '& tr:hover': {
        },
      }
    },
    muiTableBodyRowProps:{
      sx:{
        backgroundColor: "background.paper",
      }
    },
    muiTopToolbarProps: {
      sx: {
        px: "0px !important",
        backgroundColor: "background.paper",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: "background.paper",
        '& label': {
          fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
            xl: '14px',
          },
        },
        '& .MuiInputBase-root': {
          fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
            xl: '14px',
          },
        },
        '& li button': {
          fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
            xl: '14px',
          },
        },
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx:  {
        borderRadius:'8px',
        // height: '100%',
        // border:`1px solid ${theme.palette.divider}`,
      },
    },
    muiTableContainerProps: ({table}) => ({
      sx: {
        // height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
        // height: `calc(100% - ${table.refs.topToolbarRef.current?.clientHeight}px - ${table.refs.bottomToolbarRef.current?.clientHeight}px)`
      }
    }),
    muiSearchTextFieldProps: {
      placeholder: t("src.sections.@dashboard.search"),
      InputProps: {
        sx: {
          width: "150px",
          // marginLeft:1,
          '& input': {
            fontSize:'12px'
          },
          // transition: theme.transitions.create(["box-shadow", "width"], {
          //   easing: theme.transitions.easing.easeInOut,
          //   duration: theme.transitions.duration.shorter,
          // }),
          "&.Mui-focused": {
            width: "240px",
          //   // boxShadow: theme.customShadows.z8,
          },
          "& fieldset": {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.divider} !important`,
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: "text.disabled", width: 20, height: 20 }}
            />
          </InputAdornment>
        ),
      },
      variant: "outlined",
      size: "small",
    },
    renderTopToolbar: ({table}) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '0.5rem',
            p: isMobile ? '8px' : '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width:isMobile ? '100%' : 'max-content', justifyContent: isMobile ? 'flex-start' : 'center' }}>
            {/* import MRT sub-components */}
            {props?.customTitle &&
              <Typography variant="subtitle2">{props.customTitle}</Typography>
            }
            {!props?.customTitle && <MRT_GlobalFilterTextField table={table}/>}
            {!props?.customTitle && <MRT_ToggleFiltersButton sx={{color:'text.disabled',}} table={table}/>}
            {!props?.customTitle && <MRT_ShowHideColumnsButton sx={{color:'text.disabled',}} table={table}/>}
          </Box>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width:isMobile ? '100%' : 'max-content', justifyContent: isMobile ? 'flex-end' : 'center' }}>
            {props?.customTitle && <MRT_GlobalFilterTextField table={table}/>}
            {props?.customTitle && <MRT_ToggleFiltersButton sx={{color:'text.disabled',}} table={table}/>}
            {props?.customTitle && <MRT_ShowHideColumnsButton sx={{color:'text.disabled',}} table={table}/>}
            {props?.customFilter &&
              <Box>
                {props.customFilter}
              </Box>
            }
            {props?.customButtonTitle &&
              <Box>
                <Button
                  color='primary'
                  disabled={props.customButtonDisabled}
                  onClick={props.customButtonAction}
                  size="small"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  variant="outlined"
                >
                  {props.customButtonTitle}
                </Button>
              </Box>
            }
          </Box>
        </Box>
      )

    },
    ...props,
  });

  useEffect(() => {
    if (id) {
      return () => {
        const tableState = table.getState();
        setTableState({ id, tableState });
      };
    }
  }, [id, setTableState, table]);

  return <MaterialReactTable table={table} />;
};

export default MaterialTablePagination;
