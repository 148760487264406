import React, { useCallback, useEffect, useState } from "react";

import {Icon} from "@iconify/react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import InfoAboutComponent from "../InfoAboutComponent";

const CommonSettings = () => {

  const navigate = useNavigate();
  const isMobile = useResponsive("down", "sm");

  const { projectId } = useParams();
  const { setProject } = useActions();
  const { project } = useSelector((state) => state.projectsReducer);
  //
  const [loading, setLoading] = useState(false);
  //
  const loadProject = useCallback(() => {
    setLoading(true);
    try {
      const getProject = async () => {
        const response = await $authHost.get(`/projects/${projectId}/`);
        setProject(response.data);
        setLoading(false);
      };
      getProject();
    } catch (e) {
      console.log(e);
    }
  }, [projectId, setProject]);

  useEffect(() => {
    loadProject();
  }, [loadProject]);



  const handleEdit = () => {
    navigate(`/projects/edit/${project?.id}`);
  };

  return (
    <Box>
      <Card>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            p:2
          }}
        >
          <Typography variant="subtitle2">
            Справочная информация
          </Typography>
          {isMobile ?
            <IconButton color="primary" onClick={handleEdit} sx={{ p: 0 }}>
              <Icon height="20" icon="material-symbols-light:edit-outline-rounded" width="20"  />
            </IconButton>
            :
            <Button
              color="primary"
              onClick={handleEdit}
              size="small"
              startIcon={
                <Icon height="20" icon="material-symbols-light:edit-outline-rounded" width="20"  />
              }
              variant="text"
            >
              Редактировать
            </Button>
          }
        </Stack>
        <Divider/>
        <Box
          sx={{
            flexDirection: "column",
            px: 2, pb: 4, pt:2
          }}
        >
          <RowStack>
            <ColumnStack customSpace={0.5}>
              <Typography align="left" component="h2" pb={1} variant="miniHeader">
                Общее
              </Typography>
              <InfoAboutComponent
                label="Проект"
                loading={loading}
                text={`${project?.code} / ${project?.name}`}
              />
              <InfoAboutComponent
                label="Клиент"
                loading={loading}
                path={`/clients/${project?.client?.id}`}
                text={`${project?.client?.code} / ${project?.client?.name}`}
                type="link"
              />
              <InfoAboutComponent
                label="Статус проекта"
                loading={loading}
                text={project?.status}
              />
              <InfoAboutComponent
                label="Описание"
                loading={loading}
                text={project?.notes}
              />
            </ColumnStack>
            <ColumnStack customSpace={0.5}>
              <Typography align="left" component="h2" pb={1} variant="miniHeader">
                Интеграция
              </Typography>
              <InfoAboutComponent
                label="ClickUp"
                loading={loading}
                text={project?.clickup}
              />
              <InfoAboutComponent
                label="Teams"
                loading={loading}
                text={project?.teams}
              />
              <InfoAboutComponent
                label="Azure"
                loading={loading}
                text={project?.azure_dev_ops_space}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack customSpace={0.5}>
              <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                Сроки
              </Typography>
              <InfoAboutComponent
                label="Старт проекта"
                loading={loading}
                text={
                  project?.actual_start_date
                    ? dayjs(project?.actual_start_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <InfoAboutComponent
                label="Дата завершения"
                loading={loading}
                sx={{
                  marginBottom:'8px'
                }}
                text={
                  project?.actual_end_date
                    ? dayjs(project?.actual_end_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <InfoAboutComponent
                label="Старт План"
                loading={loading}
                text={
                  project?.start_date
                    ? dayjs(project?.start_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <InfoAboutComponent
                label="Завершение План"
                loading={loading}
                sx={{ marginBottom:'8px' }}
                text={
                  project?.end_date
                    ? dayjs(project?.end_date).format("DD.MM.YYYY")
                    : ""
                }
              />
            </ColumnStack>
            <ColumnStack customSpace={0.5}>
              <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                Бюджеты
              </Typography>
              <InfoAboutComponent
                label="Валюта проекта"
                loading={loading}
                sx={{
                  marginBottom:'8px'
                }}
                text={project?.currency}
              />
              <InfoAboutComponent
                label="Бюджет в валюте"
                loading={loading}
                text={project?.currency_budget}
              />
              <InfoAboutComponent
                label="Бюджет в ком. часах"
                loading={loading}
                text={project?.commercial_hours_budget}
              />
              <InfoAboutComponent
                label="Бюджет во вн. часах"
                loading={loading}
                sx={{
                  marginBottom:'8px'
                }}
                text={project?.internal_hours_budget}
              />
              <InfoAboutComponent
                label="Нач. бюджет в валюте"
                loading={loading}
                text={project?.currency_budget_start}
              />
              <InfoAboutComponent
                label="Нач. бюджет в ком. часах"
                loading={loading}
                text={project?.commercial_hours_budget_start}
              />
              <InfoAboutComponent
                label="Нач. бюджет во вн. часах"
                loading={loading}
                text={project?.internal_hours_budget_start}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack customSpace={0.5} full={true}>
              <Typography align="left" component="h2" pb={1} pt={4} variant="miniHeader">
                Команда проекта
              </Typography>
              <InfoAboutComponent
                label="Менеджер проекта"
                loading={loading}
                text={project?.manager?.display_name}
              />
              <InfoAboutComponent
                label="Команда"
                loading={loading}
                text={
                  Array.isArray(project?.team)
                    ? project?.team.map((t) => t.display_name).join(", ")
                    : ""
                }
              />
            </ColumnStack>
          </RowStack>
        </Box>
      </Card>
    </Box>
  );
};

export default CommonSettings;
