import React, { useMemo, useState } from "react";

import { Icon } from "@iconify/react";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NavLink as RouterLink } from "react-router";

import { useActions } from "../../hook/useActions";
import { useAuth } from "../../hook/useAuth";
import palette from "../../theme/palette";
import { StyledNavItem } from "../../theme/standarts_styles";

export default function NavSection({ ...other }) {
  const { roles } = useSelector((state) => state.authReducer);

  const [open, setOpen] = useState(null);
  const { signOut } = useAuth();

  const handleLogout = () => {
    signOut();
    setOpen(false);
  };

  const navConfig = [
    {
      title: "Главная",
      path: "/",
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:dashboard-outline-rounded"
          width="20"
        />
      ),
      counterChip: false,
      roles: ["ERP_Users", "ERP_Admins"],
    },
    {
      title: "Клиенты",
      path: "/clients",
      // icon: <Icon height="20" icon="material-symbols-light:savings-outline-rounded" width="20"  />,
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:supervised-user-circle-outline"
          width="20"
        />
      ),
      roles: ["ERP_Users", "ERP_Admins"],
      counterChip: false,
    },
    {
      title: "Проекты",
      path: "/projects",
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:folder-special-outline-rounded"
          width="20"
        />
      ),
      roles: ["ERP_Users", "ERP_Admins"],
      counterChip: true,
    },
    {
      title: "Расписание",
      path: "/schedule/my-schedule",
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:calendar-clock-outline-rounded"
          width="20"
        />
      ),
      roles: ["ERP_Users", "ERP_Admins"],
      counterChip: false,
    },
    {
      title: "Табели",
      path: "/timesheets",
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:blur-linear-outline-rounded"
          width="20"
        />
      ),
      roles: ["ERP_Admins", "ERP_ReportCardAdmins"],
      counterChip: false,
    },
    {
      title: "Выплаты",
      path: "/payments/my-compensations",
      icon: (
        <Icon
          height="22"
          icon="material-symbols-light:paid-outline-rounded"
          width="22"
        />
      ),
      roles: ["ERP_Users", "ERP_Admins"],
      counterChip: false,
    },
    {
      title: "Договоры",
      path: "/employee-relations/contracts",
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:contact-page-outline-rounded"
          width="20"
        />
      ),
      roles: ["ERP_Admins", "ERP_ReportCardAdmins"],
      counterChip: false,
    },
    {
      title: "Орг. структура",
      path: "/org-structure/employees",
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:family-history-outline-rounded"
          width="20"
        />
      ),
      roles: ["ERP_Users", "ERP_Admins"],
      counterChip: false,
    },
  ];
  // const chats = useSelector(state => state.dialoguesReducer.chats)
  // const unreadChats = useMemo(() => (
  //   chats.filter(chat => chat.msg_count > 0).length  // TODO Механика новых сообщений
  // ), [chats])

  return (
    <Box
      sx={(theme)=>({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 1.5,
        marginTop: 2,
      })}
    >
      <List
        dense={true}
        disablePadding
        sx={{
          // paddingTop:1,
          width: "100%",
        }}
      >
        {navConfig.map(
          (item) =>
            roles.some((role) => item.roles?.some((r) => r === role)) && (
              <NavItem item={item} key={item.title} unreadChats={[]} />
            ),
        )}
      </List>
    </Box>
  );
}

function NavItem({ item, unreadChats }) {
  const { title, path, icon, counterChip } = item;
  const { openNav } = useSelector((state) => state.serviceReducer);

  return (
    <StyledNavItem
      component={RouterLink}
      sx={{
        p: 0,
        color: "text.primary",
        // borderRadius:'8px',
        "&.active": {
          bgcolor: `${alpha(palette.primary.lighter, 0.25)}`,
          color: "primary.main",
        },
      }}
      to={path}
    >
      {openNav ? (
        <Stack direction="row" px={1} spacing={1}>
          {icon && icon}
          <ListItemText
            disableTypography
            primary={<Typography variant="body2">{title}</Typography>}
          />
          {/*{counterChip && unreadChats > 0 && (*/}
          {/*  <StyledChip label={unreadChats} size="small" />*/}
          {/*)}*/}
        </Stack>
      ) : (
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            sx={{
              width: "100%",
            }}
          >
            {icon && icon}
           </Grid>
      )}
    </StyledNavItem>
  );
}
