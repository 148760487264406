import React, { useState } from "react";

import {Icon} from "@iconify/react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { $authHost } from "../../http";

const CustomFileInput = ({
  label,
  helperText,
  files,
  setFiles,
  error,
  setLoading,
}) => {
  const { t } = useTranslation();

  const [confirmationId, setConfirmationId] = useState(false);

  const onUploadFile = async (files) => {
    setLoading(true);

    try {
      let requests = Object.values(files).map((file) => {
        return $authHost.post(
          "/compensation/upload_file/",
          { file },
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
      });

      const response = await Promise.allSettled(requests);

      setFiles((prevState) => [
        ...prevState,
        ...response
          .filter((r) => r?.status === "fulfilled")
          .map((r) => r?.value?.data),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteFile = async (id) => {
    setLoading(true);

    try {
      await $authHost.delete(`/compensation/me/delete_file/${id}/`);

      setFiles((prevState) => {
        return prevState.filter((file) => file.id !== id);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mb={2}>
      <Box
        px={1}
        sx={{
          // TODO: change color?
          border: `1px solid ${error ? "#FF4842" : "#dce0e4"}`,
          borderRadius: 1,
          position: "relative",
          "&:hover": { borderColor: "#000000" },
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            top: "-1px",
            left: "8px",
            padding: "0 5px",
            transform: "translateY(-50%)",
            color: error ? "#FF4842" : "#637381",
            fontSize: "8px",
            backgroundColor: "#fff",
          }}
        >
          {label}
        </Typography>
        <Button
          component="label"
          size='small'
          startIcon={<CloudUploadIcon />}
          sx={{ marginTop: 1, marginBottom: 1 }}
          variant="text"
        >
          Прикрепить файл
          <input
            hidden
            multiple
            onChange={(event) => onUploadFile(event.target.files)}
            type="file"
          />
        </Button>
        <Stack
          direction="row"
          spacing={1}
          sx={{ flexWrap: 'wrap' }}
          useFlexGap
        >
          {files.map((file) => (
            <Box
              key={file.id}
              p={0.5}
              // TODO: change color?
              sx={{ border: "1px solid #dce0e4", borderRadius: 1 }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{ width: "max-content", justifyContent: "flex-start", alignItems:'center' }}
              >
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Icon height="18" icon="material-symbols-light:attach-file" width="18"/>
                  <Typography
                    sx={{maxWidth:'100px', overflowX:'hidden',whiteSpace:'nowrap', textOverflow: "ellipsis"}}
                    variant="body3"
                  >
                    {file.display_name}
                  </Typography>
                </Stack>
                {confirmationId === file.id ? (
                  <Stack direction="row">
                    <Tooltip title="Подтвердить">
                      <IconButton
                        color="success"
                        onClick={() => onDeleteFile(file.id)}
                        size="small"
                      >
                        <Icon height="18" icon="material-symbols-light:check" width="18"  />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Отмена">
                      <IconButton
                        color="error"
                        onClick={() => setConfirmationId(null)}
                        size="small"
                      >
                        <Icon height="18" icon="material-symbols-light:close" width="18"  />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  <Tooltip title="Удалить">
                    <IconButton
                      color="primary"
                      onClick={() => setConfirmationId(file.id)}
                      size="small"
                    >
                      <Icon height="18" icon="material-symbols-light:close-rounded" width="18"/>
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Box>
          ))}
        </Stack>

      </Box>
      {helperText && (
        // TODO: change color?
        <Typography color={error ? "#FF4842" : "#454F5B"}
          fontSize={12}
          mt="4px"
          mx="14px"
          variant="body3"
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

CustomFileInput.propTypes = {
  label: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
};

export default CustomFileInput;
