import React from "react";

import "../../index.css";

import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import { useAuth } from "../../hook/useAuth";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";

const TimeoutWarning = ({ isOpen, onRequestClose }) => {
  const {
    setStartTime,
    setToken,
    setRefreshToken,
    setTokenLifetime,
    setIdToken,
    setIdTokenLifetime,
  } = useActions();
  const { refreshToken } = useSelector((state) => state.authReducer);

  const { t } = useTranslation();
  const { signOut } = useAuth();
  const isMobile = useResponsive("down", "sm");

  const handleRefreshToken = async () => {
    try {
      const response = await $authHost.post(`/auth/refresh`, {
        refresh_token: refreshToken,
      });
      const {
        data: { access_token, refresh_token, id_token },
        headers: { date },
      } = response;

      const utcTime = new Date(date);
      const localTimeMilliseconds = utcTime.getTime();

      const decodedToken = jwtDecode(access_token);
      const tokenLifetime =
        decodedToken.exp * 1000 - (Date.parse(date) || Date.now());

      const decodedIdToken = jwtDecode(id_token);
      const idTokenLifetime =
        decodedIdToken.exp * 1000 - (Date.parse(date) || Date.now());

      setStartTime(localTimeMilliseconds);
      setToken(access_token);
      setRefreshToken(refresh_token);
      setTokenLifetime(tokenLifetime);
      setIdToken(id_token);
      setIdTokenLifetime(idTokenLifetime);
      onRequestClose();
    } catch (e) {
      console.log(e);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "500px",
      minWidth: "200px",
      width: "100%",
      padding: "30px",
      fontSize: "14px",
      backgroundColor: "#ffffff",
      border: "none",
    },
    overlay: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.6)",
      display: "flex",
      zIndex: "10000",
    },
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles}>
        <Stack
          direction="column"
          sx={{
            alignItems:'flex-start',
            justifyContent:'flex-start',
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            sx={{
              width: "100%",
              alignItems:'center',
              justifyContent:'space-between',
              marginBottom:'8px'
            }}
          >
            <Typography color="text.primary"  variant="subtitle2">
              Сессия подходит к концу
            </Typography>
            {isMobile &&
              <IconButton
                color="secondary" onClick={onRequestClose}
              >
                <CloseIcon/>
              </IconButton>
            }
          </Stack>

          <Typography color="text.secondary" variant="body3">
            Выход произойдет автоматически менее чем через 10 минут. Советуем
            переавторизоваться во избежании потери данных.
          </Typography>
          {isMobile ? (
            <Stack
              alignItems="center"
              direction="column"
              justifyContent="center"
              spacing={1}
              sx={{ width: "100%", marginTop:'16px'}}
            >
              <Button
                onClick={handleRefreshToken} size="small"
                sx={{ width: "100%" }} variant="contained">
                Продлить сессию
              </Button>
              <Button
                color="secondary"
                onClick={() => signOut()}
                size="small"
                sx={{ width: "100%" }}
                variant="text"
              >
                Выйти сейчас
              </Button>
            </Stack>
          ) : (
            <Stack
              direction="row"
              sx={{
                width:'100%',
                alignItems:'flex-start',
                justifyContent:'space-between',
                marginTop:'16px'
              }}
            >
              <Button color="secondary" onClick={() => signOut()} size="small" variant="text">
                Выйти сейчас
              </Button>
              <div>
                <Button
                  onClick={handleRefreshToken}
                  size="small"
                  sx={{ marginLeft: "auto", mx: 1 }}
                  variant="contained"
                >
                  Продлить сессию
                </Button>
                <Button onClick={onRequestClose} size="small" variant="outlined">
                  Понятно
                </Button>
              </div>
            </Stack>
            )
          }
        </Stack>
      </Modal>
    </div>
  );
};

export default React.memo(TimeoutWarning);
