import React from "react";

import {
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router";

import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import TitleAndButtonComponent from "../layouts/StandardElementsForPages/TitleAndButtonComponent";

const OrgStructure = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // function handleChange(event, newValue) {
  //   setValue(newValue);
  // }
  //

  const tabs = [
    { label: "Сотрудники", path: "employees" },
    { label: "Отделы", path: "departments" },
  ];

  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Box sx={{ pb:2 }}>
      <TitleAndButtonComponent
        back={()=>{navigate("/");}}
        backTitle="Главная"
        title="Организационная структура"
      />
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Box>
  );
};

export default OrgStructure;
