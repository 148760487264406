import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button, Card, Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import CustomTextField from "../../components/TextFields/CustomTextField";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import TitleAndButtonComponent from "../../layouts/StandardElementsForPages/TitleAndButtonComponent";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";

const ClientsAddForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
    control,
    setError,
    clearErrors,
  } = methods;

  const tabs = [
    { label: "Обзор" },
    { label: "Контактные лица", disabled:true},
  ];

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let new_arr = {
        address: data.address || null,
        code: data.code || null,
        country: data.country || null,
        details: data.details || null,
        email: data.email || null,
        full_name: data.full_name || null,
        name: data.name || null,
        web_site: data.web_site || null,
      };
      new_arr["contacts"] = [];
      return await $authHost.post(`/clients/add/`, new_arr);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/clients");
  };

  const checkUniqueCode = async (value) => {
    const { data } = await $authHost.get(
      `/clients/unique_check/?code=${value}`,
    );
    if (data?.unique_code === false) {
      setError("code", {
        type: "custom",
        message: "Код должен быть уникальным",
      });
    }
    if (data?.unique_code === true) {
      clearErrors("code");
    }
  };

  const validateName = async (value) => {
    if (value) {
      const trimmedName = value.toString().trim();
      if (trimmedName) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?name=${trimmedName}`,
        );
        if (data?.unique_name === false) {
          return "Наименование должно быть уникальным";
        }
        if (data?.unique_name === true) {
          return true;
        }
      }
    }
  };

  const validateCode = async (value) => {
    if (value) {
      const trimmedCode = value.toString().trim();
      if (trimmedCode) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?code=${trimmedCode}`,
        );
        if (data?.unique_code === false) {
          return "Код должен быть уникальным";
        }
        if (data?.unique_code === true) {
          return true;
        }
      }
    }
  };

  return (
    <Box
      sx={{
        pb:2
        // height:`calc(100% - 42px)`,
      }}
    >
      <TitleAndButtonComponent
        back={handleBack}
        backTitle="Вернуться к списку"
        title="Новый клиент"
      />
      <RemoteDynamicTabs tabs={tabs} />
      <Card>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            p:2
          }}
        >
          <Typography variant="subtitle2">
            Справочная информация
          </Typography>
        </Stack>
        <Divider/>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form>
            <Box
              sx={{
                flexDirection: "column",
                px: 2, pb: 4, pt:2
              }}
            >
              <RowStack>
                <ColumnStack>
                  <Typography align="left" component="h2" pb={1} variant="miniHeader">
                    Общее
                  </Typography>
                  <CustomTextField
                    label="Наименование"
                    name="name"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                      validate: (value) => validateName(value),
                    }}
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                  <CustomTextField
                    label="Полное наименование"
                    name="full_name"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                  <CustomTextField
                    label="Код клиента"
                    name="code"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                      validate: (value) => validateCode(value),
                    }}
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                  <CustomTextField
                    label="Веб-сайт"
                    name="web_site"
                    registerOptions={
                      {
                        // required: t('main.another.requiredField'),
                      }
                    }
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                  <Typography align="left" component="h2" pb={1} pt={2} variant="miniHeader">
                    Контакты
                  </Typography>
                  <CustomTextField
                    label="Email"
                    name="email"
                    registerOptions={{
                      // required: t('main.another.requiredField'),
                      pattern: {
                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: t("main.another.wrongInput"),
                      },
                    }}
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                </ColumnStack>
                <ColumnStack>
                  <Typography align="left" component="h2" pb={1} variant="miniHeader">
                    Регистрация
                  </Typography>
                  <CustomTextField
                    label="Адрес"
                    multiline={true}
                    name="address"
                    registerOptions={
                      {
                        // required: t('main.another.requiredField'),
                      }
                    }
                    rows={3}
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                  <CustomTextField
                    label="Реквизиты"
                    multiline={true}
                    name="details"
                    registerOptions={
                      {
                        // required: t('main.another.requiredField'),
                      }
                    }
                    rows={3}
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                  <CustomTextField
                    label="Страна"
                    name="country"
                    registerOptions={
                      {
                        // required: t('main.another.requiredField'),
                      }
                    }
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                  />
                </ColumnStack>
              </RowStack>
            {isMobile ? (
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ width: "100%", mt: 3 }}
              >
                <Button
                  color="primary"
                  onClick={handleSubmit((data) => {
                    onSubmit(data).then((res) => {
                      if (res) {
                        navigate("/clients");
                      }
                    });
                  })}
                  sx={{ width: "100%" }}
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={handleSubmit((data) => {
                    onSubmit(data).then((res) => {
                      if (res?.data?.source_id) {
                        navigate(`/clients/edit/${res.data.source_id}/contacts`);
                      } else if (res) {
                        navigate("/clients");
                      }
                    });
                  })}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  Сохранить и добавить контакты
                </Button>
                <Button
                  onClick={() => reset()}
                  sx={{ width: "100%" }}
                  variant="text"
                >
                  {t("src.components.Intelligence.reset")}
                </Button>
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >

                <Box>
                  <Button
                    color="primary"
                    onClick={() => reset()}
                    size="small"
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={handleSubmit((data) => {
                      onSubmit(data).then((res) => {
                        if (res?.data?.source_id) {
                          navigate(`/clients/edit/${res.data.source_id}/contacts`);
                        } else if (res) {
                          navigate("/clients");
                        }
                      });
                    })}
                    size="small"
                    variant="outlined"
                  >
                    Сохранить и создать контакты
                  </Button>
                  <Button
                    onClick={handleSubmit((data) => {
                      onSubmit(data).then((res) => {
                        if (res) {
                          navigate("/clients");
                        }
                      });
                    })}
                    size="small"
                    sx={{ ml: 1 }}
                    variant="contained"
                  >
                    Сохранить
                  </Button>
                </Box>
              </Stack>
            )}
            </Box>
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Card>
    </Box>
  );
};

export default ClientsAddForm;
