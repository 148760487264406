import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete, Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isSameOrBefore);

const UserContractAdd = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state.usersReducer);
  const { setUsers } = useActions();
  const isMobile = useResponsive("down", "sm");

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      is_unlimited: false,
      start_date: null,
      end_date: null,
      termination_date: null,
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    control,
    setValue,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  const onSubmit = async ({ start_date, end_date, user, ...data }) => {
    setLoading(true);

    const newData = {
      ...data,
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      end_date: dayjs(end_date).format("YYYY-MM-DD"),
      user_id: user.source_id,
    };

    try {
      await $authHost.post(`/agreements/add/`, convertToNull(newData));
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/employee-relations/contracts");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/employee-relations/contracts`);
  };

  const unlimited = useWatch({ control, name: "is_unlimited" });
  const capacity = useWatch({ control, name: "interaction_capacity" });
  const startDate = useWatch({ control, name: "start_date" });
  const endDate = useWatch({ control, name: "end_date" });
  const status = useWatch({ control, name: "status" });

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/users/all/`);
      setUsers(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [setUsers]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const startDateIsBefore = dayjs(startDate).isSameOrBefore(endDate, "day");

    if (!startDateIsBefore) {
      setValue("end_date", startDate);
    }
  }, [endDate, setValue, startDate]);

  return (
    <Card>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p:2
        }}
      >
        <Typography variant="subtitle2">
         Новый договор
        </Typography>
        {isMobile ?
          <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
          :
          <Button
            color="primary"
            onClick={handleBack} startIcon={
              <CloseIcon />
            }
            sx={{ px: 1, py:0 }}
            variant="text"
          >
            Отмена
          </Button>
        }
      </Stack>
      <Divider/>
      <Box sx={{ p: 2 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Общее
                </Typography>
                <Controller
                  control={control}
                  name="user"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option.display_name}
                        onChange={(e, value) => field.onChange(value)}
                        options={users}
                        renderInput={(params) => (
                          <TextField
                            label="Сотрудник *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              width:'100%',
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              }
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <CustomTextField
                  label="Номер договора"
                  name="date_and_number"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <Controller
                  control={control}
                  name="start_date"
                  render={({
                             field: { onChange, value },
                             fieldState: { error },
                           }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        control={control}
                        format="DD.MM.YYYY"
                        label="Дата заключения *"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            size: "small",
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                        sx={(theme) => ({
                          '& .MuiOutlinedInput-root':{
                            '& fieldset': {
                              borderColor: `${theme.palette.divider}`,
                            },
                          },
                          width:'100%',
                          '& input': {
                            fontSize:'15px'
                          },
                          '& .MuiInputBase-inputMultiline':{
                            fontSize:'15px'
                          },
                          mb:1,
                        })}
                        value={dayjs(value)}
                      />
                    </LocalizationProvider>
                  )}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="is_unlimited"
                      render={({ field: { value, ...field } }) => {
                        return (
                          <Switch checked={value} color="primary" {...field} />
                        );
                      }}
                    />
                  }
                  label="Бессрочный"
                  labelPlacement="start"
                  sx={{ ml: 1, mb: 1.5 }}
                />
                {!unlimited && (
                  <Controller
                    control={control}
                    name="end_date"
                    render={({
                               field: { onChange, value },
                               fieldState: { error },
                             }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          format="DD.MM.YYYY"
                          label="Дата окончания *"
                          minDate={startDate}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                          sx={(theme) => ({
                            '& .MuiOutlinedInput-root':{
                              '& fieldset': {
                                borderColor: `${theme.palette.divider}`,
                              },
                            },
                            width:'100%',
                            '& input': {
                              fontSize:'15px'
                            },
                            '& .MuiInputBase-inputMultiline':{
                              fontSize:'15px'
                            },
                            mb: 2,
                          })}
                          value={dayjs(value)}
                        />
                      </LocalizationProvider>
                    )}
                    rules={{
                      required: t("main.another.requiredField"),
                    }}
                  />
                )}
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={[
                          "На согласовании",
                          "Действующий",
                          "Расторгнут",
                          "Исполнен",
                        ]}
                        renderInput={(params) => (
                          <TextField
                            label="Статус договора *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              width:'100%',
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                {status === "Расторгнут" && (
                  <Controller
                    control={control}
                    name="termination_date"
                    render={({
                               field: { onChange, value },
                               fieldState: { error },
                             }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          format="DD.MM.YYYY"
                          label="Дата прекращения *"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                          sx={(theme) => ({
                            '& .MuiOutlinedInput-root':{
                              '& fieldset': {
                                borderColor: `${theme.palette.divider}`,
                              },
                            },
                            width:'100%',
                            mb:2,
                            '& input': {
                              fontSize:'15px'
                            },
                            '& .MuiInputBase-inputMultiline':{
                              fontSize:'15px'
                            },

                          })}
                          value={dayjs(value)}
                        />
                      </LocalizationProvider>
                    )}
                    rules={{
                      required: t("main.another.requiredField"),
                    }}
                  />
                )}
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Трудоустройство", "Самозанятость", "ИП"]}
                        renderInput={(params) => (
                          <TextField
                            label="Тип договора *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              width:'100%',
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <CustomTextField
                  label="Юридическое лицо"
                  name="legal_entity"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                  }}
                  size="small"
                  sx={{ width: "100%", mb:2 }}
                />
                <Controller
                  control={control}
                  name="production_calendar"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Россия", "Казахстан"]}
                        renderInput={(params) => (
                          <TextField
                            label="Производственный календарь *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              width:'100%',
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,  width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <Controller
                  control={control}
                  name="loyalty_program"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Core", "Assotiate", "Contractor"]}
                        renderInput={(params) => (
                          <TextField
                            label="Программа лояльности *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              width:'100%',
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
              </ColumnStack>
              <ColumnStack customSpace={0.5}>
                <Typography align="left" component="h2" pb={1} variant="miniHeader">
                  Условия
                </Typography>
                <Controller
                  control={control}
                  name="interaction_capacity"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["Fulltime", "Fix"]}
                        renderInput={(params) => (
                          <TextField
                            label="Вид занятости *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              width:'100%',
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%',
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                {capacity === "Fix" && (
                  <>
                    <CustomTextField
                      label="Объем часов Fix"
                      name="hour_capacity"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                        pattern: {
                          value: /^\d*$/,
                          message: t("main.another.wrongInput"),
                        },
                      }}
                      size="small"
                      sx={{ width: "100%", mb: 2 }}
                    />
                    <CustomTextField
                      label="Ставка в час"
                      name="hour_rate"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                        pattern: {
                          value: /^\d*$/,
                          message: t("main.another.wrongInput"),
                        },
                      }}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                  </>
                )}
                {capacity === "Fulltime" && (
                  <CustomTextField
                    label="Ставка в месяц"
                    name="month_rate"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                      pattern: {
                        value: /^\d*$/,
                        message: t("main.another.wrongInput"),
                      },
                    }}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                )}
                <Controller
                  control={control}
                  name="currency"
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        options={["RUB", "KZT"]}
                        renderInput={(params) => (
                          <TextField
                            label="Валюта договора *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                            sx={(theme) => ({
                              '& .MuiOutlinedInput-root':{
                                '& fieldset': {
                                  borderColor: `${theme.palette.divider}`,
                                },
                              },
                              '& input': {
                                fontSize:'15px'
                              },
                              '& .MuiInputBase-inputMultiline':{
                                fontSize:'15px'
                              },
                            })}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2, width:'100%'
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: t("main.another.requiredField"),
                  }}
                />
                <CustomTextField
                  label="Отпуск в год, дней"
                  name="vacation_days"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^\d*$/,
                      message: t("main.another.wrongInput"),
                    },
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 3 }}
                />
                <CustomTextField
                  label="Больничные в год, дней"
                  name="sick_days"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^\d*$/,
                      message: t("main.another.wrongInput"),
                    },
                  }}
                  size="small"
                  sx={{ width: "100%", mb: 3 }}
                />
              </ColumnStack>
            </RowStack>
            {isMobile ? (
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ width: "100%", mt: 2 }}
              >
                <Button
                  sx={{ width: "100%" }}
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={handleReset}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  Сбросить
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} sx={{justifyContent: "flex-end"}}>
                <Button
                  color="primary"
                  onClick={handleReset}
                  size="small"
                  variant="outlined"
                >
                  Сбросить
                </Button>
                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </Button>
              </Stack>
              )}
          </form>
        </FormProvider>
      </Box>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Card>
  );
};

export default UserContractAdd;
