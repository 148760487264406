import { useEffect } from "react";

import {Divider} from "@mui/material";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation } from "react-router";

import Header from "./header/Header";
import Nav from "./nav/Nav";
import BreadCrumbs from "../components/Breadcrumbs/BreadCrumbs";
import { desiredLocations } from "../constants";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { LoginPreview, Main, StyledRoot } from "../theme/standarts_styles";

export default function DashboardLayout() {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletPortraitOrMobile = useMediaQuery({
    query: "(max-width: 790px)",
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width:790px)",
  });
  const location = useLocation();
  // const currentLocation = desiredLocations.includes(location.pathname);
  const { isInitAuth } = useSelector((state) => state.authReducer);
  const { openNav } = useSelector((state) => state.serviceReducer);
  const { setOpenNav, toggleOpenNav } = useActions();
  // const location = useLocation();
  useEffect(() => {
    //закрываем боковое меню при навигации с мобильного устройства
    if (openNav && isTabletPortraitOrMobile) {
      toggleOpenNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    //закрываем боковое меню при входе в режим мобильного устройства
    if (!isTabletPortraitOrMobile) {
      setOpenNav(false);
    }
  }, [isTabletPortraitOrMobile, setOpenNav]);


  return (
    <StyledRoot>
      {isInitAuth === true && !isDesktopOrLaptop && <Header />}
      {isInitAuth === true && <Nav />}
      {isInitAuth === true ? (
        <Main isAuth={isInitAuth === true}>
            {/*{isInitAuth === true && location.pathname !== '/' && isDesktopOrLaptop && <BreadCrumbs currentLocation={location} />}*/}
            {/*{isInitAuth === true && isDesktopOrLaptop && <BreadCrumbs currentLocation={location} />}*/}
          {/*<Divider/>*/}
          <Outlet />
        </Main>
      ) : (
        <LoginPreview>
          <Outlet />
        </LoginPreview>
      )}
    </StyledRoot>
  );
}
