import {AppBar, Autocomplete, Grid, ListItemButton, Toolbar} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Link } from "react-router";
import SimpleBar from "simplebar-react";



import {
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  HEADER_DESKTOP,
  HEADER_MOBILE, NAV_WIDTH, NAV_WIDTH_CLOSE,
} from "../constants";




export const StyledRoot = styled("div")({
  backgroundColor: "background.default",
  display: "flex",
  minHeight: "100%",
  height:'100vh',
  overflow: "hidden",
});

export const RenderedContent = styled(Grid)(({ theme, openNav }) => ({
  height: "100vh",
  borderRadius: '4px',
  display: "flex",
  transform: 'scaleX(1)',
  transformOrigin: 'left',
  transition: "all 250ms ease-out",
  backgroundColor: '#fff',
  boxShadow: 'none',
  width: '100%',
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("790")]: {
    borderRadius: '0px',
    paddingLeft:'4px',
    paddingRight:'4px',
    paddingTop:'10px',
    paddingBottom:'10px'
  },
  [theme.breakpoints.up("790")]: {
    paddingTop:'10px',
    paddingBottom:'10px'
  },
}));

export const StyledRootAppBar = styled(AppBar)(({ theme, openNav }) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 1,
  border:`1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  [theme.breakpoints.up("1224")]: {
    width: "100%",
  },
  [theme.breakpoints.up("790")]: {
    minHeight: HEADER_DESKTOP,
    height: HEADER_DESKTOP
  },
  [theme.breakpoints.down("790")]: {
    minHeight: HEADER_MOBILE,
    height: HEADER_MOBILE
  },
}));

export const StyledToolbar = styled(Toolbar)(({ theme, openNav }) => ({
  boxShadow: 'none',
  border:'none',
  alignItems: 'center',
  [theme.breakpoints.up("790")]: {
    minHeight: HEADER_DESKTOP,
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 3, 0, 0),
  },
  [theme.breakpoints.down("790")]: {
    minHeight: HEADER_MOBILE,
    height: HEADER_MOBILE,
    padding: theme.spacing(0, 2, 0, 0),
  },
}));

export const StyledSVG  = styled("svg")(({ theme, color, height }) => ({
  " & path": {
  fill: color
  },
  height: height,
  width: "auto",
}));

export const EventDiv = styled("div")(({ theme, color }) => ({
  backgroundColor: color,
  width: "100%",
  paddingRight: "5px",
  paddingLeft: "5px",
  color: "#ffffff",
  borderRadius: "3px",
}));



export const LoginPreview = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
}))

export const Main = styled("div")(({ theme, isAuth }) => ({
  flexGrow: 1,
  border:'none',
  overflow: 'auto',
  [theme.breakpoints.down("790")]: {
    paddingLeft:16,
    paddingRight:16,
    marginTop: isAuth ? HEADER_MOBILE + 16 : 0,
  },
  [theme.breakpoints.up("790")]: {
    paddingLeft:24,
    paddingRight:24,
    marginTop: "18px",
  },
}));

// export const StyledBox = styled("div")(({ theme }) => ({
//   // mx: 2.5,
//   // marginBottom: "15px",
//   // marginTop:10,
//   // paddingBottom: 0,
//   // [theme.breakpoints.up("lg")]: {
//   //   marginTop: APP_BAR_DESKTOP + 20,
//   //   paddingLeft: theme.spacing(1),
//   //   paddingRight: theme.spacing(1),
//   // },
// }));

export const TitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  mb: 2,
}));

export const ButtonStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  height: "70px",
  justifyContent: "flex-start",
  m: 0,
}));

// export const StyledContent = styled("div")(({ theme, width }) => ({
//   maxWidth: width ? width : 480,
//   margin: "auto",
//   height: "100%",
//   display: "flex",
//   justifyContent: "center",
//   flexDirection: "column",
//   padding: theme.spacing(12, 0),
// }));



export const RowStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  [theme.breakpoints.down("790")]: {
    flexDirection:"column",
    gap: theme.spacing(2)
  },
  [theme.breakpoints.up("790")]: {
    flexDirection:"row",
    gap: theme.spacing(4)
  },
}));

export const ColumnStack = styled(Stack)(({ theme, customSpace, full }) => ({
  display: "flex",
  flexDirection:"column",
  gap: customSpace ? theme.spacing(customSpace) : theme.spacing(2),
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("790")]: {
    width:"100%",
  },
  [theme.breakpoints.up("790")]: {
    width: full? "100%" : "50%"
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.layout.main,
  backgroundColor: theme.palette.layout.element,
}));

export const StyledChipTime = styled(Chip)(({ theme, color_type, fontSize }) => ({
  color: color_type,
  border: `solid 1px ${color_type}`,
  height: fontSize !== null && fontSize*1.7,
  fontSize: fontSize !== null && `${fontSize}px`,
}));


export const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 40,
  position: "relative",
  textTransform: "capitalize",
  borderRadius: "8px"
}));

// export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
//   maxHeight: "100%",
//   "& .simplebar-scrollbar": {
//     "&:before": {
//       // backgroundColor: alpha(theme.palette.grey[600], 0.48),
//       // backgroundColor: 'default.paper',
//       backgroundColor: theme.palette.primary,
//     },
//     "&.simplebar-visible:before": {
//       opacity: 1,
//     },
//   },
//   "& .simplebar-track.simplebar-vertical": {
//     width: 10,
//   },
//   "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
//     height: 6,
//   },
//   "& .simplebar-mask": {
//     zIndex: "inherit",
//   },
// }));

// export const StyledRootScrollbar = styled("div")(({ theme }) => ({
//   flexGrow: 1,
//   height: "100%",
//   overflow: "hidden",
// }));
//
// export const StyledRootUpMD = styled("div")(({ theme }) => ({
//   [theme.breakpoints.up("md")]: {
//     display: "flex",
//   },
// }));

export const StyledAccount = styled("div")(({ theme }) => ({
  // height: "60px",
  // display: 'flex',
  // alignItems: 'center',
  // padding: theme.spacing(1, 2),
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // border: `1px solid ${theme.palette.layout.accent_element}`,
  // color: theme.palette.common.white,
  // backgroundColor: alpha(theme.palette.primary.light, 1),
  //boxShadow: theme.customShadows.z4,
  // backgroundColor: theme.palette.layout.accent_element,
  // [theme.breakpoints.down("lg")]: {
  //   margin: theme.spacing(0, 1),
  // },
}));

export const RowOptionsStack = styled(Stack)(({ Theme }) => ({
  display: "flex",
  gap:10,
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
}));


export const SearchInput = styled(Autocomplete)(({ theme }) => ({
  // "&.Mui-focused .MuiInputLabel-outlined": {
  //   color: theme.palette.divider,
  // },
  // "& .MuiAutocomplete-inputRoot": {
  //   color: theme.palette.text.secondary,
  // },
  // "& .MuiOutlinedInput-notchedOutline": {
  //   borderColor: theme.palette.common.white,
  // },
  // "&:hover .MuiOutlinedInput-notchedOutline": {
  //   borderColor: theme.palette.divider,
  // },
  // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //   borderColor: theme.palette.divider,
  // },
  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     fontSize: '10px',
  //   },
  // },
  zIndex: 5000,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  width: "100%",
  color: theme.palette.text.secondary,
  textDecoration: "none",
}));

export const StyledMessage = styled("div")(({ theme, side }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor:
    side === "right" ? theme.palette.primary["main"] : theme.palette.grey[300],
  color: side === "right" ? "#FFFFFF" : "#000000",
  borderRadius: theme.spacing(2.5),
  display: "inline-block",
  wordBreak: "break-word",
  textAlign: side,
  maxWidth: "100%",
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontSize: "14px",
}));

export const BoxMessage = styled("div")(({ theme, side }) => ({
  width: "100%",
  display: "flex",
  justifyContent: side === "right" ? "flex-end" : "flex-start",
  alignItems: "center",
}));
