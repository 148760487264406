import React from "react";

import {Box} from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";

import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import TitleAndButtonComponent from "../../layouts/StandardElementsForPages/TitleAndButtonComponent";

const ContractTabs = () => {
  const location = useLocation();
  const { roles } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  const tabs = [
    { label: "Договоры", path: "contracts" },
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [{ label: "Трудоустройство", path: "employments" }]
      : []),
  ];

  return (
    <Box sx={{ pb: 2 }}>
      <TitleAndButtonComponent
        back={()=>{navigate("/");}}
        backTitle="Главная"
        title="Договоры"
      />
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Box>
  );
};

export default ContractTabs;
