import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const RequareAuth = ({ children }) => {
  const { token } = useSelector((state) => state.authReducer);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default RequareAuth;
