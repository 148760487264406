import React, { useEffect, useState } from "react";

import { Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {alpha, styled, useTheme} from "@mui/material/styles";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";

import palette from "../../theme/palette";



export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const RemoteDynamicTabs = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 790px)",
  });

  useEffect(() => {
    // Определяем значение вкладки на основе текущего пути
    const activeTabIndex = tabs.findIndex((component) =>
      location.pathname.includes(component.path),
    );
    if (activeTabIndex !== -1) {
      setValue(activeTabIndex);
    }
  }, [location.pathname, tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Определяем путь для перенаправления
    const newPath = tabs[newValue].path;
    navigate(newPath);
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      height:'45px',
      minHeight:'30px',
      color: theme.palette.text.disabled,
      "&.Mui-selected": {
        color: theme.palette.text.primary,
        fontWeight: "600",
      },
      "& .MuiButtonBase-root": {
        height: '45px',
      },
    }),
  );

  const StyledTabs = styled((props) => (<Tabs {...props} />))(
    ({theme}) => ({
      height: "45px",
      '& .MuiTabs-flexContainer':{
        height: '45px',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.light,
      },

  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom:'16px', }}>
        <StyledTabs
          onChange={handleChange}
          scrollButtons="auto"
          value={value}
          variant="scrollable"
        >
          {tabs.map((component, index) => (
            <StyledTab
              className={
                location.pathname.includes(component?.path) ? "active-tab" : ""
              }
              disabled={component?.disabled}
              icon={
                component.count || component.count === 0 ? (
                  <Chip
                    color="secondary"
                    label={component.count}
                    size="small"
                  />
                ) : (
                  <></>
                )
              }
              iconPosition="end"
              key={index}
              label={component?.label}
              {...component}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((component, index) => (
        <TabPanel index={index} key={index} value={value}>
          {component.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default RemoteDynamicTabs;
