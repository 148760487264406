import React, { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeView, TreeItem } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  Stack,
  Switch,
  Tooltip,
  Avatar,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { RowOptionsStack } from "../../theme/standarts_styles";
import { getSortingString } from "../../utils/getSortingString";
import BlockFormModal from "../BlockFormModal";
import Iconify from "../iconify";
import MaterialTable from "../MaterialTable";
import MaterialTablePagination from "../MaterialTablePagination";
import TableActions from "../TableActions";
import { TableCellsWrap } from "../TableCellsWrap";
import PersonInfo from "../UserCard";

const Employees = () => {
  const tableId = "eployees";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "sm");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [switchValue, setSwitchValue] = useState(0);
  const [confirmationId, setConfirmationId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const { roles } = useSelector((state) => state.authReducer);

  const { setEmployees, setSelectedEmployee } = useActions();

  const {
    employees,
    // orgAdmin,
  } = useSelector((state) => state.employeesReducer);

  const handleEditRow = (row) => {
    setSelectedEmployee(row?.source_id);
    navigate(`/org-structure/employees/edit/${row?.source_id}`);
  };

  const treeData = [
    {
      id: 1,
      name: "Коля",
      children: [
        {
          id: 4,
          name: "Маша",
          children: [
            {
              id: 11,
              name: "Петя",
            },
            {
              id: 24,
              name: "Вася",
              children: [
                {
                  id: 41,
                  name: "Костя",
                },
                {
                  id: 56,
                  name: "Игорь",
                },
              ],
            },
          ],
        },
        {
          id: 14,
          name: "Саша",
          children: [
            {
              id: 33,
              name: "Ваня",
              children: [
                {
                  id: 41,
                  name: "Костя",
                  children: [
                    {
                      id: 56,
                      name: "Игорь",
                    },
                  ],
                },
                {
                  id: 78,
                  name: "Саша",
                },
              ],
            },
            {
              id: 23,
              name: "Вася",
            },
          ],
        },
      ],
    },
  ];

  const tableColumns = [
    // {
    //   id:'actions',
    //   accessorKey: "actions",
    //   size:
    //     roles?.some((role) => role.name === "ERP_Admins") === true ? 168 : 0,
    //   enableColumnFilter: false,
    //   enableSorting: false,
    //   enableHiding: false,
    //   visibleInShowHideMenu: false,
    //   muiTableBodyCellProps: {
    //     onClick: (e) => {},
    //     sx: {
    //       cursor: "auto",
    //     },
    //   },
    //   Cell: ({ row }) => (
    //     <TableActions
    //       editFn={handleEditRow}
    //       row={row}
    //       showEdit={roles?.some((role) => role === "ERP_Admins")}
    //     />
    //   ),
    // },
    {
      accessorKey: "display_name",
      header: "Сотрудник",
      Cell: ({ row }) => {
        if (row.original?.first_name !== null) {
          return (
            <TableCellsWrap>
              <PersonInfo
                firstName={row.original?.first_name}
                lastName={row.original?.last_name}
                photo={row.original?.photo}
              />
            </TableCellsWrap>
          );
        } else {
          return <TableCellsWrap></TableCellsWrap>;
        }
      },
    },
    {
      id: "department.name",
      header: "Отдел",
      accessorFn: (row) => row.department?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "manager.display_name",
      header: "Менеджер",
      accessorFn: (row) => row.manager?.display_name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "occupation",
      header: "Должность",
      accessorFn: (row) => row.occupation || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "birth_date",
      header: "Дата рождения",
      accessorFn: (row) => dayjs(row.birth_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
      accessorFn: (row) => row.email || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "phone",
      header: "Телефон",
      accessorFn: (row) => row.phone || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "communication_channels",
      header: "Доп канал связи",
      accessorFn: (row) => row.communication_channels || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "time_zone",
      header: "Часовой пояс",
      accessorFn: (row) => row.time_zone || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "work_schedule",
      header: "Рабочий график",
      accessorFn: (row) => row.work_schedule || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "country",
      header: "Страна",
      accessorFn: (row) => row.country || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id:'actions',
      accessorKey: "actions",
      size:
        roles?.some((role) => role.name === "ERP_Admins") === true ? 168 : 0,
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      visibleInShowHideMenu: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ row }) => (
        <TableActions
          editFn={handleEditRow}
          row={row}
          showEdit={roles?.some((role) => role === "ERP_Admins")}
        />
      ),
    },
  ];

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/users/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      const data = response.data.items;
      setEmployees(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setEmployees,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const renderTree = (nodes) =>
    nodes.map((el) => (
      <TreeItem key={el.id} label={el.name} nodeId={el.id.toString()}>
        {Array.isArray(el.children) && el.children.length > 0
          ? renderTree(el.children)
          : null}
      </TreeItem>
    ));

  const handleOpenViewPage = (row) => {
    const id = row.original.source_id;
    if (id) {
      navigate(`/org-structure/employees/${id}`);
      setSelectedEmployee(id);
    }
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  // const renderTree = (nodes) =>
  //   nodes.map((el) => (
  //     <TreeNode key={el.id} label={el.name}>
  //       {Array.isArray(el.children) && el.children.length > 0
  //         ? renderTree(el.children)
  //         : null}
  //     </TreeNode>
  //   ));

  return (
    <Box>
      {/*<Stack direction='row' justifyContent='space-between'>*/}
      {/*  <Stack direction='row' alignItems='center'>*/}
      {/*    <Typography variant='body1'>Таблица</Typography>*/}
      {/*    <Switch*/}
      {/*      value={switchValue}*/}
      {/*      onChange={() => setSwitchValue(!switchValue)}*/}
      {/*    />*/}
      {/*    <Typography variant='body1'>Дерево</Typography>*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
      <Box>
        <Card>
          {!switchValue ? (
            <>
              <MaterialTablePagination
                columnFilters={columnFilters}
                columns={tableColumns}
                data={employees}
                id={tableId}
                loading={loading}
                muiTableBodyCellProps={({ row }) => ({
                  onClick: () => {
                    handleOpenViewPage(row);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={onGlobalFilterChange}
                onSortingChange={setSorting}
                pageCount={pageCount}
                pagination={pagination}
                rowCount={rowCount}
                search={searchText}
                setPagination={setPagination}
                sorting={sorting}
              />
              <BlockFormModal
                title="Вы не завершили действие внутри таблицы"
                when={!!confirmationId}
              />
            </>
          ) : (
            <Box p={2}>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {renderTree(treeData)}
              </TreeView>
              {/*<Tree label={<div>Root</div>}>{renderTree(treeData)}</Tree>*/}
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default Employees;
