import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  IconButton,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import DepartmentsForm from "../../pages/forms/DepartmentsForm";
import { getSortingString } from "../../utils/getSortingString";
import BlockFormModal from "../BlockFormModal";
import AddEditFormButtonGroup from "../forms/AddEditFormButtonGroup";
import BaseAddEditForm from "../forms/BaseAddEditForm";
import MaterialTablePagination from "../MaterialTablePagination";
import TableActions from "../TableActions";
import { TableCellsWrap } from "../TableCellsWrap";
import PersonInfo from "../UserCard";

const Departments = () => {
  const tableId = "departments";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "sm");

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const { roles } = useSelector((state) => state.authReducer);

  const {
    setDepartments,
    setSelectedDepartment,
    setSelectedManager,
  } = useActions();

  const { departments, selectedDepartment, selectedManager } = useSelector(
    (state) => state.departmentsReducer,
  );

  const handleAddClient = (event) => {
    setSelectedRow(null);
    setSelectedDepartment(null);
    setSelectedManager(null);
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
  };

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setSelectedDepartment(row);
    setOpenForm("Update");
  };

  const handleDeleteRow = async (row) => {
    setLoading(true);
    try {
      await $authHost
        .delete(`/departments/${row?.id}/delete/`)
        .then((response) => {
          loadData();
        });
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  };

  const tableColumns = [
    {
      accessorKey: "name",
      header: "Название",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>
          <Typography
            sx={{
              color: "text.primary",
              textAlign: "start",
            }}
            variant="body2"
          >
            {renderedCellValue}
          </Typography>
        </TableCellsWrap>
      ),
    },
    {
      id: "manager.display_name",
      header: "Менеджер",
      Cell: ({ renderedCellValue, row }) => {
        if (row.original.manager !== null) {
          return (
            <TableCellsWrap>
              <PersonInfo
                firstName={row.original.manager?.first_name}
                lastName={row.original.manager?.last_name}
                photo={row.original.manager?.photo}
              />
            </TableCellsWrap>
          );
        } else {
          return <TableCellsWrap></TableCellsWrap>;
        }
      },
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={handleEditRow}
          row={row}
          setConfirmationId={setConfirmationId}
          showDelete={roles?.some((role) => role === "ERP_Admins")}
          showEdit={roles?.some((role) => role === "ERP_Admins")}
        />
      ),
    },
  ];

  const UpdateAction = async (data) => {
    try {
      const updateContact = async () => {
        return await $authHost.put(
          `/departments/${selectedDepartment.id}/update/`,
          {
            manager_id: data.manager.source_id,
            name: data.name,
          },
        );
      };
      updateContact().then(() => {
        setOpenForm("");
        loadData();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const AddAction = async (data) => {
    try {
      const newPerson = async () => {
        const response = await $authHost.post(`/departments/add/`, {
          name: data.name,
          manager_id: data.manager.source_id,
        });
        return response.data;
      };
      newPerson().then(() => {
        setOpenForm("");
        loadData();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/departments/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      const data = response.data.items;
      setDepartments(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setDepartments,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!openForm && selectedDepartment) {
      setSelectedDepartment(null);
      setSelectedManager(null);
    }
    if (openForm === "") {
      setSelectedManager(null);
    }
  }, [openForm, selectedDepartment, setSelectedDepartment, setSelectedManager]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <>
      {openForm && openForm !== "View" && (
        <Card sx={{ mb: 2 }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p:2
            }}
          >
            <Typography variant="subtitle2">
              {openForm === "Add"
                ? "Добавить"
                : "Редактировать"}
            </Typography>
            {isMobile ?
              <IconButton color="primary" onClick={() => setOpenForm("")} sx={{ p: 0 }}>
                <CloseIcon />
              </IconButton>
              :
              <IconButton color="primary" onClick={() => setOpenForm("")} sx={{ px: 1, py:0 }}>
                <CloseIcon />
              </IconButton>
            }
          </Stack>
          <Divider/>
          <Box p={2}>
            <BaseAddEditForm
              AddAction={AddAction}
              UpdateAction={UpdateAction}
              action={openForm}
              handleClose={() => setOpenForm("")}
              resetValue={{
                name: "",
                manager: "",
              }}
              selectedRow={selectedRow}
              setConfirmation={setConfirmation}
              setLoading={setLoading}
            >
              <DepartmentsForm
                action={openForm}
                selectedRow={selectedRow}
              />
              <AddEditFormButtonGroup
                action={openForm}
                confirmation={confirmation}
                handleClose={() => setOpenForm("")}
              />
            </BaseAddEditForm>
          </Box>
        </Card>
      )}
      <Box>
        <Card>
          <MaterialTablePagination
            columnFilters={columnFilters}
            columns={tableColumns}
            customButtonAction={handleAddClient}
            customButtonDisabled={!!selectedDepartment}
            customButtonTitle={
              roles?.some((role) => role === "ERP_Admins") && "Добавить отдел"
            }
            data={departments}
            id={tableId}
            loading={loading}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={onGlobalFilterChange}
            onSortingChange={setSorting}
            pageCount={pageCount}
            pagination={pagination}
            rowCount={rowCount}
            search={searchText}
            setPagination={setPagination}
            sorting={sorting}
          />
        </Card>
      </Box>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </>
  );
};

export default Departments;
