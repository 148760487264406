import React from "react";

import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";

const CustomTextField = ({
  label,
  name,
  required,
  registerOptions,
  secondErrorText,
  multiline,
  rows,
  helperText,
  ...rest
}) => {
  const req = required || !!registerOptions?.required;
const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div style={{ position: "relative", marginBottom:  helperText ? '32px':'16px', width: "100%" }}>
      <TextField
        {...register(name, registerOptions)}
        {...rest}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors[name]} // Установите error на основе наличия ошибки из react-hook-form
        label={`${label}${req ? " *" : ""}`}
        multiline={multiline}
        rows={rows}
        size="small"
        sx={(theme) => ({
          '& .MuiOutlinedInput-root':{
            '& fieldset': {
              borderColor: `${theme.palette.divider}`,
            },
          },
          width:'100%',
          '& input': {
            fontSize:'15px'
          },
          '& .MuiInputBase-inputMultiline':{
            fontSize:'15px'
          }
        })}
        variant="outlined"
      />
      {errors[name] && (
        <span
          style={{
            position: "absolute",
            bottom: "-18px",
            fontSize: "12px",
            color: "red",
            left: 14, // Выравнивание по левому краю
          }}
        >
          {errors[name]?.message || "Unknown error!"}
        </span>
      )}
      {helperText && !errors[name] && (
        <span
          style={{
            position: "absolute",
            bottom: "-18px",
            fontSize: "12px",
            color: '#bcbcbc',
            left: 14, // Выравнивание по левому краю
          }}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default CustomTextField;
